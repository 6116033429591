<template>
  <main class="col-12 col-lg-8 px-0 px-sm-3 pr-xl-0">
    <CreateMindo :questionId="questionId" :questionName="questionName" />

    <MindoTabs :questionId="questionId" />
  </main>
</template>

<script>
import MindoTabs from "./MindoTabs.vue";
import CreateMindo from "./CreateMindo.vue";
export default {
  name: "MindoMain",
  props: ["questionId", "questionName"],
  components: {
    CreateMindo,
    MindoTabs,
  },
};
</script>

<style></style>
