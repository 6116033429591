<template>
  <aside class="col-12 col-lg-4 pl-xl-0">
    <div id="mindo">
      <p class="text-center mb-0 pb-3 pt-3 mindo-badge bg-orange">
        <svg
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 31 31"
          style="enable-background: new 0 0 31 31; height: 50px"
          xml:space="preserve"
        >
          <g>
            <path
              d="M31,8.9c-0.03-1.43-1.19-2.59-2.65-2.55c-1.44,0.03-2.58,1.22-2.55,2.65c0.11,5.43-4.24,6.55-4.75,6.67 c-0.16,0.03-0.3,0.08-0.45,0.14c0.77,0.65,1.41,1.44,1.9,2.38c0.37,0.69,0.64,1.43,0.83,2.22C26.58,19.28,31.14,15.9,31,8.9z"
            ></path>
            <path
              d="M7.02,21.49c0.24-1.73,0.81-3.21,1.72-4.45c0.77-1.05,1.73-1.86,2.87-2.42C9.8,15.37,2.8,18.7,0.12,27.14 c-0.43,1.37,0.33,2.83,1.69,3.26c0.15,0.05,0.29,0.08,0.44,0.1c1.23,0.17,2.44-0.57,2.83-1.79c0.36-1.14,0.84-2.15,1.37-3.05 L7.02,21.49z"
            ></path>
            <path
              d="M7.47,18.24c0.73-1.35,1.76-2.4,3.09-3.15c1.33-0.75,2.85-1.13,4.57-1.13c1.72,0,3.25,0.38,4.58,1.14 c1.34,0.76,2.37,1.83,3.1,3.21c0.73,1.38,1.1,2.97,1.1,4.76v7.08H6.37v-7.24C6.37,21.15,6.74,19.59,7.47,18.24z M19.59,20.53 c-0.42-0.66-1.01-1.17-1.78-1.53c-0.77-0.37-1.64-0.55-2.63-0.55c-1,0-1.89,0.2-2.65,0.59c-0.77,0.39-1.36,0.93-1.79,1.62 c-0.43,0.69-0.64,1.47-0.64,2.35v2.76h10.11v-2.97C20.21,21.94,20,21.19,19.59,20.53z"
            ></path>
            <g>
              <path
                d="M9.09,3.38c0.58-1.07,1.39-1.9,2.43-2.49C12.56,0.3,13.76,0,15.13,0c1.39,0,2.6,0.3,3.64,0.89 c1.04,0.59,1.85,1.42,2.43,2.49c0.58,1.07,0.86,2.31,0.86,3.72c0,1.41-0.29,2.65-0.86,3.72c-0.58,1.07-1.39,1.9-2.43,2.48 c-1.04,0.58-2.26,0.88-3.64,0.88c-1.37,0-2.57-0.29-3.62-0.88c-1.04-0.58-1.85-1.41-2.43-2.48C8.51,9.75,8.22,8.51,8.22,7.1 C8.22,5.69,8.51,4.45,9.09,3.38z M12.75,9.11c0.62,0.51,1.43,0.77,2.43,0.77c1.02,0,1.84-0.26,2.45-0.77 c0.62-0.51,0.93-1.18,0.93-2.01c0-0.85-0.31-1.53-0.93-2.04c-0.62-0.51-1.44-0.77-2.45-0.77c-1,0-1.81,0.26-2.43,0.78 c-0.62,0.52-0.93,1.2-0.93,2.03C11.83,7.93,12.14,8.6,12.75,9.11z"
              ></path>
            </g>
          </g>
        </svg>
        <span>MİN</span><span class="text-white">DO</span><br />
        <br />
        <span style="font-size: 20px">Birlikte cevap bulalım!</span>
      </p>
      <div
        class="mindo active cursor-pointer"
        v-for="item in getMindoQuestions"
        :key="item.Id"
      >
        <a @click="selectQuestionId(item.Id)" class="icon-right">
          <span style="font-style: italic">
            <strong>{{ item.MindoAccountName }} :</strong>
          </span>
          <br />
          <p>{{ item.Name }}</p>
        </a>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "MindoAsside",
  computed: {
    getMindoQuestions() {
      return this.$store.getters["mindo/getMindoQuestions"];
    },
  },
  methods: {
    selectQuestionId(id) {
      this.$emit("select-question-id", id);
      this.$router.replace(
        {
          query: Object.assign({ ...this.$route.query }, { question: id }),
        },
        () => {}
      );
    },
  },
  async created() {
    if (!this.getMindoQuestions) {
      await this.$store.dispatch("mindo/setMindoQuestions");
    }
  },
};
</script>

<style></style>
