<template>
  <svg viewBox="0 0 430.9 175.8" max-width="700px">
    <g class="st0">
      <path
        class="st1"
        d="M183.3 82.5c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.9-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V69.4c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C186.8 83 185.2 82.5 183.3 82.5zM220.4 133.3h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C241.7 123.8 232.1 133.3 220.4 133.3z"
      ></path>
      <path
        class="st1"
        d="M226.7 84.4c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C227.9 83.8 227.4 84.4 226.7 84.4z"
      ></path>
      <path
        class="st1"
        d="M201.2 91c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C202.5 90.4 201.9 91 201.2 91z"
      ></path>
      <path
        class="st1"
        d="M183.5 109.6c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C198.5 103 191.8 109.6 183.5 109.6z"
      ></path>
      <path
        class="st1"
        d="M179.1 133.3c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C194.1 126.6 187.3 133.3 179.1 133.3z"
      ></path>
      <path
        class="st1"
        d="M220.2 119.7c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C221.5 119.1 220.9 119.7 220.2 119.7z"
      ></path>
      <path
        class="st1"
        d="M213 98c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C214.2 97.4 213.7 98 213 98z"
      ></path>
      <path
        class="st1"
        d="M258.3 57.2c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.2-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C270.7 62.6 265.1 57.2 258.3 57.2zM306.2 133.3h-41.5c-11.7 0-21.3-9.5-21.3-21.1V69.4c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C321 126.7 314.4 133.3 306.2 133.3z"
      ></path>
      <path
        class="st1"
        d="M258.5 84.4c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C273.2 77.8 266.7 84.4 258.5 84.4z"
      ></path>
      <path
        class="st1"
        d="M283.9 91c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C285.1 90.4 284.6 91 283.9 91z"
      ></path>
      <path
        class="st1"
        d="M301.6 109.6c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C316.6 103 309.8 109.6 301.6 109.6z"
      ></path>
      <path
        class="st1"
        d="M306 133.3c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C321 126.6 314.3 133.3 306 133.3z"
      ></path>
      <path
        class="st1"
        d="M292.3 119.7c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C293.6 119.1 293 119.7 292.3 119.7z"
      ></path>
      <path
        class="st1"
        d="M244.9 98c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C246.2 97.4 245.6 98 244.9 98z"
      ></path>
      <path
        class="st1"
        d="M264.9 133.3c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C279.9 126.6 273.2 133.3 264.9 133.3z"
      ></path>
      <path
        class="st1"
        d="M142.5 175.3c-7.2 0-13-6-13-13.3v-59.7c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H81.5c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C155.5 169.3 149.7 175.3 142.5 175.3z"
      ></path>
      <path
        class="st1"
        d="M383.9 173.6c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 0 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V86.1c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C396.9 167.6 391.1 173.6 383.9 173.6z"
      ></path>
      <path
        class="st1"
        d="M395.6 86.1c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C398.4 85 397.2 86.1 395.6 86.1z"
      ></path>
      <path
        class="st1"
        d="M395.6 29.2c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8s10.4-1 10.4-4.8c0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C410.9 36.1 404 29.2 395.6 29.2zM395.6 71.1c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C408.5 69.1 401.6 71.1 395.6 71.1z"
      ></path>
      <path
        class="st1"
        d="M395.6 75.6c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406.6 74.1 400.9 75.6 395.6 75.6z"
      ></path>
      <path
        class="st1"
        d="M395.6 80.1c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406.6 78.5 400.9 80.1 395.6 80.1z"
      ></path>
      <path
        class="st1"
        d="M395.6 84.5c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406.6 82.9 400.9 84.5 395.6 84.5z"
      ></path>
      <path
        class="st1"
        d="M14.6 112c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H14.6zM38.1 148.6h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C46.1 145.1 42.5 148.6 38.1 148.6z"
      ></path>
      <path
        class="st1"
        d="M26.4 119.7c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C35.8 123.9 31.6 119.7 26.4 119.7zM26.4 140.9c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C38.3 135.6 33 140.9 26.4 140.9z"
      ></path>
      <path
        class="st1"
        d="M31.5 128.9c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C31.6 129 31.6 128.9 31.5 128.9zM31.5 131.7c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C34.1 130.5 32.9 131.7 31.5 131.7z"
      ></path>
      <path
        class="st1"
        d="M21.3 128.9c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C21.5 129 21.4 128.9 21.3 128.9zM21.3 131.7c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C24 130.5 22.8 131.7 21.3 131.7z"
      ></path>
      <path
        class="st1"
        d="M66.1 138.4h0.9v-18.7h-0.9V138.4zM68.2 140.9h-3.4c-0.7 0-1.2-0.6-1.2-1.2V118.5c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C69.5 140.3 68.9 140.9 68.2 140.9z"
      ></path>
      <path
        class="st1"
        d="M73.4 138.3h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C82.7 134.2 78.5 138.3 73.4 138.3z"
      ></path>
      <path
        class="st1"
        d="M64.7 136.4H54.4c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C66 135.8 65.4 136.4 64.7 136.4z"
      ></path>
      <path
        class="st1"
        d="M425.7 44.9h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C427 44.3 426.4 44.9 425.7 44.9z"
      ></path>
      <path
        class="st1"
        d="M372.5 44.9h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C373.7 44.3 373.2 44.9 372.5 44.9z"
      ></path>
      <path
        class="st1"
        d="M395.6 21.9c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C396.9 21.3 396.3 21.9 395.6 21.9z"
      ></path>
      <path
        class="st1"
        d="M374.4 66c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C375 65.8 374.7 66 374.4 66z"
      ></path>
      <path
        class="st1"
        d="M412 28.6c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C412.7 28.5 412.3 28.6 412 28.6z"
      ></path>
      <path
        class="st1"
        d="M379.3 28.6c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C379.9 28.5 379.6 28.6 379.3 28.6z"
      ></path>
      <path
        class="st1"
        d="M416.9 66c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C417.5 65.8 417.2 66 416.9 66z"
      ></path>
    </g>
    <g class="st2">
      <path
        class="st1"
        d="M183 82c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V68.9c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C186.5 82.5 184.8 82 183 82zM220.1 132.8h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C241.3 123.4 231.8 132.8 220.1 132.8z"
      ></path>
      <path
        class="st1"
        d="M226.4 83.9c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C227.6 83.4 227.1 83.9 226.4 83.9z"
      ></path>
      <path
        class="st1"
        d="M200.9 90.5c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C202.2 90 201.6 90.5 200.9 90.5z"
      ></path>
      <path
        class="st1"
        d="M183.2 109.2c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C198.2 102.5 191.5 109.2 183.2 109.2z"
      ></path>
      <path
        class="st1"
        d="M178.8 132.8c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C193.7 126.2 187 132.8 178.8 132.8z"
      ></path>
      <path
        class="st1"
        d="M219.9 119.2c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C221.2 118.7 220.6 119.2 219.9 119.2z"
      ></path>
      <path
        class="st1"
        d="M212.7 97.5c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C213.9 97 213.3 97.5 212.7 97.5z"
      ></path>
      <path
        class="st1"
        d="M258 56.7c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C270.4 62.2 264.8 56.7 258 56.7zM305.9 132.8h-41.5c-11.7 0-21.3-9.5-21.3-21.1V68.9c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C320.7 126.2 314 132.8 305.9 132.8z"
      ></path>
      <path
        class="st1"
        d="M258.1 83.9c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C272.9 77.4 266.4 83.9 258.1 83.9z"
      ></path>
      <path
        class="st1"
        d="M283.6 90.5c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C284.8 90 284.3 90.5 283.6 90.5z"
      ></path>
      <path
        class="st1"
        d="M301.3 109.2c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C316.2 102.5 309.5 109.2 301.3 109.2z"
      ></path>
      <path
        class="st1"
        d="M305.7 132.8c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C320.7 126.2 314 132.8 305.7 132.8z"
      ></path>
      <path
        class="st1"
        d="M292 119.2c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C293.3 118.7 292.7 119.2 292 119.2z"
      ></path>
      <path
        class="st1"
        d="M244.6 97.5c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C245.9 97 245.3 97.5 244.6 97.5z"
      ></path>
      <path
        class="st1"
        d="M264.6 132.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C279.6 126.2 272.8 132.8 264.6 132.8z"
      ></path>
      <path
        class="st1"
        d="M142.2 174.8c-7.2 0-13-6-13-13.3V101.8c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H81.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2H95.5c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C155.2 168.8 149.4 174.8 142.2 174.8z"
      ></path>
      <path
        class="st1"
        d="M383.6 173.1c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V85.6c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C396.6 167.2 390.8 173.1 383.6 173.1z"
      ></path>
      <path
        class="st1"
        d="M395.3 85.6c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C398.1 84.6 396.9 85.6 395.3 85.6z"
      ></path>
      <path
        class="st1"
        d="M395.3 28.8c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C410.6 35.6 403.7 28.8 395.3 28.8zM395.3 70.6c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C408 68.7 401.3 70.6 395.3 70.6z"
      ></path>
      <path
        class="st1"
        d="M395.3 75.2c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406.3 73.6 400.6 75.2 395.3 75.2z"
      ></path>
      <path
        class="st1"
        d="M395.3 79.6c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406.3 78 400.6 79.6 395.3 79.6z"
      ></path>
      <path
        class="st1"
        d="M395.3 84c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406.3 82.5 400.6 84 395.3 84z"
      ></path>
      <path
        class="st1"
        d="M14.3 111.5c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H14.3zM37.8 148.2h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C45.8 144.6 42.2 148.2 37.8 148.2z"
      ></path>
      <path
        class="st1"
        d="M26.1 119.3c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C35.5 123.5 31.3 119.3 26.1 119.3zM26.1 140.4c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C38 135.1 32.6 140.4 26.1 140.4z"
      ></path>
      <path
        class="st1"
        d="M31.1 128.4c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C31.3 128.5 31.2 128.4 31.1 128.4zM31.1 131.3c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C33.8 130.1 32.6 131.3 31.1 131.3z"
      ></path>
      <path
        class="st1"
        d="M21 128.4c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C21.2 128.5 21.1 128.4 21 128.4zM21 131.3c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C23.7 130.1 22.5 131.3 21 131.3z"
      ></path>
      <path
        class="st1"
        d="M65.7 138h0.9v-18.7h-0.9V138zM67.9 140.4h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C69.2 139.9 68.6 140.4 67.9 140.4z"
      ></path>
      <path
        class="st1"
        d="M73.1 137.9h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C82.4 133.7 78.2 137.9 73.1 137.9z"
      ></path>
      <path
        class="st1"
        d="M64.4 135.9H54.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C65.7 135.3 65.1 135.9 64.4 135.9z"
      ></path>
      <path
        class="st1"
        d="M425.4 44.4h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C426.6 43.8 426.1 44.4 425.4 44.4z"
      ></path>
      <path
        class="st1"
        d="M372.2 44.4h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C373.4 43.8 372.9 44.4 372.2 44.4z"
      ></path>
      <path
        class="st1"
        d="M395.3 21.4c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C396.6 20.9 396 21.4 395.3 21.4z"
      ></path>
      <path
        class="st1"
        d="M374.1 65.5c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C374.7 65.4 374.4 65.5 374.1 65.5z"
      ></path>
      <path
        class="st1"
        d="M411.7 28.1c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C412.3 28 412 28.1 411.7 28.1z"
      ></path>
      <path
        class="st1"
        d="M379 28.1c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C379.6 28 379.3 28.1 379 28.1z"
      ></path>
      <path
        class="st1"
        d="M416.6 65.5c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C417.2 65.4 416.9 65.5 416.6 65.5z"
      ></path>
    </g>
    <g class="st3">
      <path
        class="st1"
        d="M182.7 81.6c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V68.4c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C186.2 82 184.5 81.6 182.7 81.6zM219.7 132.4h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C241 122.9 231.5 132.4 219.7 132.4z"
      ></path>
      <path
        class="st1"
        d="M226 83.5c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C227.3 82.9 226.7 83.5 226 83.5z"
      ></path>
      <path
        class="st1"
        d="M200.6 90.1c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C201.9 89.5 201.3 90.1 200.6 90.1z"
      ></path>
      <path
        class="st1"
        d="M182.9 108.7c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C197.9 102.1 191.2 108.7 182.9 108.7z"
      ></path>
      <path
        class="st1"
        d="M178.5 132.4c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C193.4 125.7 186.7 132.4 178.5 132.4z"
      ></path>
      <path
        class="st1"
        d="M219.6 118.8c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C220.9 118.2 220.3 118.8 219.6 118.8z"
      ></path>
      <path
        class="st1"
        d="M212.3 97.1c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C213.6 96.5 213 97.1 212.3 97.1z"
      ></path>
      <path
        class="st1"
        d="M257.7 56.2c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C270.1 61.7 264.5 56.2 257.7 56.2zM305.6 132.4h-41.5c-11.7 0-21.3-9.5-21.3-21.1V68.4c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C320.4 125.8 313.7 132.4 305.6 132.4z"
      ></path>
      <path
        class="st1"
        d="M257.8 83.5c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C272.6 76.9 266.1 83.5 257.8 83.5z"
      ></path>
      <path
        class="st1"
        d="M283.3 90.1c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C284.5 89.5 283.9 90.1 283.3 90.1z"
      ></path>
      <path
        class="st1"
        d="M301 108.7c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C315.9 102.1 309.2 108.7 301 108.7z"
      ></path>
      <path
        class="st1"
        d="M305.4 132.4c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C320.4 125.7 313.7 132.4 305.4 132.4z"
      ></path>
      <path
        class="st1"
        d="M291.7 118.8c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C292.9 118.2 292.4 118.8 291.7 118.8z"
      ></path>
      <path
        class="st1"
        d="M244.3 97.1c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C245.6 96.5 245 97.1 244.3 97.1z"
      ></path>
      <path
        class="st1"
        d="M264.3 132.4c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C279.2 125.7 272.5 132.4 264.3 132.4z"
      ></path>
      <path
        class="st1"
        d="M141.9 174.4c-7.2 0-13-6-13-13.3v-59.7c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H80.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C154.9 168.4 149.1 174.4 141.9 174.4z"
      ></path>
      <path
        class="st1"
        d="M383.3 172.7c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V85.1c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C396.3 166.7 390.4 172.7 383.3 172.7z"
      ></path>
      <path
        class="st1"
        d="M395 85.2c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C397.8 84.1 396.6 85.2 395 85.2z"
      ></path>
      <path
        class="st1"
        d="M395 28.3c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C410.3 35.1 403.4 28.3 395 28.3zM395 70.1c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C407.7 68.2 400.9 70.1 395 70.1z"
      ></path>
      <path
        class="st1"
        d="M395 74.7c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406 73.1 400.3 74.7 395 74.7z"
      ></path>
      <path
        class="st1"
        d="M395 79.1c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406 77.6 400.3 79.1 395 79.1z"
      ></path>
      <path
        class="st1"
        d="M395 83.6c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C406 82 400.3 83.6 395 83.6z"
      ></path>
      <path
        class="st1"
        d="M14 111.1c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H14zM37.5 147.7h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C45.5 144.2 41.9 147.7 37.5 147.7z"
      ></path>
      <path
        class="st1"
        d="M25.8 118.8c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C35.2 123 30.9 118.8 25.8 118.8zM25.8 140c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C37.7 134.7 32.3 140 25.8 140z"
      ></path>
      <path
        class="st1"
        d="M30.8 128c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C31 128.1 30.9 128 30.8 128zM30.8 130.8c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C33.5 129.6 32.3 130.8 30.8 130.8z"
      ></path>
      <path
        class="st1"
        d="M20.7 128c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C20.9 128.1 20.8 128 20.7 128zM20.7 130.8c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C23.4 129.6 22.2 130.8 20.7 130.8z"
      ></path>
      <path
        class="st1"
        d="M65.4 137.5h0.9v-18.7h-0.9V137.5zM67.6 140h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C68.9 139.4 68.3 140 67.6 140z"
      ></path>
      <path
        class="st1"
        d="M72.7 137.4H67.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8H67.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C82.1 133.3 77.9 137.4 72.7 137.4z"
      ></path>
      <path
        class="st1"
        d="M64.1 135.4H53.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C65.3 134.9 64.8 135.4 64.1 135.4z"
      ></path>
      <path
        class="st1"
        d="M425.1 43.9h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C426.3 43.4 425.8 43.9 425.1 43.9z"
      ></path>
      <path
        class="st1"
        d="M371.9 43.9h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C373.1 43.4 372.5 43.9 371.9 43.9z"
      ></path>
      <path
        class="st1"
        d="M395 20.9c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C396.3 20.4 395.7 20.9 395 20.9z"
      ></path>
      <path
        class="st1"
        d="M373.8 65c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C374.4 64.9 374.1 65 373.8 65z"
      ></path>
      <path
        class="st1"
        d="M411.4 27.7c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C412 27.6 411.7 27.7 411.4 27.7z"
      ></path>
      <path
        class="st1"
        d="M378.6 27.7c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C379.3 27.6 379 27.7 378.6 27.7z"
      ></path>
      <path
        class="st1"
        d="M416.3 65c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C416.9 64.9 416.6 65 416.3 65z"
      ></path>
    </g>
    <g class="st4">
      <path
        class="st1"
        d="M182.4 81.1c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V68c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C185.9 81.6 184.2 81.1 182.4 81.1zM219.4 131.9h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C240.7 122.4 231.2 131.9 219.4 131.9z"
      ></path>
      <path
        class="st1"
        d="M225.7 83c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C227 82.4 226.4 83 225.7 83z"
      ></path>
      <path
        class="st1"
        d="M200.3 89.6c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C201.5 89 201 89.6 200.3 89.6z"
      ></path>
      <path
        class="st1"
        d="M182.6 108.3c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C197.6 101.6 190.8 108.3 182.6 108.3z"
      ></path>
      <path
        class="st1"
        d="M178.1 131.9c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C193.1 125.2 186.4 131.9 178.1 131.9z"
      ></path>
      <path
        class="st1"
        d="M219.3 118.3c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C220.5 117.7 220 118.3 219.3 118.3z"
      ></path>
      <path
        class="st1"
        d="M212 96.6c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C213.3 96 212.7 96.6 212 96.6z"
      ></path>
      <path
        class="st1"
        d="M257.3 55.8c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C269.7 61.2 264.2 55.8 257.3 55.8zM305.3 131.9h-41.5c-11.7 0-21.3-9.5-21.3-21.1V68c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C320.1 125.3 313.4 131.9 305.3 131.9z"
      ></path>
      <path
        class="st1"
        d="M257.5 83c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C272.2 76.5 265.8 83 257.5 83z"
      ></path>
      <path
        class="st1"
        d="M282.9 89.6c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C284.2 89 283.6 89.6 282.9 89.6z"
      ></path>
      <path
        class="st1"
        d="M300.6 108.3c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C315.6 101.6 308.9 108.3 300.6 108.3z"
      ></path>
      <path
        class="st1"
        d="M305.1 131.9c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C320.1 125.2 313.3 131.9 305.1 131.9z"
      ></path>
      <path
        class="st1"
        d="M291.4 118.3c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C292.6 117.7 292.1 118.3 291.4 118.3z"
      ></path>
      <path
        class="st1"
        d="M244 96.6c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C245.2 96 244.7 96.6 244 96.6z"
      ></path>
      <path
        class="st1"
        d="M264 131.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C278.9 125.2 272.2 131.9 264 131.9z"
      ></path>
      <path
        class="st1"
        d="M141.6 173.9c-7.2 0-13-6-13-13.3v-59.7c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H80.5c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C154.6 167.9 148.8 173.9 141.6 173.9z"
      ></path>
      <path
        class="st1"
        d="M383 172.2c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3H291c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V84.7c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C395.9 166.2 390.1 172.2 383 172.2z"
      ></path>
      <path
        class="st1"
        d="M394.7 84.7c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C397.5 83.7 396.3 84.7 394.7 84.7z"
      ></path>
      <path
        class="st1"
        d="M394.7 27.9c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1L406.8 56c1.3-4 3.1-9.5 3.1-12.9C409.9 34.7 403.1 27.9 394.7 27.9zM394.7 69.7c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C407.4 67.8 400.6 69.7 394.7 69.7z"
      ></path>
      <path
        class="st1"
        d="M394.7 74.3c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405.7 72.7 400 74.3 394.7 74.3z"
      ></path>
      <path
        class="st1"
        d="M394.7 78.7c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405.7 77.1 400 78.7 394.7 78.7z"
      ></path>
      <path
        class="st1"
        d="M394.7 83.1c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405.7 81.5 400 83.1 394.7 83.1z"
      ></path>
      <path
        class="st1"
        d="M13.7 110.6c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H13.7zM37.2 147.3h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C45.2 143.7 41.6 147.3 37.2 147.3z"
      ></path>
      <path
        class="st1"
        d="M25.4 118.4c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C34.8 122.5 30.6 118.4 25.4 118.4zM25.4 139.5c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C37.3 134.2 32 139.5 25.4 139.5z"
      ></path>
      <path
        class="st1"
        d="M30.5 127.5c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C30.7 127.6 30.6 127.5 30.5 127.5zM30.5 130.3c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C33.2 129.2 32 130.3 30.5 130.3z"
      ></path>
      <path
        class="st1"
        d="M20.4 127.5c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C20.5 127.6 20.5 127.5 20.4 127.5zM20.4 130.3c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C23 129.2 21.8 130.3 20.4 130.3z"
      ></path>
      <path
        class="st1"
        d="M65.1 137h0.9v-18.7H65.1V137zM67.3 139.5h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C68.5 139 68 139.5 67.3 139.5z"
      ></path>
      <path
        class="st1"
        d="M72.4 137h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C81.8 132.8 77.6 137 72.4 137z"
      ></path>
      <path
        class="st1"
        d="M63.8 135H53.5c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C65 134.4 64.5 135 63.8 135z"
      ></path>
      <path
        class="st1"
        d="M424.8 43.5h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C426 42.9 425.4 43.5 424.8 43.5z"
      ></path>
      <path
        class="st1"
        d="M371.5 43.5h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C372.8 42.9 372.2 43.5 371.5 43.5z"
      ></path>
      <path
        class="st1"
        d="M394.7 20.5c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C395.9 19.9 395.4 20.5 394.7 20.5z"
      ></path>
      <path
        class="st1"
        d="M373.4 64.6c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C374.1 64.5 373.8 64.6 373.4 64.6z"
      ></path>
      <path
        class="st1"
        d="M411.1 27.2c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C411.7 27.1 411.4 27.2 411.1 27.2z"
      ></path>
      <path
        class="st1"
        d="M378.3 27.2c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C379 27.1 378.6 27.2 378.3 27.2z"
      ></path>
      <path
        class="st1"
        d="M415.9 64.6c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C416.6 64.5 416.3 64.6 415.9 64.6z"
      ></path>
    </g>
    <g class="st5">
      <path
        class="st1"
        d="M182.1 80.7c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V67.5c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C185.6 81.1 183.9 80.7 182.1 80.7zM219.1 131.4h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C240.4 122 230.8 131.4 219.1 131.4z"
      ></path>
      <path
        class="st1"
        d="M225.4 82.5c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C226.7 82 226.1 82.5 225.4 82.5z"
      ></path>
      <path
        class="st1"
        d="M200 89.1c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C201.2 88.6 200.7 89.1 200 89.1z"
      ></path>
      <path
        class="st1"
        d="M182.3 107.8c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C197.2 101.1 190.5 107.8 182.3 107.8z"
      ></path>
      <path
        class="st1"
        d="M177.8 131.4c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C192.8 124.8 186.1 131.4 177.8 131.4z"
      ></path>
      <path
        class="st1"
        d="M219 117.8c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C220.2 117.3 219.7 117.8 219 117.8z"
      ></path>
      <path
        class="st1"
        d="M211.7 96.1c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C213 95.6 212.4 96.1 211.7 96.1z"
      ></path>
      <path
        class="st1"
        d="M257 55.3c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C269.4 60.8 263.9 55.3 257 55.3zM304.9 131.4h-41.5c-11.7 0-21.3-9.5-21.3-21.1V67.5c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C319.7 124.9 313.1 131.4 304.9 131.4z"
      ></path>
      <path
        class="st1"
        d="M257.2 82.5c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C271.9 76 265.4 82.5 257.2 82.5z"
      ></path>
      <path
        class="st1"
        d="M282.6 89.1c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C283.9 88.6 283.3 89.1 282.6 89.1z"
      ></path>
      <path
        class="st1"
        d="M300.3 107.8c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C315.3 101.1 308.6 107.8 300.3 107.8z"
      ></path>
      <path
        class="st1"
        d="M304.8 131.4c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C319.7 124.8 313 131.4 304.8 131.4z"
      ></path>
      <path
        class="st1"
        d="M291.1 117.8c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C292.3 117.3 291.7 117.8 291.1 117.8z"
      ></path>
      <path
        class="st1"
        d="M243.7 96.1c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C244.9 95.6 244.4 96.1 243.7 96.1z"
      ></path>
      <path
        class="st1"
        d="M263.6 131.4c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C278.6 124.8 271.9 131.4 263.6 131.4z"
      ></path>
      <path
        class="st1"
        d="M141.3 173.4c-7.2 0-13-6-13-13.3v-59.7c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H80.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C154.3 167.4 148.4 173.4 141.3 173.4z"
      ></path>
      <path
        class="st1"
        d="M382.6 171.8c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V84.2c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C395.6 165.8 389.8 171.8 382.6 171.8z"
      ></path>
      <path
        class="st1"
        d="M394.4 84.2c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C397.2 83.2 396 84.2 394.4 84.2z"
      ></path>
      <path
        class="st1"
        d="M394.4 27.4c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8s10.4-1 10.4-4.8c0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C409.6 34.2 402.8 27.4 394.4 27.4zM394.4 69.2c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C407.1 67.3 400.3 69.2 394.4 69.2z"
      ></path>
      <path
        class="st1"
        d="M394.4 73.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405.3 72.2 399.7 73.8 394.4 73.8z"
      ></path>
      <path
        class="st1"
        d="M394.4 78.2c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405.3 76.6 399.7 78.2 394.4 78.2z"
      ></path>
      <path
        class="st1"
        d="M394.4 82.7c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405.3 81.1 399.7 82.7 394.4 82.7z"
      ></path>
      <path
        class="st1"
        d="M13.4 110.1c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H13.4zM36.9 146.8h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C44.8 143.3 41.3 146.8 36.9 146.8z"
      ></path>
      <path
        class="st1"
        d="M25.1 117.9c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C34.5 122.1 30.3 117.9 25.1 117.9zM25.1 139.1c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C37 133.8 31.7 139.1 25.1 139.1z"
      ></path>
      <path
        class="st1"
        d="M30.2 127.1c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C30.4 127.1 30.3 127.1 30.2 127.1zM30.2 129.9c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C32.9 128.7 31.7 129.9 30.2 129.9z"
      ></path>
      <path
        class="st1"
        d="M20.1 127.1c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C20.2 127.1 20.1 127.1 20.1 127.1zM20.1 129.9c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C22.7 128.7 21.5 129.9 20.1 129.9z"
      ></path>
      <path
        class="st1"
        d="M64.8 136.6h0.9V117.9h-0.9V136.6zM67 139.1h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C68.2 138.5 67.7 139.1 67 139.1z"
      ></path>
      <path
        class="st1"
        d="M72.1 136.5h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C81.4 132.3 77.3 136.5 72.1 136.5z"
      ></path>
      <path
        class="st1"
        d="M63.4 134.5H53.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C64.7 134 64.1 134.5 63.4 134.5z"
      ></path>
      <path
        class="st1"
        d="M424.4 43h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C425.7 42.5 425.1 43 424.4 43z"
      ></path>
      <path
        class="st1"
        d="M371.2 43h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C372.5 42.5 371.9 43 371.2 43z"
      ></path>
      <path
        class="st1"
        d="M394.4 20c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C395.6 19.5 395.1 20 394.4 20z"
      ></path>
      <path
        class="st1"
        d="M373.1 64.1c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8L374 63.7C373.8 64 373.4 64.1 373.1 64.1z"
      ></path>
      <path
        class="st1"
        d="M410.7 26.8c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C411.4 26.6 411.1 26.8 410.7 26.8z"
      ></path>
      <path
        class="st1"
        d="M378 26.8c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C378.6 26.6 378.3 26.8 378 26.8z"
      ></path>
      <path
        class="st1"
        d="M415.6 64.1c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C416.3 64 416 64.1 415.6 64.1z"
      ></path>
    </g>
    <g class="st6">
      <path
        class="st1"
        d="M181.7 80.2c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V67.1c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C185.3 80.6 183.6 80.2 181.7 80.2zM218.8 131h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C240.1 121.5 230.5 131 218.8 131z"
      ></path>
      <path
        class="st1"
        d="M225.1 82.1c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C226.3 81.5 225.8 82.1 225.1 82.1z"
      ></path>
      <path
        class="st1"
        d="M199.7 88.7c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C200.9 88.1 200.3 88.7 199.7 88.7z"
      ></path>
      <path
        class="st1"
        d="M182 107.3c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C196.9 100.7 190.2 107.3 182 107.3z"
      ></path>
      <path
        class="st1"
        d="M177.5 131c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C192.5 124.3 185.8 131 177.5 131z"
      ></path>
      <path
        class="st1"
        d="M218.6 117.4c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C219.9 116.8 219.3 117.4 218.6 117.4z"
      ></path>
      <path
        class="st1"
        d="M211.4 95.7c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C212.6 95.1 212.1 95.7 211.4 95.7z"
      ></path>
      <path
        class="st1"
        d="M256.7 54.8c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C269.1 60.3 263.5 54.8 256.7 54.8zM304.6 131h-41.5c-11.7 0-21.3-9.5-21.3-21.1V67.1c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C319.4 124.4 312.8 131 304.6 131z"
      ></path>
      <path
        class="st1"
        d="M256.9 82.1c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C271.6 75.5 265.1 82.1 256.9 82.1z"
      ></path>
      <path
        class="st1"
        d="M282.3 88.7c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C283.6 88.1 283 88.7 282.3 88.7z"
      ></path>
      <path
        class="st1"
        d="M300 107.3c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C315 100.7 308.3 107.3 300 107.3z"
      ></path>
      <path
        class="st1"
        d="M304.4 131c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C319.4 124.3 312.7 131 304.4 131z"
      ></path>
      <path
        class="st1"
        d="M290.7 117.4c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C292 116.8 291.4 117.4 290.7 117.4z"
      ></path>
      <path
        class="st1"
        d="M243.4 95.7c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C244.6 95.1 244 95.7 243.4 95.7z"
      ></path>
      <path
        class="st1"
        d="M263.3 131c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C278.3 124.3 271.6 131 263.3 131z"
      ></path>
      <path
        class="st1"
        d="M141 173c-7.2 0-13-6-13-13.3V100c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H79.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C153.9 167 148.1 173 141 173z"
      ></path>
      <path
        class="st1"
        d="M382.3 171.3c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V83.8c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C395.3 165.3 389.5 171.3 382.3 171.3z"
      ></path>
      <path
        class="st1"
        d="M394 83.8c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C396.9 82.7 395.6 83.8 394 83.8z"
      ></path>
      <path
        class="st1"
        d="M394 26.9c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8s10.4-1 10.4-4.8c0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C409.3 33.8 402.5 26.9 394 26.9zM394 68.7c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C406.8 66.8 400 68.7 394 68.7z"
      ></path>
      <path
        class="st1"
        d="M394 73.3c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405 71.7 399.4 73.3 394 73.3z"
      ></path>
      <path
        class="st1"
        d="M394 77.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405 76.2 399.4 77.8 394 77.8z"
      ></path>
      <path
        class="st1"
        d="M394 82.2c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C405 80.6 399.4 82.2 394 82.2z"
      ></path>
      <path
        class="st1"
        d="M13.1 109.7c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4V115.1c0-3-2.4-5.4-5.5-5.4H13.1zM36.6 146.3h-23.5c-4.4 0-8-3.5-8-7.9V115.1c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C44.5 142.8 40.9 146.3 36.6 146.3z"
      ></path>
      <path
        class="st1"
        d="M24.8 117.4c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C34.2 121.6 30 117.4 24.8 117.4zM24.8 138.6c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C36.7 133.3 31.4 138.6 24.8 138.6z"
      ></path>
      <path
        class="st1"
        d="M29.9 126.6c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C30 126.7 30 126.6 29.9 126.6zM29.9 129.4c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C32.5 128.2 31.3 129.4 29.9 129.4z"
      ></path>
      <path
        class="st1"
        d="M19.7 126.6c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C19.9 126.7 19.8 126.6 19.7 126.6zM19.7 129.4c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C22.4 128.2 21.2 129.4 19.7 129.4z"
      ></path>
      <path
        class="st1"
        d="M64.5 136.1h0.9v-18.7h-0.9V136.1zM66.6 138.6h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C67.9 138 67.3 138.6 66.6 138.6z"
      ></path>
      <path
        class="st1"
        d="M71.8 136h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C81.1 131.9 76.9 136 71.8 136z"
      ></path>
      <path
        class="st1"
        d="M63.1 134.1H52.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C64.4 133.5 63.8 134.1 63.1 134.1z"
      ></path>
      <path
        class="st1"
        d="M424.1 42.6h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C425.4 42 424.8 42.6 424.1 42.6z"
      ></path>
      <path
        class="st1"
        d="M370.9 42.6h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C372.2 42 371.6 42.6 370.9 42.6z"
      ></path>
      <path
        class="st1"
        d="M394.1 19.6c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C395.3 19 394.7 19.6 394.1 19.6z"
      ></path>
      <path
        class="st1"
        d="M372.8 63.7c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C373.4 63.5 373.1 63.7 372.8 63.7z"
      ></path>
      <path
        class="st1"
        d="M410.4 26.3c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C411.1 26.2 410.7 26.3 410.4 26.3z"
      ></path>
      <path
        class="st1"
        d="M377.7 26.3c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C378.3 26.2 378 26.3 377.7 26.3z"
      ></path>
      <path
        class="st1"
        d="M415.3 63.7c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C416 63.5 415.6 63.7 415.3 63.7z"
      ></path>
    </g>
    <g class="st7">
      <path
        class="st1"
        d="M181.4 79.7c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V66.6c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C184.9 80.2 183.3 79.7 181.4 79.7zM218.5 130.5h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C239.7 121.1 230.2 130.5 218.5 130.5z"
      ></path>
      <path
        class="st1"
        d="M224.8 81.6c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C226 81.1 225.5 81.6 224.8 81.6z"
      ></path>
      <path
        class="st1"
        d="M199.3 88.2c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C200.6 87.7 200 88.2 199.3 88.2z"
      ></path>
      <path
        class="st1"
        d="M181.6 106.9c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C196.6 100.2 189.9 106.9 181.6 106.9z"
      ></path>
      <path
        class="st1"
        d="M177.2 130.5c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C192.2 123.9 185.4 130.5 177.2 130.5z"
      ></path>
      <path
        class="st1"
        d="M218.3 116.9c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C219.6 116.4 219 116.9 218.3 116.9z"
      ></path>
      <path
        class="st1"
        d="M211.1 95.2c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C212.3 94.7 211.8 95.2 211.1 95.2z"
      ></path>
      <path
        class="st1"
        d="M256.4 54.4c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C268.8 59.9 263.2 54.4 256.4 54.4zM304.3 130.5h-41.5c-11.7 0-21.3-9.5-21.3-21.1V66.6c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C319.1 123.9 312.5 130.5 304.3 130.5z"
      ></path>
      <path
        class="st1"
        d="M256.5 81.6c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C271.3 75.1 264.8 81.6 256.5 81.6z"
      ></path>
      <path
        class="st1"
        d="M282 88.2c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C283.2 87.7 282.7 88.2 282 88.2z"
      ></path>
      <path
        class="st1"
        d="M299.7 106.9c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C314.6 100.2 307.9 106.9 299.7 106.9z"
      ></path>
      <path
        class="st1"
        d="M304.1 130.5c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C319.1 123.9 312.4 130.5 304.1 130.5z"
      ></path>
      <path
        class="st1"
        d="M290.4 116.9c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C291.7 116.4 291.1 116.9 290.4 116.9z"
      ></path>
      <path
        class="st1"
        d="M243 95.2c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C244.3 94.7 243.7 95.2 243 95.2z"
      ></path>
      <path
        class="st1"
        d="M263 130.5c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C278 123.9 271.2 130.5 263 130.5z"
      ></path>
      <path
        class="st1"
        d="M140.6 172.5c-7.2 0-13-6-13-13.3V99.5c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H79.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C153.6 166.5 147.8 172.5 140.6 172.5z"
      ></path>
      <path
        class="st1"
        d="M382 170.8c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V83.3c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C395 164.8 389.2 170.8 382 170.8z"
      ></path>
      <path
        class="st1"
        d="M393.7 83.3c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C396.5 82.3 395.3 83.3 393.7 83.3z"
      ></path>
      <path
        class="st1"
        d="M393.7 26.5c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C409 33.3 402.1 26.5 393.7 26.5zM393.7 68.3c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C406.5 66.4 399.7 68.3 393.7 68.3z"
      ></path>
      <path
        class="st1"
        d="M393.7 72.9c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.7 71.3 399 72.9 393.7 72.9z"
      ></path>
      <path
        class="st1"
        d="M393.7 77.3c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.7 75.7 399 77.3 393.7 77.3z"
      ></path>
      <path
        class="st1"
        d="M393.7 81.7c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.7 80.2 399 81.7 393.7 81.7z"
      ></path>
      <path
        class="st1"
        d="M12.7 109.2c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H12.7zM36.2 145.9h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C44.2 142.3 40.6 145.9 36.2 145.9z"
      ></path>
      <path
        class="st1"
        d="M24.5 117c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C33.9 121.2 29.7 117 24.5 117zM24.5 138.1c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C36.4 132.8 31.1 138.1 24.5 138.1z"
      ></path>
      <path
        class="st1"
        d="M29.6 126.1c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C29.7 126.2 29.6 126.1 29.6 126.1zM29.6 129c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C32.2 127.8 31 129 29.6 129z"
      ></path>
      <path
        class="st1"
        d="M19.4 126.1c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C19.6 126.2 19.5 126.1 19.4 126.1zM19.4 129c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C22.1 127.8 20.9 129 19.4 129z"
      ></path>
      <path
        class="st1"
        d="M64.2 135.6h0.9v-18.7h-0.9V135.6zM66.3 138.1H62.9c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C67.6 137.6 67 138.1 66.3 138.1z"
      ></path>
      <path
        class="st1"
        d="M71.5 135.6h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C80.8 131.4 76.6 135.6 71.5 135.6z"
      ></path>
      <path
        class="st1"
        d="M62.8 133.6H52.5c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C64.1 133 63.5 133.6 62.8 133.6z"
      ></path>
      <path
        class="st1"
        d="M423.8 42.1h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C425.1 41.5 424.5 42.1 423.8 42.1z"
      ></path>
      <path
        class="st1"
        d="M370.6 42.1h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C371.8 41.5 371.3 42.1 370.6 42.1z"
      ></path>
      <path
        class="st1"
        d="M393.7 19.1c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v6.9C395 18.6 394.4 19.1 393.7 19.1z"
      ></path>
      <path
        class="st1"
        d="M372.5 63.2c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C373.1 63.1 372.8 63.2 372.5 63.2z"
      ></path>
      <path
        class="st1"
        d="M410.1 25.8c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C410.8 25.7 410.4 25.8 410.1 25.8z"
      ></path>
      <path
        class="st1"
        d="M377.4 25.8c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C378 25.7 377.7 25.8 377.4 25.8z"
      ></path>
      <path
        class="st1"
        d="M415 63.2c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C415.6 63.1 415.3 63.2 415 63.2z"
      ></path>
    </g>
    <g class="st8">
      <path
        class="st1"
        d="M181.1 79.3c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V66.1c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C184.6 79.7 182.9 79.3 181.1 79.3zM218.2 130.1h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C239.4 120.6 229.9 130.1 218.2 130.1z"
      ></path>
      <path
        class="st1"
        d="M224.5 81.1c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C225.7 80.6 225.2 81.1 224.5 81.1z"
      ></path>
      <path
        class="st1"
        d="M199 87.7c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C200.3 87.2 199.7 87.7 199 87.7z"
      ></path>
      <path
        class="st1"
        d="M181.3 106.4c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C196.3 99.7 189.6 106.4 181.3 106.4z"
      ></path>
      <path
        class="st1"
        d="M176.9 130.1c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C191.8 123.4 185.1 130.1 176.9 130.1z"
      ></path>
      <path
        class="st1"
        d="M218 116.5c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C219.3 115.9 218.7 116.5 218 116.5z"
      ></path>
      <path
        class="st1"
        d="M210.7 94.8c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C212 94.2 211.4 94.8 210.7 94.8z"
      ></path>
      <path
        class="st1"
        d="M256.1 53.9c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C268.5 59.4 262.9 53.9 256.1 53.9zM304 130.1h-41.5c-11.7 0-21.3-9.5-21.3-21.1V66.1c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C318.8 123.5 312.1 130.1 304 130.1z"
      ></path>
      <path
        class="st1"
        d="M256.2 81.1c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C271 74.6 264.5 81.1 256.2 81.1z"
      ></path>
      <path
        class="st1"
        d="M281.7 87.7c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C282.9 87.2 282.4 87.7 281.7 87.7z"
      ></path>
      <path
        class="st1"
        d="M299.4 106.4c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C314.3 99.7 307.6 106.4 299.4 106.4z"
      ></path>
      <path
        class="st1"
        d="M303.8 130.1c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C318.8 123.4 312.1 130.1 303.8 130.1z"
      ></path>
      <path
        class="st1"
        d="M290.1 116.5c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C291.4 115.9 290.8 116.5 290.1 116.5z"
      ></path>
      <path
        class="st1"
        d="M242.7 94.8c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C244 94.2 243.4 94.8 242.7 94.8z"
      ></path>
      <path
        class="st1"
        d="M262.7 130.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C277.6 123.4 270.9 130.1 262.7 130.1z"
      ></path>
      <path
        class="st1"
        d="M140.3 172c-7.2 0-13-6-13-13.3V99c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H79.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C153.3 166.1 147.5 172 140.3 172z"
      ></path>
      <path
        class="st1"
        d="M381.7 170.4c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V82.8c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C394.7 164.4 388.8 170.4 381.7 170.4z"
      ></path>
      <path
        class="st1"
        d="M393.4 82.8c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C396.2 81.8 395 82.8 393.4 82.8z"
      ></path>
      <path
        class="st1"
        d="M393.4 26c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C408.7 32.8 401.8 26 393.4 26zM393.4 67.8c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C406.1 65.9 399.3 67.8 393.4 67.8z"
      ></path>
      <path
        class="st1"
        d="M393.4 72.4c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.4 70.8 398.7 72.4 393.4 72.4z"
      ></path>
      <path
        class="st1"
        d="M393.4 76.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.4 75.3 398.7 76.8 393.4 76.8z"
      ></path>
      <path
        class="st1"
        d="M393.4 81.3c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.4 79.7 398.7 81.3 393.4 81.3z"
      ></path>
      <path
        class="st1"
        d="M12.4 108.8c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H12.4zM35.9 145.4h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C43.9 141.9 40.3 145.4 35.9 145.4z"
      ></path>
      <path
        class="st1"
        d="M24.2 116.5c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C33.6 120.7 29.4 116.5 24.2 116.5zM24.2 137.7c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C36.1 132.4 30.7 137.7 24.2 137.7z"
      ></path>
      <path
        class="st1"
        d="M29.2 125.7c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C29.4 125.8 29.3 125.7 29.2 125.7zM29.2 128.5c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C31.9 127.3 30.7 128.5 29.2 128.5z"
      ></path>
      <path
        class="st1"
        d="M19.1 125.7c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C19.3 125.8 19.2 125.7 19.1 125.7zM19.1 128.5c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C21.8 127.3 20.6 128.5 19.1 128.5z"
      ></path>
      <path
        class="st1"
        d="M63.8 135.2h0.9v-18.7h-0.9V135.2zM66 137.7h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C67.3 137.1 66.7 137.7 66 137.7z"
      ></path>
      <path
        class="st1"
        d="M71.1 135.1h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C80.5 131 76.3 135.1 71.1 135.1z"
      ></path>
      <path
        class="st1"
        d="M62.5 133.1H52.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6H52.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C63.7 132.6 63.2 133.1 62.5 133.1z"
      ></path>
      <path
        class="st1"
        d="M423.5 41.6h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C424.7 41.1 424.2 41.6 423.5 41.6z"
      ></path>
      <path
        class="st1"
        d="M370.3 41.6h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C371.5 41.1 371 41.6 370.3 41.6z"
      ></path>
      <path
        class="st1"
        d="M393.4 18.6c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v6.9C394.7 18.1 394.1 18.6 393.4 18.6z"
      ></path>
      <path
        class="st1"
        d="M372.2 62.7c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C372.8 62.6 372.5 62.7 372.2 62.7z"
      ></path>
      <path
        class="st1"
        d="M409.8 25.4c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C410.4 25.3 410.1 25.4 409.8 25.4z"
      ></path>
      <path
        class="st1"
        d="M377 25.4c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C377.7 25.3 377.4 25.4 377 25.4z"
      ></path>
      <path
        class="st1"
        d="M414.7 62.7c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C415.3 62.6 415 62.7 414.7 62.7z"
      ></path>
    </g>
    <g class="st9">
      <path
        class="st1"
        d="M180.8 78.8c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V65.7c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C184.3 79.3 182.6 78.8 180.8 78.8zM217.8 129.6h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C239.1 120.1 229.6 129.6 217.8 129.6z"
      ></path>
      <path
        class="st1"
        d="M224.1 80.7c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C225.4 80.1 224.8 80.7 224.1 80.7z"
      ></path>
      <path
        class="st1"
        d="M198.7 87.3c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C200 86.7 199.4 87.3 198.7 87.3z"
      ></path>
      <path
        class="st1"
        d="M181 105.9c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C196 99.3 189.3 105.9 181 105.9z"
      ></path>
      <path
        class="st1"
        d="M176.6 129.6c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C191.5 122.9 184.8 129.6 176.6 129.6z"
      ></path>
      <path
        class="st1"
        d="M217.7 116c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C218.9 115.4 218.4 116 217.7 116z"
      ></path>
      <path
        class="st1"
        d="M210.4 94.3c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C211.7 93.7 211.1 94.3 210.4 94.3z"
      ></path>
      <path
        class="st1"
        d="M255.7 53.5c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C268.1 58.9 262.6 53.5 255.7 53.5zM303.7 129.6H262.2c-11.7 0-21.3-9.5-21.3-21.1V65.7c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C318.5 123 311.8 129.6 303.7 129.6z"
      ></path>
      <path
        class="st1"
        d="M255.9 80.7c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C270.7 74.2 264.2 80.7 255.9 80.7z"
      ></path>
      <path
        class="st1"
        d="M281.4 87.3c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C282.6 86.7 282 87.3 281.4 87.3z"
      ></path>
      <path
        class="st1"
        d="M299 105.9c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C314 99.3 307.3 105.9 299 105.9z"
      ></path>
      <path
        class="st1"
        d="M303.5 129.6c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C318.5 122.9 311.7 129.6 303.5 129.6z"
      ></path>
      <path
        class="st1"
        d="M289.8 116c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C291 115.4 290.5 116 289.8 116z"
      ></path>
      <path
        class="st1"
        d="M242.4 94.3c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C243.7 93.7 243.1 94.3 242.4 94.3z"
      ></path>
      <path
        class="st1"
        d="M262.4 129.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C277.3 122.9 270.6 129.6 262.4 129.6z"
      ></path>
      <path
        class="st1"
        d="M140 171.6c-7.2 0-13-6-13-13.3V98.6c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H78.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V140.5c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C153 165.6 147.2 171.6 140 171.6z"
      ></path>
      <path
        class="st1"
        d="M381.4 169.9c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V82.4c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C394.4 163.9 388.5 169.9 381.4 169.9z"
      ></path>
      <path
        class="st1"
        d="M393.1 82.4c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C395.9 81.3 394.7 82.4 393.1 82.4z"
      ></path>
      <path
        class="st1"
        d="M393.1 25.6c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C408.3 32.4 401.5 25.6 393.1 25.6zM393.1 67.4c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C405.8 65.4 399 67.4 393.1 67.4z"
      ></path>
      <path
        class="st1"
        d="M393.1 71.9c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.1 70.4 398.4 71.9 393.1 71.9z"
      ></path>
      <path
        class="st1"
        d="M393.1 76.4c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.1 74.8 398.4 76.4 393.1 76.4z"
      ></path>
      <path
        class="st1"
        d="M393.1 80.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C404.1 79.2 398.4 80.8 393.1 80.8z"
      ></path>
      <path
        class="st1"
        d="M12.1 108.3c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H12.1zM35.6 145H12.1c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C43.6 141.4 40 145 35.6 145z"
      ></path>
      <path
        class="st1"
        d="M23.9 116.1c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C33.3 120.2 29 116.1 23.9 116.1zM23.9 137.2c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C35.8 131.9 30.4 137.2 23.9 137.2z"
      ></path>
      <path
        class="st1"
        d="M28.9 125.2c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C29.1 125.3 29 125.2 28.9 125.2zM28.9 128c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C31.6 126.9 30.4 128 28.9 128z"
      ></path>
      <path
        class="st1"
        d="M18.8 125.2c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C19 125.3 18.9 125.2 18.8 125.2zM18.8 128c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C21.5 126.9 20.3 128 18.8 128z"
      ></path>
      <path
        class="st1"
        d="M63.5 134.7h0.9v-18.7h-0.9V134.7zM65.7 137.2h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C66.9 136.7 66.4 137.2 65.7 137.2z"
      ></path>
      <path
        class="st1"
        d="M70.8 134.7h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C80.2 130.5 76 134.7 70.8 134.7z"
      ></path>
      <path
        class="st1"
        d="M62.2 132.7H51.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C63.4 132.1 62.9 132.7 62.2 132.7z"
      ></path>
      <path
        class="st1"
        d="M423.2 41.2h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C424.4 40.6 423.9 41.2 423.2 41.2z"
      ></path>
      <path
        class="st1"
        d="M369.9 41.2h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C371.2 40.6 370.6 41.2 369.9 41.2z"
      ></path>
      <path
        class="st1"
        d="M393.1 18.2c-0.7 0-1.2-0.5-1.2-1.2v-6.9c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v6.9C394.4 17.6 393.8 18.2 393.1 18.2z"
      ></path>
      <path
        class="st1"
        d="M371.8 62.3c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C372.5 62.1 372.2 62.3 371.8 62.3z"
      ></path>
      <path
        class="st1"
        d="M409.5 24.9c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C410.1 24.8 409.8 24.9 409.5 24.9z"
      ></path>
      <path
        class="st1"
        d="M376.7 24.9c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C377.4 24.8 377 24.9 376.7 24.9z"
      ></path>
      <path
        class="st1"
        d="M414.4 62.3c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C415 62.1 414.7 62.3 414.4 62.3z"
      ></path>
    </g>
    <g class="st10">
      <path
        class="st1"
        d="M180.5 78.3c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V65.2c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C184 78.8 182.3 78.3 180.5 78.3zM217.5 129.1h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C238.8 119.7 229.2 129.1 217.5 129.1z"
      ></path>
      <path
        class="st1"
        d="M223.8 80.2c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C225.1 79.7 224.5 80.2 223.8 80.2z"
      ></path>
      <path
        class="st1"
        d="M198.4 86.8c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C199.6 86.3 199.1 86.8 198.4 86.8z"
      ></path>
      <path
        class="st1"
        d="M180.7 105.5c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C195.6 98.8 188.9 105.5 180.7 105.5z"
      ></path>
      <path
        class="st1"
        d="M176.2 129.1c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C191.2 122.5 184.5 129.1 176.2 129.1z"
      ></path>
      <path
        class="st1"
        d="M217.4 115.5c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C218.6 115 218.1 115.5 217.4 115.5z"
      ></path>
      <path
        class="st1"
        d="M210.1 93.8c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C211.4 93.3 210.8 93.8 210.1 93.8z"
      ></path>
      <path
        class="st1"
        d="M255.4 53c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C267.8 58.5 262.3 53 255.4 53zM303.3 129.1h-41.5c-11.7 0-21.3-9.5-21.3-21.1V65.2c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C318.1 122.5 311.5 129.1 303.3 129.1z"
      ></path>
      <path
        class="st1"
        d="M255.6 80.2c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C270.3 73.7 263.9 80.2 255.6 80.2z"
      ></path>
      <path
        class="st1"
        d="M281 86.8c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C282.3 86.3 281.7 86.8 281 86.8z"
      ></path>
      <path
        class="st1"
        d="M298.7 105.5c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C313.7 98.8 307 105.5 298.7 105.5z"
      ></path>
      <path
        class="st1"
        d="M303.2 129.1c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C318.1 122.5 311.4 129.1 303.2 129.1z"
      ></path>
      <path
        class="st1"
        d="M289.5 115.5c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C290.7 115 290.2 115.5 289.5 115.5z"
      ></path>
      <path
        class="st1"
        d="M242.1 93.8c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C243.3 93.3 242.8 93.8 242.1 93.8z"
      ></path>
      <path
        class="st1"
        d="M262 129.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C277 122.5 270.3 129.1 262 129.1z"
      ></path>
      <path
        class="st1"
        d="M139.7 171.1c-7.2 0-13-6-13-13.3V98.1c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H78.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2H163.6c-6 0-10.9 4.9-10.9 10.9v17.7C152.7 165.1 146.8 171.1 139.7 171.1z"
      ></path>
      <path
        class="st1"
        d="M381.1 169.4c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V81.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C394 163.5 388.2 169.4 381.1 169.4z"
      ></path>
      <path
        class="st1"
        d="M392.8 81.9c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C395.6 80.9 394.4 81.9 392.8 81.9z"
      ></path>
      <path
        class="st1"
        d="M392.8 25.1c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C408 31.9 401.2 25.1 392.8 25.1zM392.8 66.9c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C405.5 65 398.7 66.9 392.8 66.9z"
      ></path>
      <path
        class="st1"
        d="M392.8 71.5c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.7 69.9 398.1 71.5 392.8 71.5z"
      ></path>
      <path
        class="st1"
        d="M392.8 75.9c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.7 74.3 398.1 75.9 392.8 75.9z"
      ></path>
      <path
        class="st1"
        d="M392.8 80.4c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.7 78.8 398.1 80.4 392.8 80.4z"
      ></path>
      <path
        class="st1"
        d="M11.8 107.8c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H11.8zM35.3 144.5h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C43.3 141 39.7 144.5 35.3 144.5z"
      ></path>
      <path
        class="st1"
        d="M23.5 115.6c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C32.9 119.8 28.7 115.6 23.5 115.6zM23.5 136.7c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C35.4 131.4 30.1 136.7 23.5 136.7z"
      ></path>
      <path
        class="st1"
        d="M28.6 124.8c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C28.8 124.8 28.7 124.8 28.6 124.8zM28.6 127.6c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C31.3 126.4 30.1 127.6 28.6 127.6z"
      ></path>
      <path
        class="st1"
        d="M18.5 124.8c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C18.6 124.8 18.6 124.8 18.5 124.8zM18.5 127.6c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C21.1 126.4 19.9 127.6 18.5 127.6z"
      ></path>
      <path
        class="st1"
        d="M63.2 134.3h0.9v-18.7H63.2V134.3zM65.4 136.7h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C66.6 136.2 66.1 136.7 65.4 136.7z"
      ></path>
      <path
        class="st1"
        d="M70.5 134.2h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C79.8 130 75.7 134.2 70.5 134.2z"
      ></path>
      <path
        class="st1"
        d="M61.9 132.2H51.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C63.1 131.7 62.5 132.2 61.9 132.2z"
      ></path>
      <path
        class="st1"
        d="M422.8 40.7h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C424.1 40.2 423.5 40.7 422.8 40.7z"
      ></path>
      <path
        class="st1"
        d="M369.6 40.7h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C370.9 40.2 370.3 40.7 369.6 40.7z"
      ></path>
      <path
        class="st1"
        d="M392.8 17.7c-0.7 0-1.2-0.5-1.2-1.2V9.6c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C394 17.2 393.5 17.7 392.8 17.7z"
      ></path>
      <path
        class="st1"
        d="M371.5 61.8c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C372.2 61.7 371.8 61.8 371.5 61.8z"
      ></path>
      <path
        class="st1"
        d="M409.2 24.5c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C409.8 24.3 409.5 24.5 409.2 24.5z"
      ></path>
      <path
        class="st1"
        d="M376.4 24.5c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C377.1 24.3 376.7 24.5 376.4 24.5z"
      ></path>
      <path
        class="st1"
        d="M414 61.8c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C414.7 61.7 414.4 61.8 414 61.8z"
      ></path>
    </g>
    <g class="st11">
      <path
        class="st1"
        d="M180.2 77.9c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V64.7c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C183.7 78.3 182 77.9 180.2 77.9zM217.2 128.7h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C238.5 119.2 228.9 128.7 217.2 128.7z"
      ></path>
      <path
        class="st1"
        d="M223.5 79.8c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C224.8 79.2 224.2 79.8 223.5 79.8z"
      ></path>
      <path
        class="st1"
        d="M198.1 86.4c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C199.3 85.8 198.8 86.4 198.1 86.4z"
      ></path>
      <path
        class="st1"
        d="M180.4 105c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C195.3 98.4 188.6 105 180.4 105z"
      ></path>
      <path
        class="st1"
        d="M175.9 128.7c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C190.9 122 184.2 128.7 175.9 128.7z"
      ></path>
      <path
        class="st1"
        d="M217.1 115.1c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C218.3 114.5 217.7 115.1 217.1 115.1z"
      ></path>
      <path
        class="st1"
        d="M209.8 93.4c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C211 92.8 210.5 93.4 209.8 93.4z"
      ></path>
      <path
        class="st1"
        d="M255.1 52.5c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C267.5 58 261.9 52.5 255.1 52.5zM303 128.7h-41.5c-11.7 0-21.3-9.5-21.3-21.1V64.7c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C317.8 122.1 311.2 128.7 303 128.7z"
      ></path>
      <path
        class="st1"
        d="M255.3 79.8c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C270 73.2 263.5 79.8 255.3 79.8z"
      ></path>
      <path
        class="st1"
        d="M280.7 86.4c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C282 85.8 281.4 86.4 280.7 86.4z"
      ></path>
      <path
        class="st1"
        d="M298.4 105c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C313.4 98.4 306.7 105 298.4 105z"
      ></path>
      <path
        class="st1"
        d="M302.9 128.7c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C317.8 122 311.1 128.7 302.9 128.7z"
      ></path>
      <path
        class="st1"
        d="M289.1 115.1c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C290.4 114.5 289.8 115.1 289.1 115.1z"
      ></path>
      <path
        class="st1"
        d="M241.8 93.4c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C243 92.8 242.5 93.4 241.8 93.4z"
      ></path>
      <path
        class="st1"
        d="M261.7 128.7c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C276.7 122 270 128.7 261.7 128.7z"
      ></path>
      <path
        class="st1"
        d="M139.4 170.7c-7.2 0-13-6-13-13.3V97.7c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H78.3c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2s-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C152.4 164.7 146.5 170.7 139.4 170.7z"
      ></path>
      <path
        class="st1"
        d="M380.7 169c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V81.5c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C393.7 163 387.9 169 380.7 169z"
      ></path>
      <path
        class="st1"
        d="M392.5 81.5c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C395.3 80.4 394.1 81.5 392.5 81.5z"
      ></path>
      <path
        class="st1"
        d="M392.5 24.6c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C407.7 31.5 400.9 24.6 392.5 24.6zM392.5 66.4c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2L378 53.7c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C405.2 64.5 398.4 66.4 392.5 66.4z"
      ></path>
      <path
        class="st1"
        d="M392.5 71c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.4 69.4 397.8 71 392.5 71z"
      ></path>
      <path
        class="st1"
        d="M392.5 75.5c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.4 73.9 397.8 75.5 392.5 75.5z"
      ></path>
      <path
        class="st1"
        d="M392.5 79.9c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.4 78.3 397.8 79.9 392.5 79.9z"
      ></path>
      <path
        class="st1"
        d="M11.5 107.4c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4V112.8c0-3-2.4-5.4-5.5-5.4H11.5zM35 144h-23.5c-4.4 0-8-3.5-8-7.9V112.8c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C42.9 140.5 39.4 144 35 144z"
      ></path>
      <path
        class="st1"
        d="M23.2 115.1c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C32.6 119.3 28.4 115.1 23.2 115.1zM23.2 136.3c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C35.1 131 29.8 136.3 23.2 136.3z"
      ></path>
      <path
        class="st1"
        d="M28.3 124.3c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C28.5 124.4 28.4 124.3 28.3 124.3zM28.3 127.1c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C31 125.9 29.8 127.1 28.3 127.1z"
      ></path>
      <path
        class="st1"
        d="M18.1 124.3c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C18.3 124.4 18.2 124.3 18.1 124.3zM18.1 127.1c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C20.8 125.9 19.6 127.1 18.1 127.1z"
      ></path>
      <path
        class="st1"
        d="M62.9 133.8h0.9v-18.7h-0.9V133.8zM65.1 136.3h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C66.3 135.7 65.7 136.3 65.1 136.3z"
      ></path>
      <path
        class="st1"
        d="M70.2 133.7h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C79.5 129.6 75.3 133.7 70.2 133.7z"
      ></path>
      <path
        class="st1"
        d="M61.5 131.8H51.3c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C62.8 131.2 62.2 131.8 61.5 131.8z"
      ></path>
      <path
        class="st1"
        d="M422.5 40.2h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C423.8 39.7 423.2 40.2 422.5 40.2z"
      ></path>
      <path
        class="st1"
        d="M369.3 40.2h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C370.6 39.7 370 40.2 369.3 40.2z"
      ></path>
      <path
        class="st1"
        d="M392.5 17.3c-0.7 0-1.2-0.5-1.2-1.2V9.2c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v6.9C393.7 16.7 393.2 17.3 392.5 17.3z"
      ></path>
      <path
        class="st1"
        d="M371.2 61.3c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C371.9 61.2 371.5 61.3 371.2 61.3z"
      ></path>
      <path
        class="st1"
        d="M408.8 24c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C409.5 23.9 409.2 24 408.8 24z"
      ></path>
      <path
        class="st1"
        d="M376.1 24c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C376.7 23.9 376.4 24 376.1 24z"
      ></path>
      <path
        class="st1"
        d="M413.7 61.3c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C414.4 61.2 414 61.3 413.7 61.3z"
      ></path>
    </g>
    <g class="st12">
      <path
        class="st1"
        d="M179.8 77.4c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V64.3c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C183.3 77.9 181.7 77.4 179.8 77.4zM216.9 128.2h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C238.2 118.7 228.6 128.2 216.9 128.2z"
      ></path>
      <path
        class="st1"
        d="M223.2 79.3c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C224.4 78.7 223.9 79.3 223.2 79.3z"
      ></path>
      <path
        class="st1"
        d="M197.7 85.9c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C199 85.3 198.4 85.9 197.7 85.9z"
      ></path>
      <path
        class="st1"
        d="M180 104.6c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C195 97.9 188.3 104.6 180 104.6z"
      ></path>
      <path
        class="st1"
        d="M175.6 128.2c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C190.6 121.6 183.8 128.2 175.6 128.2z"
      ></path>
      <path
        class="st1"
        d="M216.7 114.6c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C218 114.1 217.4 114.6 216.7 114.6z"
      ></path>
      <path
        class="st1"
        d="M209.5 92.9c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C210.7 92.4 210.2 92.9 209.5 92.9z"
      ></path>
      <path
        class="st1"
        d="M254.8 52.1c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C267.2 57.6 261.6 52.1 254.8 52.1zM302.7 128.2h-41.5c-11.7 0-21.3-9.5-21.3-21.1V64.3c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C317.5 121.6 310.9 128.2 302.7 128.2z"
      ></path>
      <path
        class="st1"
        d="M255 79.3c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C269.7 72.8 263.2 79.3 255 79.3z"
      ></path>
      <path
        class="st1"
        d="M280.4 85.9c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C281.6 85.3 281.1 85.9 280.4 85.9z"
      ></path>
      <path
        class="st1"
        d="M298.1 104.6c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C313.1 97.9 306.3 104.6 298.1 104.6z"
      ></path>
      <path
        class="st1"
        d="M302.5 128.2c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C317.5 121.6 310.8 128.2 302.5 128.2z"
      ></path>
      <path
        class="st1"
        d="M288.8 114.6c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C290.1 114.1 289.5 114.6 288.8 114.6z"
      ></path>
      <path
        class="st1"
        d="M241.4 92.9c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C242.7 92.4 242.1 92.9 241.4 92.9z"
      ></path>
      <path
        class="st1"
        d="M261.4 128.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C276.4 121.6 269.7 128.2 261.4 128.2z"
      ></path>
      <path
        class="st1"
        d="M139.1 170.2c-7.2 0-13-6-13-13.3V97.2c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H78c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C152 164.2 146.2 170.2 139.1 170.2z"
      ></path>
      <path
        class="st1"
        d="M380.4 168.5c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V81c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C393.4 162.5 387.6 168.5 380.4 168.5z"
      ></path>
      <path
        class="st1"
        d="M392.1 81c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C394.9 80 393.7 81 392.1 81z"
      ></path>
      <path
        class="st1"
        d="M392.1 24.2c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C407.4 31 400.6 24.2 392.1 24.2zM392.1 66c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C404.9 64.1 398.1 66 392.1 66z"
      ></path>
      <path
        class="st1"
        d="M392.1 70.6c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.1 69 397.4 70.6 392.1 70.6z"
      ></path>
      <path
        class="st1"
        d="M392.1 75c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.1 73.4 397.4 75 392.1 75z"
      ></path>
      <path
        class="st1"
        d="M392.1 79.4c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C403.1 77.9 397.4 79.4 392.1 79.4z"
      ></path>
      <path
        class="st1"
        d="M11.1 106.9c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H11.1zM34.6 143.6h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C42.6 140 39 143.6 34.6 143.6z"
      ></path>
      <path
        class="st1"
        d="M22.9 114.7c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C32.3 118.9 28.1 114.7 22.9 114.7zM22.9 135.8c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C34.8 130.5 29.5 135.8 22.9 135.8z"
      ></path>
      <path
        class="st1"
        d="M28 123.8c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C28.1 123.9 28.1 123.8 28 123.8zM28 126.7c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C30.6 125.5 29.4 126.7 28 126.7z"
      ></path>
      <path
        class="st1"
        d="M17.8 123.8c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C18 123.9 17.9 123.8 17.8 123.8zM17.8 126.7c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C20.5 125.5 19.3 126.7 17.8 126.7z"
      ></path>
      <path
        class="st1"
        d="M62.6 133.3h0.9v-18.7h-0.9V133.3zM64.7 135.8h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C66 135.3 65.4 135.8 64.7 135.8z"
      ></path>
      <path
        class="st1"
        d="M69.9 133.3h-5.1c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C79.2 129.1 75 133.3 69.9 133.3z"
      ></path>
      <path
        class="st1"
        d="M61.2 131.3H50.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C62.5 130.7 61.9 131.3 61.2 131.3z"
      ></path>
      <path
        class="st1"
        d="M422.2 39.8h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C423.5 39.2 422.9 39.8 422.2 39.8z"
      ></path>
      <path
        class="st1"
        d="M369 39.8h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C370.2 39.2 369.7 39.8 369 39.8z"
      ></path>
      <path
        class="st1"
        d="M392.1 16.8c-0.7 0-1.2-0.5-1.2-1.2V8.7c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v6.9C393.4 16.2 392.8 16.8 392.1 16.8z"
      ></path>
      <path
        class="st1"
        d="M370.9 60.9c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C371.5 60.8 371.2 60.9 370.9 60.9z"
      ></path>
      <path
        class="st1"
        d="M408.5 23.5c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C409.2 23.4 408.8 23.5 408.5 23.5z"
      ></path>
      <path
        class="st1"
        d="M375.8 23.5c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C376.4 23.4 376.1 23.5 375.8 23.5z"
      ></path>
      <path
        class="st1"
        d="M413.4 60.9c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C414 60.8 413.7 60.9 413.4 60.9z"
      ></path>
    </g>
    <g class="st13">
      <path
        class="st1"
        d="M179.5 77c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V63.8c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C183 77.4 181.3 77 179.5 77zM216.6 127.8h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C237.8 118.3 228.3 127.8 216.6 127.8z"
      ></path>
      <path
        class="st1"
        d="M222.9 78.8c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C224.1 78.3 223.6 78.8 222.9 78.8z"
      ></path>
      <path
        class="st1"
        d="M197.4 85.4c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C198.7 84.9 198.1 85.4 197.4 85.4z"
      ></path>
      <path
        class="st1"
        d="M179.7 104.1c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C194.7 97.4 188 104.1 179.7 104.1z"
      ></path>
      <path
        class="st1"
        d="M175.3 127.8c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C190.2 121.1 183.5 127.8 175.3 127.8z"
      ></path>
      <path
        class="st1"
        d="M216.4 114.1c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C217.7 113.6 217.1 114.1 216.4 114.1z"
      ></path>
      <path
        class="st1"
        d="M209.2 92.5c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C210.4 91.9 209.8 92.5 209.2 92.5z"
      ></path>
      <path
        class="st1"
        d="M254.5 51.6c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C266.9 57.1 261.3 51.6 254.5 51.6zM302.4 127.8h-41.5c-11.7 0-21.3-9.5-21.3-21.1V63.8c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C317.2 121.2 310.5 127.8 302.4 127.8z"
      ></path>
      <path
        class="st1"
        d="M254.6 78.8c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C269.4 72.3 262.9 78.8 254.6 78.8z"
      ></path>
      <path
        class="st1"
        d="M280.1 85.4c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C281.3 84.9 280.8 85.4 280.1 85.4z"
      ></path>
      <path
        class="st1"
        d="M297.8 104.1c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C312.7 97.4 306 104.1 297.8 104.1z"
      ></path>
      <path
        class="st1"
        d="M302.2 127.8c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C317.2 121.1 310.5 127.8 302.2 127.8z"
      ></path>
      <path
        class="st1"
        d="M288.5 114.1c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C289.8 113.6 289.2 114.1 288.5 114.1z"
      ></path>
      <path
        class="st1"
        d="M241.1 92.5c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C242.4 91.9 241.8 92.5 241.1 92.5z"
      ></path>
      <path
        class="st1"
        d="M261.1 127.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C276.1 121.1 269.3 127.8 261.1 127.8z"
      ></path>
      <path
        class="st1"
        d="M138.7 169.7c-7.2 0-13-6-13-13.3V96.7c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H77.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2H92c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C151.7 163.8 145.9 169.7 138.7 169.7z"
      ></path>
      <path
        class="st1"
        d="M380.1 168.1c-7.2 0-13-6-13-13.3V114.4c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V80.5c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C393.1 162.1 387.3 168.1 380.1 168.1z"
      ></path>
      <path
        class="st1"
        d="M391.8 80.5c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C394.6 79.5 393.4 80.5 391.8 80.5z"
      ></path>
      <path
        class="st1"
        d="M391.8 23.7c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C407.1 30.5 400.2 23.7 391.8 23.7zM391.8 65.5c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C404.5 63.6 397.8 65.5 391.8 65.5z"
      ></path>
      <path
        class="st1"
        d="M391.8 70.1c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C402.8 68.5 397.1 70.1 391.8 70.1z"
      ></path>
      <path
        class="st1"
        d="M391.8 74.5c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C402.8 73 397.1 74.5 391.8 74.5z"
      ></path>
      <path
        class="st1"
        d="M391.8 79c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C402.8 77.4 397.1 79 391.8 79z"
      ></path>
      <path
        class="st1"
        d="M10.8 106.5c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H10.8zM34.3 143.1h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C42.3 139.6 38.7 143.1 34.3 143.1z"
      ></path>
      <path
        class="st1"
        d="M22.6 114.2c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C32 118.4 27.8 114.2 22.6 114.2zM22.6 135.4c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C34.5 130.1 29.1 135.4 22.6 135.4z"
      ></path>
      <path
        class="st1"
        d="M27.6 123.4c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C27.8 123.5 27.7 123.4 27.6 123.4zM27.6 126.2c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C30.3 125 29.1 126.2 27.6 126.2z"
      ></path>
      <path
        class="st1"
        d="M17.5 123.4c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C17.7 123.5 17.6 123.4 17.5 123.4zM17.5 126.2c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C20.2 125 19 126.2 17.5 126.2z"
      ></path>
      <path
        class="st1"
        d="M62.2 132.9h0.9v-18.7h-0.9V132.9zM64.4 135.4h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C65.7 134.8 65.1 135.4 64.4 135.4z"
      ></path>
      <path
        class="st1"
        d="M69.6 132.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C78.9 128.7 74.7 132.8 69.6 132.8z"
      ></path>
      <path
        class="st1"
        d="M60.9 130.8H50.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2H60.9c0.7 0 1.3 0.6 1.3 1.2v12.1C62.2 130.3 61.6 130.8 60.9 130.8z"
      ></path>
      <path
        class="st1"
        d="M421.9 39.3h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C423.1 38.8 422.6 39.3 421.9 39.3z"
      ></path>
      <path
        class="st1"
        d="M368.7 39.3h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C369.9 38.8 369.4 39.3 368.7 39.3z"
      ></path>
      <path
        class="st1"
        d="M391.8 16.3c-0.7 0-1.2-0.5-1.2-1.2V8.2c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v6.9C393.1 15.8 392.5 16.3 391.8 16.3z"
      ></path>
      <path
        class="st1"
        d="M370.6 60.4c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C371.2 60.3 370.9 60.4 370.6 60.4z"
      ></path>
      <path
        class="st1"
        d="M408.2 23.1c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C408.8 23 408.5 23.1 408.2 23.1z"
      ></path>
      <path
        class="st1"
        d="M375.5 23.1c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C376.1 22.9 375.8 23.1 375.5 23.1z"
      ></path>
      <path
        class="st1"
        d="M413.1 60.4c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C413.7 60.3 413.4 60.4 413.1 60.4z"
      ></path>
    </g>
    <g class="st14">
      <path
        class="st1"
        d="M179.2 76.5c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V63.4c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C182.7 77 181 76.5 179.2 76.5zM216.3 127.3H174.8c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C237.5 117.8 228 127.3 216.3 127.3z"
      ></path>
      <path
        class="st1"
        d="M222.6 78.4c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C223.8 77.8 223.2 78.4 222.6 78.4z"
      ></path>
      <path
        class="st1"
        d="M197.1 85c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C198.4 84.4 197.8 85 197.1 85z"
      ></path>
      <path
        class="st1"
        d="M179.4 103.6c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C194.4 97 187.7 103.6 179.4 103.6z"
      ></path>
      <path
        class="st1"
        d="M175 127.3c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C189.9 120.6 183.2 127.3 175 127.3z"
      ></path>
      <path
        class="st1"
        d="M216.1 113.7c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C217.4 113.1 216.8 113.7 216.1 113.7z"
      ></path>
      <path
        class="st1"
        d="M208.8 92c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C210.1 91.4 209.5 92 208.8 92z"
      ></path>
      <path
        class="st1"
        d="M254.2 51.2c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C266.6 56.6 261 51.2 254.2 51.2zM302.1 127.3h-41.5c-11.7 0-21.3-9.5-21.3-21.1V63.4c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C316.9 120.7 310.2 127.3 302.1 127.3z"
      ></path>
      <path
        class="st1"
        d="M254.3 78.4c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C269.1 71.9 262.6 78.4 254.3 78.4z"
      ></path>
      <path
        class="st1"
        d="M279.8 85c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C281 84.4 280.4 85 279.8 85z"
      ></path>
      <path
        class="st1"
        d="M297.5 103.6c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C312.4 97 305.7 103.6 297.5 103.6z"
      ></path>
      <path
        class="st1"
        d="M301.9 127.3c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C316.9 120.6 310.2 127.3 301.9 127.3z"
      ></path>
      <path
        class="st1"
        d="M288.2 113.7c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C289.4 113.1 288.9 113.7 288.2 113.7z"
      ></path>
      <path
        class="st1"
        d="M240.8 92c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C242.1 91.4 241.5 92 240.8 92z"
      ></path>
      <path
        class="st1"
        d="M260.8 127.3c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C275.7 120.6 269 127.3 260.8 127.3z"
      ></path>
      <path
        class="st1"
        d="M138.4 169.3c-7.2 0-13-6-13-13.3V96.3c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H77.3c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V138.2c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C151.4 163.3 145.6 169.3 138.4 169.3z"
      ></path>
      <path
        class="st1"
        d="M379.8 167.6c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V80.1c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C392.8 161.6 386.9 167.6 379.8 167.6z"
      ></path>
      <path
        class="st1"
        d="M391.5 80.1c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C394.3 79 393.1 80.1 391.5 80.1z"
      ></path>
      <path
        class="st1"
        d="M391.5 23.2c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C406.8 30.1 399.9 23.2 391.5 23.2zM391.5 65.1c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C404.2 63.1 397.4 65.1 391.5 65.1z"
      ></path>
      <path
        class="st1"
        d="M391.5 69.6c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C402.5 68.1 396.8 69.6 391.5 69.6z"
      ></path>
      <path
        class="st1"
        d="M391.5 74.1c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C402.5 72.5 396.8 74.1 391.5 74.1z"
      ></path>
      <path
        class="st1"
        d="M391.5 78.5c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C402.5 76.9 396.8 78.5 391.5 78.5z"
      ></path>
      <path
        class="st1"
        d="M10.5 106c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H10.5zM34 142.7h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C42 139.1 38.4 142.7 34 142.7z"
      ></path>
      <path
        class="st1"
        d="M22.3 113.8c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C31.7 117.9 27.4 113.8 22.3 113.8zM22.3 134.9c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C34.2 129.6 28.8 134.9 22.3 134.9z"
      ></path>
      <path
        class="st1"
        d="M27.3 122.9c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C27.5 123 27.4 122.9 27.3 122.9zM27.3 125.7c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C30 124.5 28.8 125.7 27.3 125.7z"
      ></path>
      <path
        class="st1"
        d="M17.2 122.9c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C17.4 123 17.3 122.9 17.2 122.9zM17.2 125.7c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C19.9 124.5 18.7 125.7 17.2 125.7z"
      ></path>
      <path
        class="st1"
        d="M61.9 132.4h0.9v-18.7h-0.9V132.4zM64.1 134.9h-3.4c-0.7 0-1.2-0.6-1.2-1.2V112.5c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C65.4 134.3 64.8 134.9 64.1 134.9z"
      ></path>
      <path
        class="st1"
        d="M69.2 132.4H64.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8H64.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C78.6 128.2 74.4 132.4 69.2 132.4z"
      ></path>
      <path
        class="st1"
        d="M60.6 130.4H50.3c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C61.8 129.8 61.3 130.4 60.6 130.4z"
      ></path>
      <path
        class="st1"
        d="M421.6 38.9h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C422.8 38.3 422.3 38.9 421.6 38.9z"
      ></path>
      <path
        class="st1"
        d="M368.4 38.9h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C369.6 38.3 369.1 38.9 368.4 38.9z"
      ></path>
      <path
        class="st1"
        d="M391.5 15.9c-0.7 0-1.2-0.5-1.2-1.2V7.8c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C392.8 15.3 392.2 15.9 391.5 15.9z"
      ></path>
      <path
        class="st1"
        d="M370.3 60c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C370.9 59.8 370.6 60 370.3 60z"
      ></path>
      <path
        class="st1"
        d="M407.9 22.6c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C408.5 22.5 408.2 22.6 407.9 22.6z"
      ></path>
      <path
        class="st1"
        d="M375.1 22.6c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C375.8 22.5 375.5 22.6 375.1 22.6z"
      ></path>
      <path
        class="st1"
        d="M412.8 60c-0.3 0-0.6-0.1-0.9-0.4L407 54.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C413.4 59.8 413.1 60 412.8 60z"
      ></path>
    </g>
    <g class="st15">
      <path
        class="st1"
        d="M178.9 76c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V62.9c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C182.4 76.5 180.7 76 178.9 76zM215.9 126.8h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C237.2 117.4 227.7 126.8 215.9 126.8z"
      ></path>
      <path
        class="st1"
        d="M222.2 77.9c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C223.5 77.4 222.9 77.9 222.2 77.9z"
      ></path>
      <path
        class="st1"
        d="M196.8 84.5c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C198 84 197.5 84.5 196.8 84.5z"
      ></path>
      <path
        class="st1"
        d="M179.1 103.2c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C194.1 96.5 187.3 103.2 179.1 103.2z"
      ></path>
      <path
        class="st1"
        d="M174.6 126.8c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C189.6 120.2 182.9 126.8 174.6 126.8z"
      ></path>
      <path
        class="st1"
        d="M215.8 113.2c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C217 112.7 216.5 113.2 215.8 113.2z"
      ></path>
      <path
        class="st1"
        d="M208.5 91.5c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C209.8 91 209.2 91.5 208.5 91.5z"
      ></path>
      <path
        class="st1"
        d="M253.8 50.7c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C266.2 56.2 260.7 50.7 253.8 50.7zM301.8 126.8H260.3c-11.7 0-21.3-9.5-21.3-21.1V62.9c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C316.6 120.2 309.9 126.8 301.8 126.8z"
      ></path>
      <path
        class="st1"
        d="M254 77.9c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C268.7 71.4 262.3 77.9 254 77.9z"
      ></path>
      <path
        class="st1"
        d="M279.4 84.5c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C280.7 84 280.1 84.5 279.4 84.5z"
      ></path>
      <path
        class="st1"
        d="M297.1 103.2c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C312.1 96.5 305.4 103.2 297.1 103.2z"
      ></path>
      <path
        class="st1"
        d="M301.6 126.8c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C316.6 120.2 309.8 126.8 301.6 126.8z"
      ></path>
      <path
        class="st1"
        d="M287.9 113.2c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C289.1 112.7 288.6 113.2 287.9 113.2z"
      ></path>
      <path
        class="st1"
        d="M240.5 91.5c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C241.7 91 241.2 91.5 240.5 91.5z"
      ></path>
      <path
        class="st1"
        d="M260.5 126.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C275.4 120.2 268.7 126.8 260.5 126.8z"
      ></path>
      <path
        class="st1"
        d="M138.1 168.8c-7.2 0-13-6-13-13.3V95.8c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H77c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C151.1 162.8 145.3 168.8 138.1 168.8z"
      ></path>
      <path
        class="st1"
        d="M379.5 167.1c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3H287.5c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V79.6c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C392.4 161.2 386.6 167.1 379.5 167.1z"
      ></path>
      <path
        class="st1"
        d="M391.2 79.6c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C394 78.6 392.8 79.6 391.2 79.6z"
      ></path>
      <path
        class="st1"
        d="M391.2 22.8c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C406.4 29.6 399.6 22.8 391.2 22.8zM391.2 64.6c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C403.9 62.7 397.1 64.6 391.2 64.6z"
      ></path>
      <path
        class="st1"
        d="M391.2 69.2c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C402.2 67.6 396.5 69.2 391.2 69.2z"
      ></path>
      <path
        class="st1"
        d="M391.2 73.6c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C402.2 72 396.5 73.6 391.2 73.6z"
      ></path>
      <path
        class="st1"
        d="M391.2 78c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C402.2 76.5 396.5 78 391.2 78z"
      ></path>
      <path
        class="st1"
        d="M10.2 105.5c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H10.2zM33.7 142.2h-23.5c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C41.7 138.6 38.1 142.2 33.7 142.2z"
      ></path>
      <path
        class="st1"
        d="M21.9 113.3c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C31.3 117.5 27.1 113.3 21.9 113.3zM21.9 134.4c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C33.8 129.1 28.5 134.4 21.9 134.4z"
      ></path>
      <path
        class="st1"
        d="M27 122.5c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C27.2 122.5 27.1 122.5 27 122.5zM27 125.3c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C29.7 124.1 28.5 125.3 27 125.3z"
      ></path>
      <path
        class="st1"
        d="M16.9 122.5c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C17 122.5 17 122.5 16.9 122.5zM16.9 125.3c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C19.5 124.1 18.3 125.3 16.9 125.3z"
      ></path>
      <path
        class="st1"
        d="M61.6 132h0.9v-18.7h-0.9V132zM63.8 134.4h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C65 133.9 64.5 134.4 63.8 134.4z"
      ></path>
      <path
        class="st1"
        d="M68.9 131.9h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C78.3 127.7 74.1 131.9 68.9 131.9z"
      ></path>
      <path
        class="st1"
        d="M60.3 129.9H50c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C61.5 129.4 61 129.9 60.3 129.9z"
      ></path>
      <path
        class="st1"
        d="M421.3 38.4h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C422.5 37.8 421.9 38.4 421.3 38.4z"
      ></path>
      <path
        class="st1"
        d="M368 38.4h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C369.3 37.8 368.7 38.4 368 38.4z"
      ></path>
      <path
        class="st1"
        d="M391.2 15.4c-0.7 0-1.2-0.5-1.2-1.2V7.3c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C392.4 14.9 391.9 15.4 391.2 15.4z"
      ></path>
      <path
        class="st1"
        d="M369.9 59.5c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C370.6 59.4 370.3 59.5 369.9 59.5z"
      ></path>
      <path
        class="st1"
        d="M407.6 22.1c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C408.2 22 407.9 22.1 407.6 22.1z"
      ></path>
      <path
        class="st1"
        d="M374.8 22.1c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C375.5 22 375.1 22.1 374.8 22.1z"
      ></path>
      <path
        class="st1"
        d="M412.5 59.5c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C413.1 59.4 412.8 59.5 412.5 59.5z"
      ></path>
    </g>
    <g class="st16">
      <path
        class="st1"
        d="M178.6 75.6c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V62.4c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C182.1 76 180.4 75.6 178.6 75.6zM215.6 126.4h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C236.9 116.9 227.3 126.4 215.6 126.4z"
      ></path>
      <path
        class="st1"
        d="M221.9 77.5c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C223.2 76.9 222.6 77.5 221.9 77.5z"
      ></path>
      <path
        class="st1"
        d="M196.5 84.1c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C197.7 83.5 197.2 84.1 196.5 84.1z"
      ></path>
      <path
        class="st1"
        d="M178.8 102.7c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C193.7 96.1 187 102.7 178.8 102.7z"
      ></path>
      <path
        class="st1"
        d="M174.3 126.4c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C189.3 119.7 182.6 126.4 174.3 126.4z"
      ></path>
      <path
        class="st1"
        d="M215.5 112.8c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C216.7 112.2 216.2 112.8 215.5 112.8z"
      ></path>
      <path
        class="st1"
        d="M208.2 91.1c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C209.5 90.5 208.9 91.1 208.2 91.1z"
      ></path>
      <path
        class="st1"
        d="M253.5 50.2c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C265.9 55.7 260.4 50.2 253.5 50.2zM301.4 126.4h-41.5c-11.7 0-21.3-9.5-21.3-21.1V62.4c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C316.2 119.8 309.6 126.4 301.4 126.4z"
      ></path>
      <path
        class="st1"
        d="M253.7 77.5c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C268.4 70.9 262 77.5 253.7 77.5z"
      ></path>
      <path
        class="st1"
        d="M279.1 84.1c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C280.4 83.5 279.8 84.1 279.1 84.1z"
      ></path>
      <path
        class="st1"
        d="M296.8 102.7c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C311.8 96.1 305.1 102.7 296.8 102.7z"
      ></path>
      <path
        class="st1"
        d="M301.3 126.4c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C316.2 119.7 309.5 126.4 301.3 126.4z"
      ></path>
      <path
        class="st1"
        d="M287.6 112.8c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C288.8 112.2 288.2 112.8 287.6 112.8z"
      ></path>
      <path
        class="st1"
        d="M240.2 91.1c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C241.4 90.5 240.9 91.1 240.2 91.1z"
      ></path>
      <path
        class="st1"
        d="M260.1 126.4c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C275.1 119.7 268.4 126.4 260.1 126.4z"
      ></path>
      <path
        class="st1"
        d="M137.8 168.4c-7.2 0-13-6-13-13.3V95.3c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H76.7c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C150.8 162.4 144.9 168.4 137.8 168.4z"
      ></path>
      <path
        class="st1"
        d="M379.1 166.7c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V79.2c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C392.1 160.7 386.3 166.7 379.1 166.7z"
      ></path>
      <path
        class="st1"
        d="M390.9 79.2c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C393.7 78.1 392.5 79.2 390.9 79.2z"
      ></path>
      <path
        class="st1"
        d="M390.9 22.3c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C406.1 29.2 399.3 22.3 390.9 22.3zM390.9 64.1c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C403.6 62.2 396.8 64.1 390.9 64.1z"
      ></path>
      <path
        class="st1"
        d="M390.9 68.7c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.8 67.1 396.2 68.7 390.9 68.7z"
      ></path>
      <path
        class="st1"
        d="M390.9 73.2c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.8 71.6 396.2 73.2 390.9 73.2z"
      ></path>
      <path
        class="st1"
        d="M390.9 77.6c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.8 76 396.2 77.6 390.9 77.6z"
      ></path>
      <path
        class="st1"
        d="M9.9 105.1c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H9.9zM33.4 141.7H9.9c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C41.3 138.2 37.8 141.7 33.4 141.7z"
      ></path>
      <path
        class="st1"
        d="M21.6 112.8c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C31 117 26.8 112.8 21.6 112.8zM21.6 134c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C33.5 128.7 28.2 134 21.6 134z"
      ></path>
      <path
        class="st1"
        d="M26.7 122c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C26.9 122.1 26.8 122 26.7 122zM26.7 124.8c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C29.4 123.6 28.2 124.8 26.7 124.8z"
      ></path>
      <path
        class="st1"
        d="M16.6 122c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C16.7 122.1 16.7 122 16.6 122zM16.6 124.8c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C19.2 123.6 18 124.8 16.6 124.8z"
      ></path>
      <path
        class="st1"
        d="M61.3 131.5h0.9v-18.7h-0.9V131.5zM63.5 134h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C64.7 133.4 64.2 134 63.5 134z"
      ></path>
      <path
        class="st1"
        d="M68.6 131.4h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C77.9 127.3 73.8 131.4 68.6 131.4z"
      ></path>
      <path
        class="st1"
        d="M59.9 129.4H49.7c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C61.2 128.9 60.6 129.4 59.9 129.4z"
      ></path>
      <path
        class="st1"
        d="M420.9 37.9h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C422.2 37.4 421.6 37.9 420.9 37.9z"
      ></path>
      <path
        class="st1"
        d="M367.7 37.9h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C369 37.4 368.4 37.9 367.7 37.9z"
      ></path>
      <path
        class="st1"
        d="M390.9 15c-0.7 0-1.2-0.5-1.2-1.2V6.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C392.1 14.4 391.6 15 390.9 15z"
      ></path>
      <path
        class="st1"
        d="M369.6 59c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C370.3 58.9 369.9 59 369.6 59z"
      ></path>
      <path
        class="st1"
        d="M407.3 21.7c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C407.9 21.6 407.6 21.7 407.3 21.7z"
      ></path>
      <path
        class="st1"
        d="M374.5 21.7c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C375.1 21.6 374.8 21.7 374.5 21.7z"
      ></path>
      <path
        class="st1"
        d="M412.1 59c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C412.8 58.9 412.5 59 412.1 59z"
      ></path>
    </g>
    <g class="st17">
      <path
        class="st1"
        d="M178.2 75.1c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V62c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C181.8 75.6 180.1 75.1 178.2 75.1zM215.3 125.9h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C236.6 116.4 227 125.9 215.3 125.9z"
      ></path>
      <path
        class="st1"
        d="M221.6 77c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C222.8 76.4 222.3 77 221.6 77z"
      ></path>
      <path
        class="st1"
        d="M196.2 83.6c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C197.4 83 196.9 83.6 196.2 83.6z"
      ></path>
      <path
        class="st1"
        d="M178.5 102.3c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C193.4 95.6 186.7 102.3 178.5 102.3z"
      ></path>
      <path
        class="st1"
        d="M174 125.9c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C189 119.2 182.3 125.9 174 125.9z"
      ></path>
      <path
        class="st1"
        d="M215.2 112.3c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C216.4 111.7 215.8 112.3 215.2 112.3z"
      ></path>
      <path
        class="st1"
        d="M207.9 90.6c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C209.1 90.1 208.6 90.6 207.9 90.6z"
      ></path>
      <path
        class="st1"
        d="M253.2 49.8c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C265.6 55.3 260 49.8 253.2 49.8zM301.1 125.9h-41.5c-11.7 0-21.3-9.5-21.3-21.1V62c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C315.9 119.3 309.3 125.9 301.1 125.9z"
      ></path>
      <path
        class="st1"
        d="M253.4 77c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C268.1 70.5 261.6 77 253.4 77z"
      ></path>
      <path
        class="st1"
        d="M278.8 83.6c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C280.1 83 279.5 83.6 278.8 83.6z"
      ></path>
      <path
        class="st1"
        d="M296.5 102.3c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C311.5 95.6 304.8 102.3 296.5 102.3z"
      ></path>
      <path
        class="st1"
        d="M301 125.9c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C315.9 119.2 309.2 125.9 301 125.9z"
      ></path>
      <path
        class="st1"
        d="M287.2 112.3c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C288.5 111.7 287.9 112.3 287.2 112.3z"
      ></path>
      <path
        class="st1"
        d="M239.9 90.6c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C241.1 90.1 240.6 90.6 239.9 90.6z"
      ></path>
      <path
        class="st1"
        d="M259.8 125.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C274.8 119.2 268.1 125.9 259.8 125.9z"
      ></path>
      <path
        class="st1"
        d="M137.5 167.9c-7.2 0-13-6-13-13.3V94.9c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H76.4c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C150.4 161.9 144.6 167.9 137.5 167.9z"
      ></path>
      <path
        class="st1"
        d="M378.8 166.2c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V78.7c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C391.8 160.2 386 166.2 378.8 166.2z"
      ></path>
      <path
        class="st1"
        d="M390.6 78.7c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C393.4 77.7 392.1 78.7 390.6 78.7z"
      ></path>
      <path
        class="st1"
        d="M390.6 21.9c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C405.8 28.7 399 21.9 390.6 21.9zM390.6 63.7c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C403.3 61.8 396.5 63.7 390.6 63.7z"
      ></path>
      <path
        class="st1"
        d="M390.6 68.3c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.5 66.7 395.9 68.3 390.6 68.3z"
      ></path>
      <path
        class="st1"
        d="M390.6 72.7c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.5 71.1 395.9 72.7 390.6 72.7z"
      ></path>
      <path
        class="st1"
        d="M390.6 77.1c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.5 75.5 395.9 77.1 390.6 77.1z"
      ></path>
      <path
        class="st1"
        d="M9.6 104.6c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H9.6zM33.1 141.3H9.6c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C41 137.7 37.5 141.3 33.1 141.3z"
      ></path>
      <path
        class="st1"
        d="M21.3 112.4c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C30.7 116.6 26.5 112.4 21.3 112.4zM21.3 133.5c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C33.2 128.2 27.9 133.5 21.3 133.5z"
      ></path>
      <path
        class="st1"
        d="M26.4 121.5c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C26.5 121.6 26.5 121.5 26.4 121.5zM26.4 124.4c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C29 123.2 27.8 124.4 26.4 124.4z"
      ></path>
      <path
        class="st1"
        d="M16.2 121.5c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C16.4 121.6 16.3 121.5 16.2 121.5zM16.2 124.4c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C18.9 123.2 17.7 124.4 16.2 124.4z"
      ></path>
      <path
        class="st1"
        d="M61 131h0.9v-18.7h-0.9V131zM63.2 133.5h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C64.4 133 63.8 133.5 63.2 133.5z"
      ></path>
      <path
        class="st1"
        d="M68.3 131h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C77.6 126.8 73.4 131 68.3 131z"
      ></path>
      <path
        class="st1"
        d="M59.6 129H49.3c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C60.9 128.4 60.3 129 59.6 129z"
      ></path>
      <path
        class="st1"
        d="M420.6 37.5h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C421.9 36.9 421.3 37.5 420.6 37.5z"
      ></path>
      <path
        class="st1"
        d="M367.4 37.5h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C368.7 36.9 368.1 37.5 367.4 37.5z"
      ></path>
      <path
        class="st1"
        d="M390.6 14.5c-0.7 0-1.2-0.5-1.2-1.2V6.4c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C391.8 13.9 391.2 14.5 390.6 14.5z"
      ></path>
      <path
        class="st1"
        d="M369.3 58.6c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C369.9 58.5 369.6 58.6 369.3 58.6z"
      ></path>
      <path
        class="st1"
        d="M406.9 21.2c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C407.6 21.1 407.3 21.2 406.9 21.2z"
      ></path>
      <path
        class="st1"
        d="M374.2 21.2c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C374.8 21.1 374.5 21.2 374.2 21.2z"
      ></path>
      <path
        class="st1"
        d="M411.8 58.6c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C412.5 58.5 412.1 58.6 411.8 58.6z"
      ></path>
    </g>
    <g class="st18">
      <path
        class="st1"
        d="M177.9 74.7c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V61.5c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C181.4 75.1 179.8 74.7 177.9 74.7zM215 125.5h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C236.2 116 226.7 125.5 215 125.5z"
      ></path>
      <path
        class="st1"
        d="M221.3 76.5c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C222.5 76 222 76.5 221.3 76.5z"
      ></path>
      <path
        class="st1"
        d="M195.8 83.1c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C197.1 82.6 196.5 83.1 195.8 83.1z"
      ></path>
      <path
        class="st1"
        d="M178.1 101.8c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C193.1 95.1 186.4 101.8 178.1 101.8z"
      ></path>
      <path
        class="st1"
        d="M173.7 125.5c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C188.7 118.8 181.9 125.5 173.7 125.5z"
      ></path>
      <path
        class="st1"
        d="M214.8 111.8c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C216.1 111.3 215.5 111.8 214.8 111.8z"
      ></path>
      <path
        class="st1"
        d="M207.6 90.2c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C208.8 89.6 208.3 90.2 207.6 90.2z"
      ></path>
      <path
        class="st1"
        d="M252.9 49.3c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C265.3 54.8 259.7 49.3 252.9 49.3zM300.8 125.5h-41.5c-11.7 0-21.3-9.5-21.3-21.1V61.5c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C315.6 118.9 309 125.5 300.8 125.5z"
      ></path>
      <path
        class="st1"
        d="M253 76.5c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C267.8 70 261.3 76.5 253 76.5z"
      ></path>
      <path
        class="st1"
        d="M278.5 83.1c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C279.7 82.6 279.2 83.1 278.5 83.1z"
      ></path>
      <path
        class="st1"
        d="M296.2 101.8c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C311.2 95.1 304.4 101.8 296.2 101.8z"
      ></path>
      <path
        class="st1"
        d="M300.6 125.5c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C315.6 118.8 308.9 125.5 300.6 125.5z"
      ></path>
      <path
        class="st1"
        d="M286.9 111.8c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C288.2 111.3 287.6 111.8 286.9 111.8z"
      ></path>
      <path
        class="st1"
        d="M239.5 90.2c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C240.8 89.6 240.2 90.2 239.5 90.2z"
      ></path>
      <path
        class="st1"
        d="M259.5 125.5c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C274.5 118.8 267.7 125.5 259.5 125.5z"
      ></path>
      <path
        class="st1"
        d="M137.1 167.4c-7.2 0-13-6-13-13.3V94.4c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H76.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C150.1 161.5 144.3 167.4 137.1 167.4z"
      ></path>
      <path
        class="st1"
        d="M378.5 165.8c-7.2 0-13-6-13-13.3V112.1c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V78.2c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C391.5 159.8 385.7 165.8 378.5 165.8z"
      ></path>
      <path
        class="st1"
        d="M390.2 78.2c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C393 77.2 391.8 78.2 390.2 78.2z"
      ></path>
      <path
        class="st1"
        d="M390.2 21.4c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C405.5 28.2 398.6 21.4 390.2 21.4zM390.2 63.2c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C403 61.3 396.2 63.2 390.2 63.2z"
      ></path>
      <path
        class="st1"
        d="M390.2 67.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.2 66.2 395.5 67.8 390.2 67.8z"
      ></path>
      <path
        class="st1"
        d="M390.2 72.2c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.2 70.7 395.5 72.2 390.2 72.2z"
      ></path>
      <path
        class="st1"
        d="M390.2 76.7c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C401.2 75.1 395.5 76.7 390.2 76.7z"
      ></path>
      <path
        class="st1"
        d="M9.2 104.2c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H9.2zM32.7 140.8H9.2c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C40.7 137.3 37.1 140.8 32.7 140.8z"
      ></path>
      <path
        class="st1"
        d="M21 111.9c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C30.4 116.1 26.2 111.9 21 111.9zM21 133.1c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C32.9 127.8 27.6 133.1 21 133.1z"
      ></path>
      <path
        class="st1"
        d="M26.1 121.1c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C26.2 121.1 26.2 121.1 26.1 121.1zM26.1 123.9c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C28.7 122.7 27.5 123.9 26.1 123.9z"
      ></path>
      <path
        class="st1"
        d="M15.9 121.1c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C16.1 121.1 16 121.1 15.9 121.1zM15.9 123.9c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C18.6 122.7 17.4 123.9 15.9 123.9z"
      ></path>
      <path
        class="st1"
        d="M60.7 130.6h0.9V111.9h-0.9V130.6zM62.8 133.1H59.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C64.1 132.5 63.5 133.1 62.8 133.1z"
      ></path>
      <path
        class="st1"
        d="M68 130.5h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C77.3 126.4 73.1 130.5 68 130.5z"
      ></path>
      <path
        class="st1"
        d="M59.3 128.5H49c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C60.6 128 60 128.5 59.3 128.5z"
      ></path>
      <path
        class="st1"
        d="M420.3 37h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C421.6 36.5 421 37 420.3 37z"
      ></path>
      <path
        class="st1"
        d="M367.1 37h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C368.3 36.5 367.8 37 367.1 37z"
      ></path>
      <path
        class="st1"
        d="M390.2 14c-0.7 0-1.2-0.5-1.2-1.2V5.9c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C391.5 13.5 390.9 14 390.2 14z"
      ></path>
      <path
        class="st1"
        d="M369 58.1c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C369.6 58 369.3 58.1 369 58.1z"
      ></path>
      <path
        class="st1"
        d="M406.6 20.8c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C407.3 20.6 406.9 20.8 406.6 20.8z"
      ></path>
      <path
        class="st1"
        d="M373.9 20.8c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C374.5 20.6 374.2 20.8 373.9 20.8z"
      ></path>
      <path
        class="st1"
        d="M411.5 58.1c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C412.1 58 411.8 58.1 411.5 58.1z"
      ></path>
    </g>
    <g class="st19">
      <path
        class="st1"
        d="M177.6 74.2c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V61.1c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C181.1 74.7 179.4 74.2 177.6 74.2zM214.7 125h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C235.9 115.5 226.4 125 214.7 125z"
      ></path>
      <path
        class="st1"
        d="M221 76.1c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C222.2 75.5 221.7 76.1 221 76.1z"
      ></path>
      <path
        class="st1"
        d="M195.5 82.7c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C196.8 82.1 196.2 82.7 195.5 82.7z"
      ></path>
      <path
        class="st1"
        d="M177.8 101.3c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C192.8 94.7 186.1 101.3 177.8 101.3z"
      ></path>
      <path
        class="st1"
        d="M173.4 125c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C188.3 118.3 181.6 125 173.4 125z"
      ></path>
      <path
        class="st1"
        d="M214.5 111.4c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C215.8 110.8 215.2 111.4 214.5 111.4z"
      ></path>
      <path
        class="st1"
        d="M207.2 89.7c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C208.5 89.1 207.9 89.7 207.2 89.7z"
      ></path>
      <path
        class="st1"
        d="M252.6 48.9c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C265 54.3 259.4 48.9 252.6 48.9zM300.5 125h-41.5c-11.7 0-21.3-9.5-21.3-21.1V61.1c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C315.3 118.4 308.6 125 300.5 125z"
      ></path>
      <path
        class="st1"
        d="M252.7 76.1c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C267.5 69.6 261 76.1 252.7 76.1z"
      ></path>
      <path
        class="st1"
        d="M278.2 82.7c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C279.4 82.1 278.9 82.7 278.2 82.7z"
      ></path>
      <path
        class="st1"
        d="M295.9 101.3c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C310.8 94.7 304.1 101.3 295.9 101.3z"
      ></path>
      <path
        class="st1"
        d="M300.3 125c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C315.3 118.3 308.6 125 300.3 125z"
      ></path>
      <path
        class="st1"
        d="M286.6 111.4c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C287.9 110.8 287.3 111.4 286.6 111.4z"
      ></path>
      <path
        class="st1"
        d="M239.2 89.7c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C240.5 89.1 239.9 89.7 239.2 89.7z"
      ></path>
      <path
        class="st1"
        d="M259.2 125c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C274.1 118.3 267.4 125 259.2 125z"
      ></path>
      <path
        class="st1"
        d="M136.8 167c-7.2 0-13-6-13-13.3V94c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H75.7c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C149.8 161 144 167 136.8 167z"
      ></path>
      <path
        class="st1"
        d="M378.2 165.3c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V77.8c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C391.2 159.3 385.4 165.3 378.2 165.3z"
      ></path>
      <path
        class="st1"
        d="M389.9 77.8c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C392.7 76.7 391.5 77.8 389.9 77.8z"
      ></path>
      <path
        class="st1"
        d="M389.9 20.9c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C405.2 27.8 398.3 20.9 389.9 20.9zM389.9 62.8c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C402.6 60.8 395.8 62.8 389.9 62.8z"
      ></path>
      <path
        class="st1"
        d="M389.9 67.3c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C400.9 65.8 395.2 67.3 389.9 67.3z"
      ></path>
      <path
        class="st1"
        d="M389.9 71.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C400.9 70.2 395.2 71.8 389.9 71.8z"
      ></path>
      <path
        class="st1"
        d="M389.9 76.2c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C400.9 74.6 395.2 76.2 389.9 76.2z"
      ></path>
      <path
        class="st1"
        d="M8.9 103.7c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H8.9zM32.4 140.4H8.9c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C40.4 136.8 36.8 140.4 32.4 140.4z"
      ></path>
      <path
        class="st1"
        d="M20.7 111.4c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C30.1 115.6 25.9 111.4 20.7 111.4zM20.7 132.6c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C32.6 127.3 27.2 132.6 20.7 132.6z"
      ></path>
      <path
        class="st1"
        d="M25.7 120.6c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C25.9 120.7 25.8 120.6 25.7 120.6zM25.7 123.4c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C28.4 122.2 27.2 123.4 25.7 123.4z"
      ></path>
      <path
        class="st1"
        d="M15.6 120.6c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C15.8 120.7 15.7 120.6 15.6 120.6zM15.6 123.4c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C18.3 122.2 17.1 123.4 15.6 123.4z"
      ></path>
      <path
        class="st1"
        d="M60.3 130.1h0.9v-18.7h-0.9V130.1zM62.5 132.6h-3.4c-0.7 0-1.2-0.6-1.2-1.2V110.2c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C63.8 132 63.2 132.6 62.5 132.6z"
      ></path>
      <path
        class="st1"
        d="M67.7 130h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C77 125.9 72.8 130 67.7 130z"
      ></path>
      <path
        class="st1"
        d="M59 128.1H48.7c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6H48.7c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C60.2 127.5 59.7 128.1 59 128.1z"
      ></path>
      <path
        class="st1"
        d="M420 36.6h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C421.2 36 420.7 36.6 420 36.6z"
      ></path>
      <path
        class="st1"
        d="M366.8 36.6h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C368 36 367.5 36.6 366.8 36.6z"
      ></path>
      <path
        class="st1"
        d="M389.9 13.6c-0.7 0-1.2-0.5-1.2-1.2V5.5c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C391.2 13 390.6 13.6 389.9 13.6z"
      ></path>
      <path
        class="st1"
        d="M368.7 57.7c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C369.3 57.5 369 57.7 368.7 57.7z"
      ></path>
      <path
        class="st1"
        d="M406.3 20.3c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C406.9 20.2 406.6 20.3 406.3 20.3z"
      ></path>
      <path
        class="st1"
        d="M373.6 20.3c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C374.2 20.2 373.9 20.3 373.6 20.3z"
      ></path>
      <path
        class="st1"
        d="M411.2 57.7c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C411.8 57.5 411.5 57.7 411.2 57.7z"
      ></path>
    </g>
    <g class="st20">
      <path
        class="st1"
        d="M177.3 73.7c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V60.6c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C180.8 74.2 179.1 73.7 177.3 73.7zM214.3 124.5h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C235.6 115.1 226.1 124.5 214.3 124.5z"
      ></path>
      <path
        class="st1"
        d="M220.6 75.6c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C221.9 75.1 221.3 75.6 220.6 75.6z"
      ></path>
      <path
        class="st1"
        d="M195.2 82.2c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C196.5 81.7 195.9 82.2 195.2 82.2z"
      ></path>
      <path
        class="st1"
        d="M177.5 100.9c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C192.5 94.2 185.8 100.9 177.5 100.9z"
      ></path>
      <path
        class="st1"
        d="M173.1 124.5c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C188 117.9 181.3 124.5 173.1 124.5z"
      ></path>
      <path
        class="st1"
        d="M214.2 110.9c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C215.5 110.4 214.9 110.9 214.2 110.9z"
      ></path>
      <path
        class="st1"
        d="M206.9 89.2c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C208.2 88.7 207.6 89.2 206.9 89.2z"
      ></path>
      <path
        class="st1"
        d="M252.3 48.4c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C264.6 53.9 259.1 48.4 252.3 48.4zM300.2 124.5h-41.5c-11.7 0-21.3-9.5-21.3-21.1V60.6c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C315 117.9 308.3 124.5 300.2 124.5z"
      ></path>
      <path
        class="st1"
        d="M252.4 75.6c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C267.2 69.1 260.7 75.6 252.4 75.6z"
      ></path>
      <path
        class="st1"
        d="M277.9 82.2c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C279.1 81.7 278.5 82.2 277.9 82.2z"
      ></path>
      <path
        class="st1"
        d="M295.5 100.9c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C310.5 94.2 303.8 100.9 295.5 100.9z"
      ></path>
      <path
        class="st1"
        d="M300 124.5c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C315 117.9 308.3 124.5 300 124.5z"
      ></path>
      <path
        class="st1"
        d="M286.3 110.9c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C287.5 110.4 287 110.9 286.3 110.9z"
      ></path>
      <path
        class="st1"
        d="M238.9 89.2c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C240.2 88.7 239.6 89.2 238.9 89.2z"
      ></path>
      <path
        class="st1"
        d="M258.9 124.5c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C273.8 117.9 267.1 124.5 258.9 124.5z"
      ></path>
      <path
        class="st1"
        d="M136.5 166.5c-7.2 0-13-6-13-13.3V93.5c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9V108c0 7.4-6 13.3-13.4 13.3H75.4c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9V93.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C149.5 160.5 143.7 166.5 136.5 166.5z"
      ></path>
      <path
        class="st1"
        d="M377.9 164.8c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V77.3c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C390.9 158.9 385 164.8 377.9 164.8z"
      ></path>
      <path
        class="st1"
        d="M389.6 77.3c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C392.4 76.3 391.2 77.3 389.6 77.3z"
      ></path>
      <path
        class="st1"
        d="M389.6 20.5c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C404.9 27.3 398 20.5 389.6 20.5zM389.6 62.3c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C402.3 60.4 395.5 62.3 389.6 62.3z"
      ></path>
      <path
        class="st1"
        d="M389.6 66.9c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C400.6 65.3 394.9 66.9 389.6 66.9z"
      ></path>
      <path
        class="st1"
        d="M389.6 71.3c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C400.6 69.7 394.9 71.3 389.6 71.3z"
      ></path>
      <path
        class="st1"
        d="M389.6 75.7c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2C400.6 74.2 394.9 75.7 389.6 75.7z"
      ></path>
      <path
        class="st1"
        d="M8.6 103.2c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H8.6zM32.1 139.9H8.6c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C40.1 136.3 36.5 139.9 32.1 139.9z"
      ></path>
      <path
        class="st1"
        d="M20.4 111c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C29.8 115.2 25.5 111 20.4 111zM20.4 132.1c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C32.3 126.8 26.9 132.1 20.4 132.1z"
      ></path>
      <path
        class="st1"
        d="M25.4 120.2c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C25.6 120.2 25.5 120.2 25.4 120.2zM25.4 123c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C28.1 121.8 26.9 123 25.4 123z"
      ></path>
      <path
        class="st1"
        d="M15.3 120.2c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C15.5 120.2 15.4 120.2 15.3 120.2zM15.3 123c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C18 121.8 16.8 123 15.3 123z"
      ></path>
      <path
        class="st1"
        d="M60 129.7h0.9v-18.7h-0.9V129.7zM62.2 132.1h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C63.4 131.6 62.9 132.1 62.2 132.1z"
      ></path>
      <path
        class="st1"
        d="M67.3 129.6h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C76.7 125.4 72.5 129.6 67.3 129.6z"
      ></path>
      <path
        class="st1"
        d="M58.7 127.6H48.4c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C59.9 127 59.4 127.6 58.7 127.6z"
      ></path>
      <path
        class="st1"
        d="M419.7 36.1h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C420.9 35.5 420.4 36.1 419.7 36.1z"
      ></path>
      <path
        class="st1"
        d="M366.5 36.1h-6.9c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2C367.7 35.5 367.1 36.1 366.5 36.1z"
      ></path>
      <path
        class="st1"
        d="M389.6 13.1c-0.7 0-1.2-0.5-1.2-1.2V5c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C390.9 12.6 390.3 13.1 389.6 13.1z"
      ></path>
      <path
        class="st1"
        d="M368.4 57.2c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C369 57.1 368.7 57.2 368.4 57.2z"
      ></path>
      <path
        class="st1"
        d="M406 19.8c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C406.6 19.7 406.3 19.8 406 19.8z"
      ></path>
      <path
        class="st1"
        d="M373.2 19.8c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C373.9 19.7 373.6 19.8 373.2 19.8z"
      ></path>
      <path
        class="st1"
        d="M410.9 57.2c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C411.5 57.1 411.2 57.2 410.9 57.2z"
      ></path>
    </g>
    <g class="st21">
      <path
        class="st1"
        d="M177 73.3c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.8-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V60.1c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C180.5 73.7 178.8 73.3 177 73.3zM214 124.1h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C235.3 114.6 225.8 124.1 214 124.1z"
      ></path>
      <path
        class="st1"
        d="M220.3 75.2c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C221.6 74.6 221 75.2 220.3 75.2z"
      ></path>
      <path
        class="st1"
        d="M194.9 81.8c-8.2 0-15-6.7-15-14.8 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C196.1 81.2 195.6 81.8 194.9 81.8z"
      ></path>
      <path
        class="st1"
        d="M177.2 100.4c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C192.2 93.8 185.4 100.4 177.2 100.4z"
      ></path>
      <path
        class="st1"
        d="M172.7 124.1c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C187.7 117.4 181 124.1 172.7 124.1z"
      ></path>
      <path
        class="st1"
        d="M213.9 110.5c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C215.1 109.9 214.6 110.5 213.9 110.5z"
      ></path>
      <path
        class="st1"
        d="M206.6 88.8c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C207.9 88.2 207.3 88.8 206.6 88.8z"
      ></path>
      <path
        class="st1"
        d="M251.9 47.9c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.1-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C264.3 53.4 258.8 47.9 251.9 47.9zM299.9 124.1h-41.5c-11.7 0-21.3-9.5-21.3-21.1V60.1c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C314.7 117.5 308 124.1 299.9 124.1z"
      ></path>
      <path
        class="st1"
        d="M252.1 75.2c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C266.8 68.6 260.4 75.2 252.1 75.2z"
      ></path>
      <path
        class="st1"
        d="M277.5 81.8c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C278.8 81.2 278.2 81.8 277.5 81.8z"
      ></path>
      <path
        class="st1"
        d="M295.2 100.4c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C310.2 93.8 303.5 100.4 295.2 100.4z"
      ></path>
      <path
        class="st1"
        d="M299.7 124.1c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C314.7 117.4 307.9 124.1 299.7 124.1z"
      ></path>
      <path
        class="st1"
        d="M286 110.5c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C287.2 109.9 286.7 110.5 286 110.5z"
      ></path>
      <path
        class="st1"
        d="M238.6 88.8c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C239.8 88.2 239.3 88.8 238.6 88.8z"
      ></path>
      <path
        class="st1"
        d="M258.5 124.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C273.5 117.4 266.8 124.1 258.5 124.1z"
      ></path>
      <path
        class="st1"
        d="M136.2 166.1c-7.2 0-13-6-13-13.3V93c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H75.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2H160.1c-6 0-10.9 4.9-10.9 10.9v17.7C149.2 160.1 143.3 166.1 136.2 166.1z"
      ></path>
      <path
        class="st1"
        d="M377.6 164.4c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 -0.2 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0.1-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V76.8c0-0.7 0.6-1.2 1.3-1.2s1.3 0.6 1.3 1.2v74.2C390.5 158.4 384.7 164.4 377.6 164.4z"
      ></path>
      <path
        class="st1"
        d="M389.3 76.9c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C392.1 75.8 390.9 76.9 389.3 76.9z"
      ></path>
      <path
        class="st1"
        d="M389.3 20c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C404.5 26.8 397.7 20 389.3 20zM389.3 61.8c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C402 59.9 395.2 61.8 389.3 61.8z"
      ></path>
      <path
        class="st1"
        d="M389.3 66.4c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C400.2 64.8 394.6 66.4 389.3 66.4z"
      ></path>
      <path
        class="st1"
        d="M389.3 70.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C400.2 69.3 394.6 70.8 389.3 70.8z"
      ></path>
      <path
        class="st1"
        d="M389.3 75.3c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2.1 2.7 2.7 1.5 0.6 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C400.2 73.7 394.6 75.3 389.3 75.3z"
      ></path>
      <path
        class="st1"
        d="M8.3 102.8c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H8.3zM31.8 139.4H8.3c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C39.8 135.9 36.2 139.4 31.8 139.4z"
      ></path>
      <path
        class="st1"
        d="M20 110.5c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C29.4 114.7 25.2 110.5 20 110.5zM20 131.7c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C31.9 126.4 26.6 131.7 20 131.7z"
      ></path>
      <path
        class="st1"
        d="M25.1 119.7c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C25.3 119.8 25.2 119.7 25.1 119.7zM25.1 122.5c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C27.8 121.3 26.6 122.5 25.1 122.5z"
      ></path>
      <path
        class="st1"
        d="M15 119.7c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C15.1 119.8 15.1 119.7 15 119.7zM15 122.5c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C17.6 121.3 16.4 122.5 15 122.5z"
      ></path>
      <path
        class="st1"
        d="M59.7 129.2h0.9v-18.7H59.7V129.2zM61.9 131.7h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C63.1 131.1 62.6 131.7 61.9 131.7z"
      ></path>
      <path
        class="st1"
        d="M67 129.1h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C76.4 125 72.2 129.1 67 129.1z"
      ></path>
      <path
        class="st1"
        d="M58.4 127.1H48.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C59.6 126.6 59.1 127.1 58.4 127.1z"
      ></path>
      <path
        class="st1"
        d="M419.4 35.6h-6.9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2S420 35.6 419.4 35.6z"
      ></path>
      <path
        class="st1"
        d="M366.1 35.6h-6.9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2S366.8 35.6 366.1 35.6z"
      ></path>
      <path
        class="st1"
        d="M389.3 12.7c-0.7 0-1.2-0.5-1.2-1.2V4.6c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C390.5 12.1 390 12.7 389.3 12.7z"
      ></path>
      <path
        class="st1"
        d="M368 56.7c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C368.7 56.6 368.4 56.7 368 56.7z"
      ></path>
      <path
        class="st1"
        d="M405.7 19.4c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C406.3 19.3 406 19.4 405.7 19.4z"
      ></path>
      <path
        class="st1"
        d="M372.9 19.4c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C373.6 19.3 373.2 19.4 372.9 19.4z"
      ></path>
      <path
        class="st1"
        d="M410.5 56.7c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C411.2 56.6 410.9 56.7 410.5 56.7z"
      ></path>
    </g>
    <path
      class="st1"
      d="M176.7 72.8c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.9-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V59.7c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C180.2 73.3 178.5 72.8 176.7 72.8zM213.7 123.6h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C235 114.1 225.4 123.6 213.7 123.6z"
    ></path>
    <path
      class="st1"
      d="M220 74.7c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C221.3 74.1 220.7 74.7 220 74.7z"
    ></path>
    <path
      class="st1"
      d="M194.6 81.3c-8.2 0-15-6.7-15-14.9 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C195.8 80.7 195.3 81.3 194.6 81.3z"
    ></path>
    <path
      class="st1"
      d="M176.9 100c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C191.8 93.3 185.1 100 176.9 100z"
    ></path>
    <path
      class="st1"
      d="M172.4 123.6c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C187.4 116.9 180.7 123.6 172.4 123.6z"
    ></path>
    <path
      class="st1"
      d="M213.6 110c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C214.8 109.4 214.3 110 213.6 110z"
    ></path>
    <path
      class="st1"
      d="M206.3 88.3c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C207.5 87.7 207 88.3 206.3 88.3z"
    ></path>
    <path
      class="st1"
      d="M251.6 47.5c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.2-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C264 52.9 258.4 47.5 251.6 47.5zM299.5 123.6h-41.5c-11.7 0-21.3-9.5-21.3-21.1V59.7c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C314.3 117 307.7 123.6 299.5 123.6z"
    ></path>
    <path
      class="st1"
      d="M251.8 74.7c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C266.5 68.2 260 74.7 251.8 74.7z"
    ></path>
    <path
      class="st1"
      d="M277.2 81.3c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C278.5 80.7 277.9 81.3 277.2 81.3z"
    ></path>
    <path
      class="st1"
      d="M294.9 100c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C309.9 93.3 303.2 100 294.9 100z"
    ></path>
    <path
      class="st1"
      d="M299.4 123.6c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C314.3 116.9 307.6 123.6 299.4 123.6z"
    ></path>
    <path
      class="st1"
      d="M285.7 110c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C286.9 109.4 286.3 110 285.7 110z"
    ></path>
    <path
      class="st1"
      d="M238.3 88.3c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C239.5 87.7 239 88.3 238.3 88.3z"
    ></path>
    <path
      class="st1"
      d="M258.2 123.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C273.2 116.9 266.5 123.6 258.2 123.6z"
    ></path>
    <path
      class="st1"
      d="M135.9 165.6c-7.2 0-13-6-13-13.3V92.6c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H74.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C148.9 159.6 143 165.6 135.9 165.6z"
    ></path>
    <path
      class="st1"
      d="M377.2 163.9c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 0 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V76.4c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C390.2 157.9 384.4 163.9 377.2 163.9z"
    ></path>
    <path
      class="st1"
      d="M389 76.4c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C391.8 75.4 390.6 76.4 389 76.4z"
    ></path>
    <path
      class="st1"
      d="M389 19.6c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C404.2 26.4 397.4 19.6 389 19.6zM389 61.4c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C401.8 59.5 394.9 61.4 389 61.4z"
    ></path>
    <path
      class="st1"
      d="M389 66c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C399.9 64.4 394.3 66 389 66z"
    ></path>
    <path
      class="st1"
      d="M389 70.4c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C399.9 68.8 394.3 70.4 389 70.4z"
    ></path>
    <path
      class="st1"
      d="M389 74.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C399.9 73.2 394.3 74.8 389 74.8z"
    ></path>
    <path
      class="st1"
      d="M8 102.3c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H8zM31.5 139H8c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C39.4 135.4 35.9 139 31.5 139z"
    ></path>
    <path
      class="st1"
      d="M19.7 110.1c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C29.1 114.3 24.9 110.1 19.7 110.1zM19.7 131.2c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C31.6 125.9 26.3 131.2 19.7 131.2z"
    ></path>
    <path
      class="st1"
      d="M24.8 119.2c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C25 119.3 24.9 119.2 24.8 119.2zM24.8 122c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C27.5 120.9 26.3 122 24.8 122z"
    ></path>
    <path
      class="st1"
      d="M14.7 119.2c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C14.8 119.3 14.7 119.2 14.7 119.2zM14.7 122c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C17.3 120.9 16.1 122 14.7 122z"
    ></path>
    <path
      class="st1"
      d="M59.4 128.7h0.9v-18.7h-0.9V128.7zM61.6 131.2h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C62.8 130.7 62.3 131.2 61.6 131.2z"
    ></path>
    <path
      class="st1"
      d="M66.7 128.7h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C76 124.5 71.8 128.7 66.7 128.7z"
    ></path>
    <path
      class="st1"
      d="M58 126.7H47.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C59.3 126.1 58.7 126.7 58 126.7z"
    ></path>
    <path
      class="st1"
      d="M419 35.2h-6.9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2S419.7 35.2 419 35.2z"
    ></path>
    <path
      class="st1"
      d="M365.8 35.2h-6.9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2S366.5 35.2 365.8 35.2z"
    ></path>
    <path
      class="st1"
      d="M389 12.2c-0.7 0-1.2-0.5-1.2-1.2V4.1c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C390.2 11.6 389.7 12.2 389 12.2z"
    ></path>
    <path
      class="st1"
      d="M367.7 56.3c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C368.4 56.2 368 56.3 367.7 56.3z"
    ></path>
    <path
      class="st1"
      d="M405.3 18.9c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C406 18.8 405.7 18.9 405.3 18.9z"
    ></path>
    <path
      class="st1"
      d="M372.6 18.9c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C373.2 18.8 372.9 18.9 372.6 18.9z"
    ></path>
    <path
      class="st1"
      d="M410.2 56.3c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C410.9 56.2 410.5 56.3 410.2 56.3z"
    ></path>
    <g class="st22">
      <path
        class="st23"
        d="M37.2 111.8c-0.3-0.7-0.6-1.3-1-2 -0.4-0.6-0.8-1.3-1.2-1.9l-4.8 0.9c-0.9-0.8-1.8-1.6-2.8-2.2l-0.4-4.9c-1.4-0.6-2.8-1-4.3-1.2l-2.8 4c-1.2 0-2.4 0.2-3.5 0.4l-3.7-3.2c-0.7 0.3-1.4 0.6-2 1 -0.7 0.4-1.3 0.8-1.9 1.2l0.9 4.8c-0.8 0.9-1.6 1.8-2.2 2.8l-4.9 0.4c-0.6 1.4-1 2.8-1.2 4.3l4 2.8c0 1.2 0.1 2.4 0.4 3.5l-3.2 3.7c0.3 0.7 0.6 1.3 1 2 0.4 0.7 0.8 1.3 1.2 1.9l4.9-0.8c0.9 0.9 1.8 1.6 2.8 2.2l0.4 4.9c1.4 0.6 2.8 1 4.3 1.2l2.8-4c1.2 0 2.4-0.1 3.5-0.4l3.7 3.2c0.7-0.3 1.4-0.6 2-1 0.7-0.4 1.3-0.8 1.9-1.2l-0.8-4.8c0.9-0.8 1.6-1.8 2.2-2.8l4.9-0.3c0.6-1.4 1-2.8 1.2-4.3l-4-2.8c0-1.2-0.1-2.3-0.4-3.5L37.2 111.8zM23.1 124.7c-3.1 1.8-7.1 0.7-8.9-2.4 -1.8-3.1-0.7-7.1 2.5-8.8 3.1-1.8 7.1-0.7 8.9 2.4C27.3 118.9 26.2 122.9 23.1 124.7z"
      ></path>
      <path
        class="st23"
        d="M22.2 117.7c0.7 1.3 0.3 3-1 3.7 -1.3 0.8-3 0.3-3.7-1 -0.7-1.3-0.3-3 1-3.7C19.8 115.9 21.5 116.4 22.2 117.7z"
      ></path>
      <path
        class="st23"
        d="M430.1 7c-0.2-0.4-0.4-0.8-0.6-1.2 -0.2-0.4-0.5-0.8-0.8-1.1l-3 0.5c-0.5-0.5-1.1-1-1.7-1.3l-0.2-3c-0.9-0.3-1.7-0.6-2.7-0.7l-1.7 2.5c-0.7 0-1.5 0.1-2.2 0.3l-2.3-2c-0.4 0.2-0.8 0.4-1.2 0.6 -0.4 0.2-0.8 0.5-1.2 0.8l0.5 3c-0.5 0.5-1 1.1-1.3 1.7l-3 0.2c-0.3 0.9-0.6 1.7-0.7 2.7l2.5 1.7c0 0.7 0.1 1.5 0.3 2.2l-2 2.3c0.2 0.4 0.4 0.8 0.6 1.2 0.2 0.4 0.5 0.8 0.8 1.2l3-0.5c0.5 0.5 1.1 1 1.7 1.4l0.2 3c0.9 0.4 1.8 0.6 2.7 0.7l1.7-2.5c0.7 0 1.5-0.1 2.2-0.3l2.3 2c0.4-0.2 0.8-0.4 1.2-0.6 0.4-0.2 0.8-0.5 1.2-0.7l-0.5-3c0.5-0.5 1-1.1 1.4-1.7l3-0.2c0.4-0.8 0.6-1.7 0.7-2.6l-2.5-1.7c0-0.7-0.1-1.5-0.3-2.2L430.1 7zM421.4 14.9c-1.9 1.1-4.4 0.4-5.5-1.5 -1.1-1.9-0.4-4.4 1.5-5.5 1.9-1.1 4.4-0.4 5.5 1.5C424 11.4 423.3 13.8 421.4 14.9z"
      ></path>
      <path
        class="st23"
        d="M420.8 10.6c0.5 0.8 0.2 1.8-0.6 2.3 -0.8 0.5-1.8 0.2-2.3-0.6 -0.5-0.8-0.2-1.8 0.6-2.3C419.3 9.5 420.4 9.8 420.8 10.6z"
      ></path>
      <path
        class="st23"
        d="M324.4 126.7c0.7-1 1.3-2 1.9-3.1 0.6-1.1 1.1-2.2 1.5-3.3l-5.4-5.8c0.4-1.9 0.6-3.8 0.5-5.7l6.4-4.7c-0.4-2.4-1.2-4.7-2.2-6.9l-7.9-0.3c-1-1.6-2.3-3.1-3.7-4.4l1.1-7.8c-1-0.7-2-1.3-3.1-1.9 -1.1-0.6-2.2-1-3.3-1.5l-5.8 5.4c-1.9-0.4-3.8-0.6-5.7-0.5l-4.8-6.3c-2.4 0.4-4.7 1.2-6.9 2.1l-0.3 7.9c-1.6 1-3.1 2.3-4.4 3.7l-7.9-1.1c-0.7 1-1.3 2-1.9 3.1 -0.6 1.1-1.1 2.2-1.5 3.3l5.4 5.8c-0.4 1.9-0.6 3.8-0.5 5.7l-6.4 4.7c0.4 2.4 1.2 4.7 2.2 6.9l8 0.3c1 1.6 2.3 3.1 3.7 4.4l-1.1 7.8c1 0.7 2 1.3 3.1 1.9 1.1 0.6 2.2 1.1 3.3 1.5l5.8-5.4c1.9 0.4 3.8 0.6 5.7 0.5l4.8 6.3c2.4-0.4 4.7-1.2 6.9-2.1l0.3-7.9c1.6-1 3.1-2.3 4.4-3.7L324.4 126.7zM294.5 118.9c-5.2-2.7-7.2-9-4.4-14.1 2.7-5.1 9.1-7.1 14.3-4.4 5.2 2.7 7.2 9 4.5 14.1C306 119.6 299.6 121.5 294.5 118.9z"
      ></path>
      <path
        class="st23"
        d="M303.3 111.6c-1.1 2.1-3.8 3-5.9 1.8 -2.2-1.1-3-3.8-1.9-5.9 1.1-2.1 3.8-3 6-1.8C303.6 106.8 304.4 109.5 303.3 111.6z"
      ></path>
      <path
        class="st23"
        d="M389.5 95.9c-0.2-1.1-0.5-2.3-0.9-3.4 -0.4-1.1-0.8-2.2-1.3-3.3l-7.7-0.2c-1-1.6-2.3-3-3.6-4.2l1-7.6c-2-1.3-4.1-2.4-6.3-3.2l-5.6 5.3c-1.8-0.4-3.7-0.5-5.6-0.4l-4.7-6.1c-1.1 0.2-2.3 0.5-3.4 0.9 -1.1 0.4-2.2 0.8-3.3 1.3l-0.2 7.7c-1.6 1-3 2.3-4.3 3.6l-7.7-1c-1.3 2-2.4 4.1-3.2 6.2l5.3 5.6c-0.4 1.8-0.5 3.7-0.4 5.6l-6.1 4.7c0.2 1.1 0.5 2.3 0.9 3.4 0.4 1.1 0.8 2.2 1.3 3.3l7.7 0.2c1.1 1.6 2.3 3 3.7 4.2l-1 7.6c2 1.3 4.1 2.4 6.3 3.2l5.6-5.3c1.8 0.4 3.7 0.5 5.6 0.4l4.7 6.1c1.2-0.2 2.3-0.5 3.4-0.9 1.1-0.4 2.2-0.8 3.3-1.3l0.2-7.7c1.6-1 3-2.3 4.3-3.6l7.7 1c1.3-1.9 2.4-4 3.2-6.2l-5.3-5.6c0.4-1.8 0.5-3.7 0.4-5.6L389.5 95.9zM376.1 94.2l-6.8 2.2c-2-3.4-5.8-5.3-9.8-4.9l-2.2-6.7C365 83.3 372.7 87.2 376.1 94.2zM361.8 105.6c-2.3 0.7-4.7-0.5-5.4-2.7 -0.7-2.2 0.5-4.6 2.8-5.4 2.3-0.7 4.7 0.5 5.4 2.7C365.3 102.5 364.1 104.9 361.8 105.6zM353 86.1l2.2 6.8c-3.4 2-5.3 5.8-5 9.7l-6.8 2.2C342 97.1 345.9 89.4 353 86.1zM344.9 109l6.8-2.2c2 3.4 5.9 5.3 9.8 4.9l2.2 6.8C356 119.9 348.2 116 344.9 109zM368 117.1l-2.2-6.7c3.4-2 5.4-5.8 5-9.7l6.8-2.2C379 106.1 375 113.8 368 117.1z"
      ></path>
      <path
        class="st23"
        d="M257.6 60.2c-0.8 3.1-4 4.9-7.1 4.1 -3.1-0.8-4.9-4-4.1-7.1 0.9-3.1 4.1-4.9 7.2-4C256.6 54 258.5 57.1 257.6 60.2z"
      ></path>
      <path
        class="st23"
        d="M148.4 94.1v-4.5h-11.8c-0.1-1.6-0.5-3.1-0.9-4.6l10.9-4.5 -1.7-4.1 -10.9 4.5c-0.7-1.4-1.6-2.6-2.6-3.8l8.4-8.3 -3.2-3.2 -8.3 8.3c-1.2-1-2.5-1.8-3.9-2.6l4.5-10.8 -4.2-1.7 -4.5 10.9c-1.5-0.4-3-0.7-4.5-0.9V57h-4.5v11.7c-1.6 0.2-3.1 0.5-4.6 0.9l-4.5-10.8 -4.2 1.7 4.5 10.8c-1.4 0.7-2.7 1.6-3.8 2.6l-8.4-8.3 -3.2 3.2 8.4 8.3c-1 1.2-1.9 2.5-2.6 3.9l-10.9-4.5 -1.7 4.1 10.9 4.5c-0.4 1.5-0.7 3-0.9 4.5H78.2v4.5h11.8c0.2 1.6 0.5 3.1 0.9 4.6l-10.9 4.5 1.7 4.1 10.9-4.5c0.7 1.4 1.6 2.6 2.6 3.8l-8.4 8.3 3.2 3.2 8.4-8.3c1.2 1 2.5 1.8 3.9 2.6l-4.5 10.8 4.2 1.7 4.5-10.8c1.5 0.4 3 0.8 4.6 0.9v11.7h4.5v-11.7c1.6-0.1 3.1-0.4 4.6-0.9l4.5 10.8 4.2-1.7 -4.5-10.8c1.4-0.7 2.7-1.6 3.9-2.6l8.4 8.3 3.2-3.2 -8.3-8.3c1-1.2 1.9-2.5 2.6-3.9l10.9 4.5 1.7-4.1 -10.9-4.5c0.5-1.4 0.8-3 0.9-4.5H148.4zM113.3 100.6c-4.8 0-8.8-3.9-8.8-8.7 0-4.8 3.9-8.7 8.8-8.7 4.9 0 8.8 3.9 8.8 8.7C122.1 96.7 118.2 100.6 113.3 100.6z"
      ></path>
      <path
        class="st23"
        d="M399.4 157.6l0.4-2.9 -7.6-1.1c0.1-1 0-2-0.2-3l7.5-1.9 -0.7-2.8 -7.5 1.9c-0.4-0.9-0.8-1.9-1.3-2.7l6.2-4.6 -1.8-2.3 -6.2 4.6c-0.7-0.7-1.5-1.4-2.3-2l3.9-6.6 -2.5-1.5 -3.9 6.6c-0.9-0.4-1.9-0.7-2.9-1l1.1-7.6 -2.9-0.4 -1.1 7.6c-1 0-2.1 0-3.1 0.2l-1.9-7.4 -2.9 0.7 1.9 7.4c-0.9 0.4-1.9 0.8-2.7 1.3l-4.7-6.1 -2.3 1.8 4.7 6.1c-0.7 0.7-1.4 1.5-2 2.3l-6.7-3.9 -1.5 2.5 6.7 3.9c-0.4 0.9-0.7 1.9-1 2.9l-7.7-1.1 -0.4 2.9 7.7 1.1c-0.1 1 0 2.1 0.2 3l-7.5 1.9 0.7 2.8 7.5-1.9c0.4 0.9 0.8 1.9 1.3 2.7l-6.2 4.6 1.8 2.3 6.2-4.6c0.7 0.7 1.5 1.4 2.3 2l-3.9 6.6 2.5 1.5 3.9-6.6c0.9 0.4 1.9 0.8 2.9 1l-1.1 7.6 2.9 0.4 1.1-7.6c1 0.1 2.1 0 3.1-0.2l2 7.4 2.9-0.7 -1.9-7.4c1-0.3 1.9-0.8 2.7-1.3l4.7 6.1 2.4-1.8 -4.6-6.1c0.8-0.7 1.4-1.4 2.1-2.3l6.7 3.9 1.5-2.5 -6.7-3.9c0.4-0.9 0.8-1.8 1-2.8L399.4 157.6zM376 158.6c-3.1-0.4-5.3-3.3-4.9-6.4 0.5-3.1 3.4-5.3 6.5-4.9 3.2 0.4 5.3 3.3 4.9 6.5C382.1 156.9 379.2 159.1 376 158.6z"
      ></path>
      <path
        class="st23"
        d="M176.3 83.8c-1-0.2-2-0.3-3.1-0.3 -1 0-2.1 0-3.1 0l-2.6 6.2c-1.6 0.4-3.2 0.9-4.6 1.6l-6-3.2c-1.7 1.2-3.2 2.6-4.6 4.1l2.6 6.2c-0.9 1.4-1.6 2.8-2.1 4.4l-6.5 1.9c-0.2 1-0.3 2-0.3 3.1 0 1 0 2.1 0 3.1l6.3 2.6c0.4 1.6 0.9 3.2 1.6 4.6l-3.2 5.9c1.2 1.7 2.6 3.2 4.1 4.6l6.3-2.6c1.4 0.9 2.9 1.6 4.4 2.1l1.9 6.5c1 0.2 2 0.3 3.1 0.3 1 0.1 2.1 0 3.1 0l2.6-6.2c1.6-0.4 3.2-0.9 4.6-1.6l6 3.2c1.7-1.2 3.2-2.6 4.6-4.1l-2.6-6.2c0.9-1.4 1.6-2.8 2.1-4.4l6.5-1.9c0.2-1 0.3-2 0.4-3 0.1-1 0-2.1 0-3.1l-6.2-2.6c-0.4-1.6-0.9-3.1-1.6-4.6l3.2-5.9c-1.2-1.7-2.6-3.2-4.1-4.5l-6.3 2.6c-1.4-0.9-2.9-1.6-4.4-2.1L176.3 83.8zM180.8 109.6c-0.3 4.9-4.5 8.7-9.4 8.5 -5-0.2-8.8-4.4-8.5-9.4 0.3-4.9 4.5-8.7 9.4-8.4C177.3 100.5 181.1 104.7 180.8 109.6z"
      ></path>
      <path
        class="st23"
        d="M172.1 105.4c2.1 0.1 3.7 1.9 3.6 3.9 -0.1 2.1-1.9 3.6-3.9 3.5 -2.1-0.1-3.7-1.9-3.6-3.9C168.2 106.9 170 105.3 172.1 105.4z"
      ></path>
      <path
        class="st23"
        d="M236.5 72.1l7.7-0.1c0.3-0.6 0.6-1.3 0.8-2 0.2-0.7 0.4-1.4 0.6-2.1l-6-4.7 1.1-3.1 -1.4-2.9 5.4-5.4c-0.5-1.3-1.2-2.6-1.9-3.9l-7.6 0.8 -1.4-2.9 -3.1-1.1 -0.1-7.6c-0.6-0.3-1.3-0.5-2-0.8 -0.7-0.2-1.4-0.4-2.1-0.6l-4.8 5.9 -3.1-1.1 -2.9 1.4 -5.5-5.3c-1.3 0.5-2.6 1.2-3.9 1.9l0.9 7.6 -2.9 1.4 -1.1 3.1 -7.6 0.1c-0.3 0.7-0.6 1.3-0.8 2 -0.2 0.7-0.4 1.4-0.6 2.1l6 4.8 -1.1 3.1 1.4 2.9 -5.4 5.4c0.5 1.3 1.2 2.6 1.9 3.9l7.6-0.8 1.4 2.9 3.1 1.1 0.1 7.6c0.7 0.3 1.3 0.6 2 0.8 0.7 0.2 1.4 0.4 2.1 0.6l4.8-5.9 3.1 1.1 3-1.4 5.5 5.3c1.4-0.5 2.7-1.1 3.9-1.9l-0.8-7.5 3-1.4L236.5 72.1zM228.8 69.3l-8.3 4 -8.7-3 -4-8.2 3-8.6 8.3-4 8.7 3 4 8.2L228.8 69.3z"
      ></path>
      <path
        class="st23"
        d="M225 63.1c-1 2.8-4.1 4.4-7 3.4 -2.9-1-4.4-4.1-3.4-6.9 1-2.8 4.1-4.3 7-3.4C224.5 57.2 226 60.3 225 63.1z"
      ></path>
      <path
        class="st23"
        d="M94.4 121.5c0.1-1.1 0.2-2.2 0.2-3.3 0-1.1-0.1-2.2-0.2-3.3l-6.8-2.4c-0.5-1.7-1.1-3.3-2-4.8l3.1-6.5c-1.4-1.7-2.9-3.3-4.6-4.6l-6.5 3.1c-1.5-0.8-3.1-1.5-4.8-2l-2.4-6.7c-1.1-0.1-2.2-0.2-3.3-0.2 -1.1 0-2.2 0.1-3.3 0.2l-2.4 6.8c-1.7 0.5-3.3 1.1-4.8 2L49.9 96.7c-1.7 1.4-3.3 2.9-4.6 4.6l3.1 6.5c-0.9 1.5-1.5 3.1-2 4.8l-6.8 2.4c-0.1 1.1-0.2 2.2-0.2 3.3 0 1.1 0.1 2.2 0.2 3.3l6.8 2.4c0.5 1.7 1.2 3.3 2 4.8l-3.1 6.5c1.4 1.7 2.9 3.3 4.7 4.6l6.5-3.1c1.5 0.9 3.1 1.5 4.8 2l2.4 6.8c1.1 0.1 2.2 0.2 3.3 0.2 1.1 0 2.2-0.1 3.3-0.2l2.4-6.8c1.7-0.5 3.3-1.1 4.8-2l6.5 3.1c1.7-1.3 3.3-2.9 4.6-4.6l-3.1-6.5c0.9-1.5 1.5-3.1 2-4.8L94.4 121.5zM83 116.2H76.3c-0.8-3.6-3.6-6.4-7.2-7.2v-6.6C76.3 103.3 82 109 83 116.2zM67 122.2c-2.2 0-4-1.8-4-4 0-2.2 1.8-4 4-4 2.2 0 4 1.8 4 4C71 120.5 69.2 122.2 67 122.2zM64.9 102.4v6.6c-3.6 0.8-6.4 3.6-7.2 7.2h-6.7C51.9 109 57.6 103.3 64.9 102.4zM51 120.3h6.7c0.8 3.6 3.6 6.4 7.2 7.2v6.6C57.6 133.2 51.9 127.5 51 120.3zM69.1 134.2v-6.6c3.6-0.8 6.4-3.6 7.2-7.2h6.7C82 127.5 76.3 133.2 69.1 134.2z"
      ></path>
      <path
        class="st23"
        d="M389 30.1c2.7 0.2 4.8 2.5 4.7 5.2 -0.1 2.7-2.5 4.8-5.2 4.7 -2.7-0.1-4.8-2.4-4.7-5.1C383.9 32 386.2 29.9 389 30.1z"
      ></path>
      <path
        class="st23"
        d="M386.2 7l-1.5 3.1 -2.4-2.5c-0.6 0.2-1.3 0.3-1.9 0.5l-0.8 3.4 -2.9-1.9c-0.6 0.3-1.2 0.6-1.8 0.9l-0.1 3.4 -3.2-1.3c-0.5 0.4-1 0.8-1.5 1.2l0.6 3.4 -3.4-0.6c-0.4 0.5-0.8 1-1.2 1.5l1.3 3.2 -3.5 0.1c-0.3 0.6-0.6 1.2-0.9 1.8l2 2.8 -3.4 0.8c-0.2 0.6-0.4 1.3-0.5 1.9l2.5 2.4 -3.1 1.5c0 0.3-0.1 0.7-0.1 1 0 0.3 0 0.7 0 1l2.9 1.8 -2.7 2.1c0.1 0.7 0.2 1.3 0.3 2l3.3 1.2 -2.2 2.6c0.2 0.6 0.5 1.2 0.7 1.8l3.4 0.5 -1.6 3c0.3 0.6 0.7 1.1 1.1 1.6l3.4-0.2 -1 3.3c0.5 0.5 0.9 0.9 1.4 1.4l3.3-1 -0.3 3.4c0.5 0.4 1.1 0.7 1.7 1.1l3-1.6 0.5 3.4c0.6 0.3 1.2 0.5 1.9 0.7l2.6-2.2 1.2 3.2c0.6 0.1 1.3 0.2 2 0.3l2.1-2.7 1.8 2.9c0.7 0 1.3 0 2-0.1l1.5-3.1 2.4 2.5c0.6-0.1 1.3-0.3 1.9-0.5l0.8-3.3 2.9 1.9c0.6-0.3 1.2-0.6 1.8-0.9l0.1-3.4 3.2 1.3c0.5-0.4 1-0.8 1.5-1.2l-0.6-3.4 3.4 0.6c0.4-0.5 0.9-1 1.3-1.5l-1.3-3.2 3.5-0.1c0.3-0.6 0.6-1.1 0.9-1.7l-1.9-2.8 3.4-0.8c0.2-0.6 0.4-1.3 0.5-1.9l-2.5-2.4 3.1-1.5c0-0.3 0.1-0.6 0.1-1 0-0.3 0-0.7 0-1L414 33.6l2.7-2.1c-0.1-0.6-0.2-1.3-0.3-1.9l-3.3-1.2 2.2-2.6c-0.2-0.6-0.4-1.2-0.7-1.8l-3.4-0.4 1.7-3c-0.3-0.6-0.7-1.1-1.1-1.6l-3.4 0.3 1-3.3c-0.4-0.5-0.9-0.9-1.4-1.4l-3.3 1 0.3-3.4c-0.5-0.4-1.1-0.7-1.7-1.1l-3 1.6 -0.5-3.4c-0.6-0.2-1.2-0.5-1.8-0.7l-2.6 2.2 -1.2-3.2c-0.6-0.1-1.3-0.2-2-0.3l-2.1 2.7 -1.8-2.9C387.5 6.9 386.9 7 386.2 7zM407.7 35.9c-0.5 10.4-9.5 18.4-19.9 17.9 -10.5-0.5-18.5-9.4-18-19.8 0.5-10.4 9.5-18.4 19.9-17.9C400.2 16.7 408.2 25.5 407.7 35.9z"
      ></path>
      <path
        class="st23"
        d="M164.4 42.1l-2.7-0.1 -0.4 7.1c-1 0-1.9 0.2-2.8 0.4l-2.4-6.7 -2.6 0.9 2.4 6.7c-0.8 0.4-1.7 0.9-2.4 1.4l-4.8-5.3 -2 1.8 4.8 5.3c-0.6 0.7-1.2 1.5-1.7 2.3l-6.5-3.1 -1.2 2.5 6.5 3.1c-0.3 0.9-0.5 1.8-0.7 2.7l-7.2-0.4 -0.1 2.7 7.2 0.4c0 1 0.2 1.9 0.4 2.8l-6.8 2.4 0.9 2.6 6.8-2.4c0.4 0.9 0.9 1.7 1.4 2.4l-5.3 4.8 1.8 2 5.3-4.8c0.7 0.6 1.5 1.2 2.3 1.7l-3.1 6.5 2.5 1.2 3.1-6.4c0.9 0.3 1.8 0.5 2.7 0.7l-0.4 7.1 2.7 0.1 0.4-7.1c1 0 1.9-0.2 2.8-0.4l2.4 6.7 2.6-0.9 -2.4-6.7c0.9-0.4 1.7-0.9 2.4-1.4l4.8 5.3 2-1.8 -4.8-5.3c0.6-0.7 1.2-1.5 1.7-2.3l6.5 3.1 1.2-2.4 -6.5-3.1c0.3-0.9 0.6-1.8 0.7-2.7l7.2 0.4 0.1-2.7 -7.2-0.4c0-1-0.2-1.9-0.4-2.8l6.8-2.4 -0.9-2.6 -6.8 2.4c-0.4-0.8-0.9-1.6-1.4-2.4l5.3-4.8 -1.8-2 -5.3 4.8c-0.7-0.6-1.5-1.2-2.3-1.7l3.1-6.4 -2.5-1.2 -3.1 6.4c-0.9-0.3-1.8-0.5-2.7-0.7L164.4 42.1zM167.2 63.4c-0.1 2.9-2.7 5.2-5.6 5 -2.9-0.1-5.2-2.6-5.1-5.6 0.2-2.9 2.7-5.2 5.6-5C165.1 58 167.4 60.5 167.2 63.4z"
      ></path>
    </g>
    <path
      class="st24"
      d="M213.7 123.6h-41.4c-8.2 0-14.8-6.6-14.8-14.7 0-5.8 3.4-11 8.6-13.3 -2.8-2.7-4.4-6.5-4.4-10.5 0-8.1 6.7-14.8 15-14.8 1.3 0 2.5 0.2 3.7 0.6 -0.4-1.4-0.6-2.8-0.6-4.2 0-8.1 6.6-14.7 14.8-14.7 4.3 0 8.2 1.8 11 5 1.3-6.8 7.4-11.9 14.6-11.9 8.2 0 14.8 6.6 14.8 14.7v42.8C235 114.1 225.4 123.6 213.7 123.6zM176.7 72.8c-6.8 0-12.5 5.6-12.5 12.3 0 3.9 1.9 7.6 5.1 9.9 0.4 0.3 0.6 0.8 0.5 1.2 -0.1 0.5-0.4 0.9-0.9 1 -5.2 1.5-8.9 6.3-8.9 11.7 0 6.7 5.5 12.2 12.3 12.2h41.5c10.3 0 18.8-8.4 18.8-18.6V59.7c0-6.7-5.5-12.2-12.3-12.2 -6.8 0-12.4 5.5-12.4 12.2 0 0.1 0 0.2 0 0.3 0 0.2 0 0.3 0 0.5 0 0.6-0.3 1.1-0.9 1.3 -0.6 0.2-1.2-0.1-1.4-0.6 -2.1-4.1-6.3-6.7-10.8-6.7 -6.8 0-12.3 5.5-12.3 12.2 0 2.1 0.5 4.1 1.5 5.9 0.3 0.5 0.2 1.1-0.2 1.5 -0.4 0.4-1 0.5-1.5 0.2C180.2 73.3 178.5 72.8 176.7 72.8z"
    ></path>
    <path
      class="st24"
      d="M220 74.7c-8.3 0-14.7-6.5-14.7-14.8 0-8.3 6.5-14.8 14.7-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.4 0 6.9 5.4 12.4 12.2 12.4 0.7 0 1.3 0.6 1.3 1.2C221.3 74.1 220.7 74.7 220 74.7z"
    ></path>
    <path
      class="st24"
      d="M194.6 81.3c-8.2 0-15-6.7-15-14.9 0-8.1 6.6-14.5 15-14.5 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -7 0-12.5 5.3-12.5 12 0 6.8 5.6 12.4 12.5 12.4 0.7 0 1.3 0.6 1.3 1.2C195.8 80.7 195.3 81.3 194.6 81.3z"
    ></path>
    <path
      class="st24"
      d="M176.9 100c-8.2 0-15-6.7-15-14.8 0-8.3 6.5-14.8 14.8-14.8 0.7 0 1.3 0.6 1.3 1.3 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.2 5.4-12.2 12.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.5 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C191.8 93.3 185.1 100 176.9 100z"
    ></path>
    <path
      class="st24"
      d="M172.4 123.6c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C187.4 116.9 180.7 123.6 172.4 123.6z"
    ></path>
    <path
      class="st24"
      d="M213.6 110c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -6.9 0-12.5 5.6-12.5 12.4 0 0.7-0.6 1.2-1.2 1.2 -0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 8.3 0 15 6.7 15 14.9C214.8 109.4 214.3 110 213.6 110z"
    ></path>
    <path
      class="st24"
      d="M206.3 88.3c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C207.5 87.7 207 88.3 206.3 88.3z"
    ></path>
    <path
      class="st24"
      d="M299.5 123.6h-41.5c-11.7 0-21.3-9.5-21.3-21.1V59.7c0-8.1 6.6-14.7 14.8-14.7 7.3 0 13.3 5.2 14.6 12 2.8-3.1 6.7-4.9 11-4.9 8.2 0 14.8 6.6 14.8 14.7 0 1.5-0.2 2.9-0.6 4.3 1.2-0.3 2.5-0.5 3.7-0.5 8.3 0 15 6.6 15 14.7 0 4-1.6 7.7-4.4 10.5 5.2 2.4 8.6 7.6 8.6 13.3C314.3 117 307.7 123.6 299.5 123.6zM251.6 47.5c-6.8 0-12.3 5.5-12.3 12.2v42.8c0 10.3 8.4 18.6 18.8 18.6h41.5c6.8 0 12.3-5.5 12.3-12.2 0-5.4-3.6-10.2-8.9-11.7 -0.5-0.1-0.8-0.5-0.9-1 -0.1-0.5 0.1-0.9 0.5-1.2 3.2-2.3 5.1-6 5.1-9.9 0-6.7-5.6-12.2-12.5-12.2 -1.9 0-3.7 0.4-5.5 1.4 -0.5 0.3-1.1 0.2-1.5-0.2 -0.4-0.4-0.5-1-0.2-1.5 1-1.8 1.5-3.8 1.5-5.9 0-6.7-5.5-12.2-12.3-12.2 -4.6 0-8.7 2.6-10.8 6.7 -0.3 0.5-0.9 0.8-1.4 0.6 -0.6-0.2-1-0.7-0.9-1.3 0-0.2 0-0.3 0-0.5 0-0.1 0-0.2 0-0.3C264 52.9 258.4 47.5 251.6 47.5z"
    ></path>
    <path
      class="st24"
      d="M251.8 74.7c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.2-5.4 12.2-12.4 0-6.9-5.4-12.4-12.2-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.7 6.5 14.7 14.9C266.5 68.2 260 74.7 251.8 74.7z"
    ></path>
    <path
      class="st24"
      d="M277.2 81.3c-7.8 0-14-6.3-14-14.5 0-8.2 6.3-14.8 14-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.3 0-11.5 5.6-11.5 12.4 0 6.7 5.1 12 11.5 12 0.7 0 1.3 0.6 1.3 1.2C278.5 80.7 277.9 81.3 277.2 81.3z"
    ></path>
    <path
      class="st24"
      d="M294.9 100c-0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.5 12.5-12.4 0-6.8-5.4-12.2-12.2-12.2 -0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 14.8 6.5 14.8 14.7C309.9 93.3 303.2 100 294.9 100z"
    ></path>
    <path
      class="st24"
      d="M299.4 123.6c-8.2 0-15-6.7-15-14.8 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3 0 6.8 5.6 12.4 12.5 12.4 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C314.3 116.9 307.6 123.6 299.4 123.6z"
    ></path>
    <path
      class="st24"
      d="M285.7 110c-0.7 0-1.2-0.6-1.2-1.2 0-6.8-5.6-12.4-12.5-12.4 -0.7 0-1.2-0.5-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 8.3 0 15 6.7 15 14.9C286.9 109.4 286.3 110 285.7 110z"
    ></path>
    <path
      class="st24"
      d="M238.3 88.3c-0.7 0-1.2-0.6-1.2-1.2 0-8.2 6.7-14.8 15-14.8 0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2 -6.9 0-12.5 5.6-12.5 12.4C239.5 87.7 239 88.3 238.3 88.3z"
    ></path>
    <path
      class="st24"
      d="M258.2 123.6c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2 6.9 0 12.5-5.6 12.5-12.4 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.3C273.2 116.9 266.5 123.6 258.2 123.6z"
    ></path>
    <path
      class="st24"
      d="M135.9 165.6c-7.2 0-13-6-13-13.3V92.6c0-6-4.6-10.9-10.1-10.9 -5.6 0-10.1 4.9-10.1 10.9v14.5c0 7.4-6 13.3-13.4 13.3H74.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h14.4c6 0 10.9-4.9 10.9-10.9v-14.5c0-7.4 5.7-13.3 12.7-13.3 7 0 12.7 6 12.7 13.3v59.7c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9v-17.7c0-7.4 6-13.3 13.4-13.3h43.7c0.7 0 1.3 0.6 1.3 1.2 0 0.7-0.6 1.2-1.2 1.2h-43.6c-6 0-10.9 4.9-10.9 10.9v17.7C148.9 159.6 143 165.6 135.9 165.6z"
    ></path>
    <path
      class="st24"
      d="M377.2 163.9c-7.2 0-13-6-13-13.3v-40.3c0-6-4.5-10.9-10.1-10.9 -2.7 0-5.2 1.1-7.1 3.2 -1.9 2.1-3 4.8-3 7.7 0 0 0 0.1 0 0.1 0 7.3-6.1 13.3-13.4 13.3h-45.2c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h45.2c6 0 10.9-4.9 10.9-10.9 0 0 0 0 0-0.1 0-3.5 1.3-6.8 3.7-9.3 2.4-2.5 5.6-3.9 9-3.9 7 0 12.7 6 12.7 13.3v40.3c0 6 4.7 10.9 10.5 10.9 5.8 0 10.5-4.9 10.5-10.9V76.4c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v74.2C390.2 157.9 384.4 163.9 377.2 163.9z"
    ></path>
    <path
      class="st24"
      d="M389 76.4c-1.6 0-2.8-1-2.8-2.4 0-0.7 0.6-1.3 1.3-1.3 0.7 0 1.2 0.5 1.3 1.1 0.2 0.1 0.4 0 0.5 0 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.7 1.3 1.4C391.8 75.4 390.6 76.4 389 76.4z"
    ></path>
    <path
      class="st24"
      d="M389 61.4c-5.9 0-12.9-1.9-12.9-7.3 0-0.6-1-3.7-1.5-5.2l-0.1-0.3c-1.7-5-3.3-10.2-3.3-13.8 0-9.8 8-17.7 17.7-17.7 9.8 0 17.8 7.9 17.8 17.7 0 3.8-1.9 9.5-3.3 13.7l-0.1 0.3c-0.5 1.5-1.5 4.7-1.6 5.4C401.8 59.5 394.9 61.4 389 61.4zM389 19.6c-8.4 0-15.2 6.8-15.2 15.2 0 3.6 2 9.5 3.2 13l0.1 0.3c1.1 3.5 1.6 5.2 1.6 5.9 0 3.8 6.5 4.8 10.4 4.8 3.9 0 10.4-1 10.4-4.8 0-0.8 0.5-2.6 1.7-6.1l0.1-0.3c1.3-4 3.1-9.5 3.1-12.9C404.2 26.4 397.4 19.6 389 19.6z"
    ></path>
    <path
      class="st24"
      d="M389 66c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C399.9 64.4 394.3 66 389 66z"
    ></path>
    <path
      class="st24"
      d="M389 70.4c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C399.9 68.8 394.3 70.4 389 70.4z"
    ></path>
    <path
      class="st24"
      d="M389 74.8c-2.5 0-4.9-0.3-6.7-1 -3.8-1.4-4.3-3.7-4.3-5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2 0 1.1 0.9 2 2.7 2.7 1.5 0.5 3.6 0.9 5.8 0.9 4.1 0 8.5-1.1 8.5-3.5 0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2C399.9 73.2 394.3 74.8 389 74.8z"
    ></path>
    <path
      class="st24"
      d="M31.5 139H8c-4.4 0-8-3.5-8-7.9v-23.3c0-4.4 3.6-7.9 8-7.9h23.5c4.4 0 8 3.5 8 7.9v23.3C39.4 135.4 35.9 139 31.5 139zM8 102.3c-3 0-5.5 2.4-5.5 5.4v23.3c0 3 2.5 5.4 5.5 5.4h23.5c3 0 5.5-2.4 5.5-5.4v-23.3c0-3-2.4-5.4-5.5-5.4H8z"
    ></path>
    <path
      class="st24"
      d="M19.7 131.2c-6.6 0-11.9-5.3-11.9-11.8 0-6.5 5.3-11.8 11.9-11.8 6.6 0 11.9 5.3 11.9 11.8C31.6 125.9 26.3 131.2 19.7 131.2zM19.7 110.1c-5.2 0-9.4 4.2-9.4 9.3 0 5.2 4.2 9.3 9.4 9.3 5.2 0 9.4-4.2 9.4-9.3C29.1 114.3 24.9 110.1 19.7 110.1z"
    ></path>
    <path
      class="st24"
      d="M24.8 122c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C27.5 120.9 26.3 122 24.8 122zM24.8 119.2c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C25 119.3 24.9 119.2 24.8 119.2z"
    ></path>
    <path
      class="st24"
      d="M14.7 122c-1.5 0-2.7-1.2-2.7-2.6 0-1.5 1.2-2.6 2.7-2.6 1.5 0 2.7 1.2 2.7 2.7C17.3 120.9 16.1 122 14.7 122zM14.7 119.2c-0.1 0-0.2 0.1-0.2 0.2 0 0.2 0.3 0.2 0.3 0C14.8 119.3 14.7 119.2 14.7 119.2z"
    ></path>
    <path
      class="st24"
      d="M61.6 131.2h-3.4c-0.7 0-1.2-0.6-1.2-1.2v-21.1c0-0.7 0.6-1.2 1.3-1.2h3.4c0.7 0 1.3 0.6 1.3 1.2v21.2C62.8 130.7 62.3 131.2 61.6 131.2zM59.4 128.7h0.9v-18.7h-0.9V128.7z"
    ></path>
    <path
      class="st24"
      d="M66.7 128.7h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c3.8 0 6.8-3 6.8-6.8 0-3.7-3.1-6.8-6.8-6.8h-5.1c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h5.1c5.2 0 9.3 4.2 9.3 9.3C76 124.5 71.8 128.7 66.7 128.7z"
    ></path>
    <path
      class="st24"
      d="M58 126.7H47.8c-0.7 0-1.2-0.6-1.2-1.2 0-0.7 0.6-1.2 1.3-1.2h9v-9.6h-9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h10.3c0.7 0 1.3 0.6 1.3 1.2v12.1C59.3 126.1 58.7 126.7 58 126.7z"
    ></path>
    <path
      class="st24"
      d="M419 35.2h-6.9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2S419.7 35.2 419 35.2z"
    ></path>
    <path
      class="st24"
      d="M365.8 35.2h-6.9c-0.7 0-1.2-0.6-1.2-1.2s0.6-1.2 1.3-1.2h6.9c0.7 0 1.3 0.6 1.3 1.2S366.5 35.2 365.8 35.2z"
    ></path>
    <path
      class="st24"
      d="M389 12.2c-0.7 0-1.2-0.5-1.2-1.2V4.1c0-0.7 0.6-1.2 1.3-1.2 0.7 0 1.3 0.6 1.3 1.2v6.9C390.2 11.6 389.7 12.2 389 12.2z"
    ></path>
    <path
      class="st24"
      d="M367.7 56.3c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.8l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C368.4 56.2 368 56.3 367.7 56.3z"
    ></path>
    <path
      class="st24"
      d="M405.3 18.9c-0.3 0-0.6-0.1-0.9-0.4 -0.5-0.5-0.5-1.3 0-1.7l4.9-4.8c0.5-0.5 1.3-0.5 1.8 0 0.5 0.5 0.5 1.3 0 1.8l-4.9 4.9C406 18.8 405.7 18.9 405.3 18.9z"
    ></path>
    <path
      class="st24"
      d="M372.6 18.9c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.8 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C373.2 18.8 372.9 18.9 372.6 18.9z"
    ></path>
    <path
      class="st24"
      d="M410.2 56.3c-0.3 0-0.6-0.1-0.9-0.4l-4.9-4.8c-0.5-0.5-0.5-1.3 0-1.7 0.5-0.5 1.3-0.5 1.8 0l4.9 4.9c0.5 0.5 0.5 1.3 0 1.8C410.9 56.2 410.5 56.3 410.2 56.3z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MindoSvg",
};
</script>

<style lang="scss">
@import "../../assets/styles/scss/mindo.scss";
</style>