<template>
  <section id="most-liked" class="tab-pane">
    <article
      v-for="(item, key) in getMostLiked"
      :key="item.SuggestionId"
      class="pt-3 px-3 pt-sm-3 px-sm-5"
      type="Suggestion"
      objectid="9547f6cd-1ec2-eb11-bacc-0022489cb344"
    >
      <div class="d-flex flex-nowrap">
        <img
          v-if="item.UserProfileImage"
          class="article-user-img fallback-image"
          :src="`data:image/png;base64, ${item.UserProfileImage}`"
          :alt="item.CreatedCustomerName | getInitialsFilter"
        />
        <i
          v-else
          class="article-user-img fallback-image flex-shrink-0"
          :alt="item.CreatedCustomerName | getInitialsFilter"
          >{{ item.CreatedCustomerName | getInitialsFilter }}</i
        >
        <div
          class="article-user-info flex-grow-1 pl-2 pl-sm-3 pt-sm-2 align-self-center align-self-sm-auto"
        >
          <span class="mb-1"
            >{{ item.CreatedCustomerName }} ( {{ item.CompanyName }} )</span
          >
          <span class="pt-0">{{ item.CreatedCustomerCompanyName }} şirketinden paylaşılan fikir</span>
        </div>
        <div class="article-brand ml-1 ml-sm-2 ml-md-3">
          <span class="smaller"><span>#</span>{{ key + 1 }}</span>
          <svg viewBox="0 0 44.49 61.72">
            <path
              class="cls-1"
              d="M40.26,35.39A15.68,15.68,0,1,0,55.94,51.06,15.69,15.69,0,0,0,40.26,35.39Z"
              transform="translate(-18.02 -28.82)"
            ></path>
            <polygon
              class="cls-1"
              points="14.29 22.5 16.16 22.5 16.41 20.46 14.55 20.46 14.29 22.5"
            ></polygon>
            <path
              class="cls-1"
              d="M61.28,52.3a3,3,0,0,1,0-2.47l1-2a1.8,1.8,0,0,0-.42-2.13l-1.76-1.49a3.06,3.06,0,0,1-.95-2.28l.2-2.3a1.83,1.83,0,0,0-1.21-1.8L56,37.08a3,3,0,0,1-1.75-1.75l-.7-2.19a1.85,1.85,0,0,0-1.68-1.22h-.13l-2.29.19h-.16a3.08,3.08,0,0,1-2.12-1l-1.49-1.76a1.82,1.82,0,0,0-1.37-.6,1.63,1.63,0,0,0-.76.17l-2,1.06a3.06,3.06,0,0,1-2.47,0L37,29a1.63,1.63,0,0,0-.76-.17,1.8,1.8,0,0,0-1.37.6l-1.49,1.76a3.08,3.08,0,0,1-2.12,1h-.16l-2.3-.19h-.12A1.85,1.85,0,0,0,27,33.14l-.7,2.19a3,3,0,0,1-1.75,1.75l-2.19.7a1.83,1.83,0,0,0-1.21,1.8l.19,2.3a3,3,0,0,1-.94,2.28l-1.76,1.49a1.82,1.82,0,0,0-.43,2.13l1.06,2a3,3,0,0,1,0,2.47l-1.06,2.05a1.82,1.82,0,0,0,.43,2.13L20.38,58a3,3,0,0,1,.94,2.29l-.19,2.29a1.85,1.85,0,0,0,1.21,1.81l2.19.7a3,3,0,0,1,1.75,1.74l.09.3L27,69a1.84,1.84,0,0,0,1.68,1.21h.13l2.29-.2h.16a3,3,0,0,1,2.12,1l1.49,1.76a1.8,1.8,0,0,0,1.37.6,1.64,1.64,0,0,0,.76-.18L39,72.07a3.06,3.06,0,0,1,2.47,0l2,1.06a1.64,1.64,0,0,0,.76.18,1.82,1.82,0,0,0,1.37-.6L47.17,71a3,3,0,0,1,2.12-1h.16l2.29.2h.13A1.84,1.84,0,0,0,53.55,69l.6-1.9.1-.3A3,3,0,0,1,56,65.05l2.2-.7a1.85,1.85,0,0,0,1.21-1.81l-.2-2.29A3,3,0,0,1,60.15,58l1.76-1.48a1.84,1.84,0,0,0,.42-2.13Zm-21,15.45A16.69,16.69,0,1,1,57,51.06,16.7,16.7,0,0,1,40.26,67.75Z"
              transform="translate(-18.02 -28.82)"
            ></path>
            <path
              class="cls-1"
              d="M54.75,68.56l-.24.74a2.81,2.81,0,0,1-2.64,1.91h-.21L49.36,71h-.07a2.11,2.11,0,0,0-1.35.6l-1.49,1.76a2.8,2.8,0,0,1-2.14,1A2.69,2.69,0,0,1,43.08,74L41,73a1.85,1.85,0,0,0-.77-.15,1.88,1.88,0,0,0-.77.15L37.44,74a2.61,2.61,0,0,1-1.22.29,2.8,2.8,0,0,1-2.14-1L32.59,71.6a2.11,2.11,0,0,0-1.35-.6h-.07l-2.3.19h-.21A2.84,2.84,0,0,1,26,69.3l-.23-.74L19.3,84.06c-.21.52.06.82.6.68l7.82-2.07a1.43,1.43,0,0,1,1.5.6L33.4,90.2c.29.47.7.44.9-.08l6-14.22,6,14.22c.21.52.61.55.9.08l4.18-6.93a1.43,1.43,0,0,1,1.5-.6l7.82,2.07c.54.14.81-.16.6-.68Z"
              transform="translate(-18.02 -28.82)"
            ></path>
          </svg>
        </div>
        <div
          class="article-further-menu dropdown ml-1 ml-sm-2 ml-md-3 ml-lg-4 show"
          v-if="user.Data.UserId === item.CreatedCustomerId"
        >
          <a
            class="btn btn-outline-light"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            @click.stop="cardDropdownFn(item.SuggestionId)"
          >
            <svg width="15px" height="3px">
              <path
                fill-rule="evenodd"
                opacity="0.902"
                fill="rgb(53, 59, 72)"
                d="M13.333,3.000 C12.413,3.000 11.666,2.328 11.666,1.500 C11.666,0.672 12.413,-0.000 13.333,-0.000 C14.254,-0.000 15.000,0.672 15.000,1.500 C15.000,2.328 14.254,3.000 13.333,3.000 ZM7.500,3.000 C6.579,3.000 5.833,2.328 5.833,1.500 C5.833,0.672 6.579,-0.000 7.500,-0.000 C8.420,-0.000 9.166,0.672 9.166,1.500 C9.166,2.328 8.420,3.000 7.500,3.000 ZM1.666,3.000 C0.746,3.000 -0.000,2.328 -0.000,1.500 C-0.000,0.672 0.746,-0.000 1.666,-0.000 C2.587,-0.000 3.333,0.672 3.333,1.500 C3.333,2.328 2.587,3.000 1.666,3.000 Z"
              ></path>
            </svg>
          </a>
          <div
            v-if="item.SuggestionId === cardDropdownId"
            ref="carDropdown"
            class="dropdown-menu dropdown-menu-right show"
            x-placement="bottom-end"
            style="
              position: absolute;
              transform: translate3d(-121px, 36px, 0px);
              top: 0px;
              left: 0px;
              will-change: transform;
              cursor: pointer;
            "
          >
            <a class="dropdown-item" @click="deleteCardShowModal(item.SuggestionId)"
              >Sil</a
            >
            <a class="dropdown-item" @click.stop="editSuggestion(item)">Düzenle</a>
          </div>
        </div>
      </div>

      <div class="article-img clearfix"></div>

      <p class="article-content">
        {{ item.SuggestionDetail }}
      </p>

      <div
        class="article-response d-flex justify-content-end align-items-center flex-wrap"
      >
        <span>{{ item.LikeCount }} kişi beğendi</span>
        <span>{{ item.CommentCount }} yorum</span>
      </div>

      <div class="article-actions py-1">
        <span class="icon-clock" :title="item.SuggestionDate" tooltip="">{{
          item.SuggestionDate | dateDiffFilter
        }}</span>
        <button
          @click="isCommentFn(key)"
          class="btn-article-action make-comment icon-bubbles btn-toggle"
          :aria-pressed="[key !== commentId ? false : true]"
        >
          <span class="d-none d-sm-inline">Yorum Yap</span>
        </button>
        <input
          :id="item.SuggestionId"
          type="checkbox"
          class="give-heart"
          :checked="item.IsLiked"
        />
        <label
          :for="item.SuggestionId"
          class="btn-article-action btn-article-action-label"
          @click="liked(item)"
          ><i class="fas fa-heart"></i><i class="far fa-heart"></i
          ><span class="d-none d-sm-inline">Beğen</span></label
        >
      </div>

      <transition name="slide-down">
        <div v-if="key === commentId" class="article-reply">
          <img
            v-if="user.Data.UserProfileImage"
            class="article-user-img fallback-image"
            :src="`data:image/png;base64, ${user.Data.UserProfileImage}`"
            :alt="user.Data.FullName | getInitialsFilter"
          />
          <i
            v-else
            class="comment-user-img fallback-image"
            :alt="user.Data.FullName | getInitialsFilter"
            >{{ user.Data.FullName | getInitialsFilter }}</i
          >
          <div class="icon-note">
            <textarea
              v-model="commentText"
              class="autofit"
              placeholder="Yorum Giriniz..."
            ></textarea>
          </div>
          <div v-if="commentText && commentText.length > 0" class="text-right pt-3">
            <button class="btn-reply btn-cancel" @click="commentText = ''">VAZGEÇ</button>
            <button class="btn-reply add-comment-button" @click="createComment(item)">
              YANITLA
            </button>
          </div>
        </div>
      </transition>

      <a
        v-if="item.CommentCount != 0"
        class="article-comment-show icon-down"
        @click="commentShowFn(item.SuggestionId)"
        >{{ item.CommentCount }} Yorum</a
      >
      <transition name="slide-down">
        <div
          v-if="isCommentShowId == item.SuggestionId"
          class="article-comment-container"
          style=""
        >
          <div id="commentdiv" v-for="comment in item.Comments" :key="comment.Id">
            <div
              v-show="comment.UserId == user.Data.UserId"
              class="article-further-menu dropdown show"
              style="right: 10px; margin-top: 10px; position: absolute"
            >
              <a
                class="btn btn-outline-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click.stop="commentEditFn(comment.Id)"
              >
                <svg width="15px" height="3px">
                  <path
                    fill-rule="evenodd"
                    opacity="0.902"
                    fill="rgb(53, 59, 72)"
                    d="M13.333,3.000 C12.413,3.000 11.666,2.328 11.666,1.500 C11.666,0.672 12.413,-0.000 13.333,-0.000 C14.254,-0.000 15.000,0.672 15.000,1.500 C15.000,2.328 14.254,3.000 13.333,3.000 ZM7.500,3.000 C6.579,3.000 5.833,2.328 5.833,1.500 C5.833,0.672 6.579,-0.000 7.500,-0.000 C8.420,-0.000 9.166,0.672 9.166,1.500 C9.166,2.328 8.420,3.000 7.500,3.000 ZM1.666,3.000 C0.746,3.000 -0.000,2.328 -0.000,1.500 C-0.000,0.672 0.746,-0.000 1.666,-0.000 C2.587,-0.000 3.333,0.672 3.333,1.500 C3.333,2.328 2.587,3.000 1.666,3.000 Z"
                  ></path>
                </svg>
              </a>
              <div
                ref="commentEditPoppup"
                class="dropdown-menu dropdown-menu-right"
                :style="[
                  commnetEditId == comment.Id
                    ? { display: 'block', cursor: 'pointer' }
                    : '',
                ]"
              >
                <a class="dropdown-item" @click="deleteComment(comment)">Sil</a>
                <a class="dropdown-item" @click.stop="commentEditShowModal(comment)"
                  >Düzenle</a
                >
              </div>
            </div>
            <div class="article-comment" id="article-comment-id" style="position: static">
              <div class="article-comment-body">
                <img
                  v-if="comment.UserProfileImage"
                  class="article-user-img fallback-image"
                  :src="`data:image/png;base64, ${comment.UserProfileImage}`"
                  :alt="comment.UserName | getInitialsFilter"
                />
                <i
                  v-else
                  class="comment-user-img fallback-image"
                  :alt="comment.UserName | getInitialsFilter"
                  >{{ comment.UserName | getInitialsFilter }}</i
                >
                <span>{{ comment.UserName }} ({{ comment.UserCompanyName }})</span
                ><span class="icon-clock" :title="comment.CreatedOnUtc">
                  {{ comment.CreatedOnUtc | dateDiffFilter }}</span
                >
                <p
                  style="
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  "
                >
                  {{ comment.CommentText }}
                </p>
              </div>
              <div class="article-comment-container" style="display: none"></div>
            </div>
          </div>
        </div>
      </transition>
    </article>
    <transition name="fade">
      <CommentEdit
        v-if="isCommentEdit"
        @close="isCommentEdit = $event"
        @edit-comment="editComment"
        :commentEditData="commentEditData"
      />
    </transition>
    <transition name="fade">
      <CardDelete
        v-if="isCardDelete"
        @close="isCardDelete = $event"
        @delete="deleteCard"
      />
    </transition>
    <transition name="fade">
      <CardEdit
        v-if="isEditCard"
        @close="isEditCard = $event"
        :editCardData="editCardData"
        :questionId="questionId"
      />
    </transition>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  suggestionStatus,
  getInitials,
  dateDiff,
  fileTypeCheck,
} from "../../../../helpers";
import { suggestionService, upload } from "../../../../services";
import CommentEdit from "../../Cards/Modals/CommentEdit.vue";
import CardDelete from "../../Cards/Modals/CardDelete.vue";
import CardEdit from "../Modals/CardEdit.vue";
export default {
  name: "MindoLiked",
  data() {
    return {
      commentShowId: null,
      commnetEditId: null,
      commentId: null,
      pageIndex: 0,
      commentText: "",
      isCommentShowId: null,
      commentEditData: null,
      isCommentEdit: false,
      cardDropdownId: null,
      isCardDelete: false,
      cardDeleteDataId: null,
      editCardData: null,
      isEditCard: false,
    };
  },
  props: ["getMostLiked", "questionId"],
  components: {
    CommentEdit,
    CardDelete,
    CardEdit,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  filters: {
    suggestionStatusFilter(value) {
      return suggestionStatus(value);
    },
    getInitialsFilter(value) {
      return getInitials(value);
    },
    dateDiffFilter(value) {
      return dateDiff(new Date(value));
    },
  },
  beforeMount() {
    document.addEventListener("click", this.clickOutSide);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSide);
  },
  methods: {
    clickOutSide(e) {
      let carDropdown = this.$refs.carDropdown ? this.$refs.carDropdown[0] : null;
      let target = e.target;
      if (carDropdown && carDropdown !== target && !carDropdown.contains(target)) {
        this.cardDropdownId = null;
      }
    },
    cardDropdownFn(item) {
      this.cardDropdownId = this.cardDropdownId === item ? null : item;
    },
    commentShowFn(item) {
      this.isCommentShowId = this.isCommentShowId === item ? null : item;
    },
    commentEditFn(item) {
      this.commnetEditId = this.commnetEditId === item ? null : item;
    },
    isCommentShowFn(item) {
      this.commentShowId = this.commentShowId === item ? null : item;
    },
    isCommentFn(item) {
      this.commentId = this.commentId === item ? null : item;
    },
    getMostLikedData() {
      const tmpData = {
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setMostLikedMindos", tmpData);
    },
    getMostCommentedData() {
      const tmpData = {
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setMostCommentedMindos", tmpData);
    },
    getSuggestionData() {
      const tmpData = {
        pageIndex: this.pageIndex,
        pageSize: 5,
        mindoQuestionId: parseInt(this.questionId),
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setSuggestionsData", tmpData);
    },
    async liked(item) {
      if (item.IsLiked) {
        //Delete Liked
        let id = null;
        item.Likes.forEach((element) => {
          if (element.UserId == this.user.Data.UserId) {
            id = element.Id;
          }
        });
        const body = {
          Id: id,
          SuggestionId: item.SuggestionId,
          UserId: this.user.Data.UserId,
        };
        const res = await suggestionService.fetchDeleteLike(body);
        if (res.Message == "like is deleted") {
          this.$store.dispatch("mindo/deleteSuggestionData");
          this.$store.dispatch("mindo/deleteMostLikedMindos");
          this.getMostLikedData();
          this.getSuggestionData();
        }
      } else {
        //Create Liked
        const body = {
          UserId: this.user.Data.UserId,
          SuggestionId: item.SuggestionId,
        };
        const res = await suggestionService.fetchCreateLike(body);

        if (res.Data) {
          this.$store.dispatch("mindo/deleteSuggestionData");
          this.$store.dispatch("mindo/deleteMostLikedMindos");
          this.getMostLikedData();
          this.getSuggestionData();
        }
      }
    },
    async createComment(item) {
      const body = {
        CommentText: this.commentText,
        SuggestionId: item.SuggestionId,
        UserId: this.user.Data.UserId,
      };
      try {
        const res = await suggestionService.fetchCreateComment(body);
        if (res.Data) {
          this.commentText = null;
          this.isCommentShowId = null;
          this.commentId = null;
          this.$toast.success("Yorumunuz başarıyla yapıldı.", {
            position: "top",
          });
          this.$store.dispatch("mindo/deleteSuggestionData");
          this.$store.dispatch("mindo/deleteMostLikedMindos");
          this.$store.dispatch("mindo/deleteMostCommentedMindos");
          this.getMostLikedData();
          this.getSuggestionData();
          this.getMostCommentedData();
        }
      } catch (error) {
        this.$toast.error("Yorumunuz gönderilirken bir hata oluştu.", {
          position: "top",
        });
      }
    },
    async deleteComment(item) {
      const body = {
        Id: item.Id,
        SuggestionId: item.SuggestionId,
        UserId: item.UserId,
      };
      try {
        const res = await suggestionService.fetchDeleteComment(body);
        if (res.Message == "comment is deleted") {
          this.$toast.success("Yorumunuz başarıyla silindi.", {
            position: "top",
          });
          this.$store.dispatch("mindo/deleteSuggestionData");
          this.$store.dispatch("mindo/deleteMostLikedMindos");
          this.$store.dispatch("mindo/deleteMostCommentedMindos");
          this.getMostLikedData();
          this.getSuggestionData();
          this.getMostCommentedData();
        }
      } catch (error) {
        this.$toast.error("Yorumunuz silinirken bir hata oluştu.", {
          position: "top",
        });
      }
    },
    commentEditShowModal(item) {
      this.commentEditData = item;
      this.isCommentEdit = true;
      this.commnetEditId = null;
    },
    async editComment(event) {
      const body = {
        Id: this.commentEditData.Id,
        CommentText: event,
        SuggestionId: this.commentEditData.SuggestionId,
        UserId: this.commentEditData.UserId,
      };
      try {
        const res = await suggestionService.fetchUpdateComment(body);
        if (res.Data) {
          this.$toast.success("Yorumunuz başarıyla güncellendi.", {
            position: "top",
          });
          this.$store.dispatch("mindo/deleteSuggestionData");
          this.$store.dispatch("mindo/deleteMostLikedMindos");
          this.$store.dispatch("mindo/deleteMostCommentedMindos");
          this.getMostLikedData();
          this.getSuggestionData();
          this.getMostCommentedData();
          this.isCommentEdit = false;
        }
      } catch (error) {
        this.$toast.error("Yorumunuz güncellenirken bir hata oluştu.", {
          position: "top",
        });
      }
    },
    deleteCardShowModal(item) {
      this.isCardDelete = true;
      this.cardDeleteDataId = item;
      this.cardDropdownId = null;
    },
    async deleteCard() {
      try {
        const res = await suggestionService.fetchDeleteSuggestion(this.cardDeleteDataId);
        if (res.Message == "Suggestion is deleted") {
          this.$toast.success("İşleminiz başarıyla tamamlandı.", {
            position: "top",
          });
          this.$store.dispatch("mindo/deleteSuggestionData");
          this.$store.dispatch("mindo/deleteMostLikedMindos");
          this.getMostLikedData();
          this.getSuggestionData();
        } else {
          this.$toast.error("İşleminiz sırasında bir hata oluştu.", {
            position: "top",
          });
        }
      } catch (error) {
        this.$toast.error("İşleminiz sırasında bir hata oluştu.", {
          position: "top",
        });
      }
    },
    async editSuggestion(item) {
      this.$store.dispatch("loading/setIsLoading", true);
      const tmpData = item;
      if (item.FileId != null) {
        const res = await upload.fetchDownloadFile(item.FileId);
        tmpData.DownloadFile = {
          DownloadBinary: res.Data.DownloadBinary,
          Extension: res.Data.Extension,
          Filename: res.Data.Filename,
          Id: res.Data.Id,
          fileType: fileTypeCheck(res.Data.Extension),
        };
      }
      this.editCardData = tmpData;
      this.isEditCard = true;
      this.cardDropdownId = null;
      this.$store.dispatch("loading/setIsLoading", false);
    },
  },
};
</script>

<style></style>
