var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade show custom-modal",staticStyle:{"display":"block","background-color":"rgba(0, 0, 0, 0.5)"},attrs:{"id":"modal-share-arge","tabindex":"-1","role":"dialog"},on:{"click":_vm.close}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg",attrs:{"role":"document"}},[_c('div',{ref:"modalContent",staticClass:"modal-content",staticStyle:{"height":"unset"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"modal-header bg-orange rounded-0"},[_c('h5',{staticClass:"modal-title text-center text-white mx-auto"},[_vm._v("FİKİR DETAYI")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.close}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"container mb-3 mw-100"},[_c('div',{staticClass:"mindo-form-body",attrs:{"id":"mindo-edit-form"}},[_c('input',{attrs:{"name":"__RequestVerificationToken","type":"hidden","value":"CGe3ma-Lb2clQSSz8EQFAkgkC2QNwTf9AO1Qnc-WFHbhLNbb-TodIFpQepN_rBKsru4IEB-FrVIyqWDZCRRCxazHbmkYoQzVnaDojd2Sn_g0DBWSV6qJ_lJ7B7BtqE9VBVtq6Q5LPp6goU5tQ5BbMg2"}}),_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.suggestionDetail),expression:"suggestionDetail"}],staticClass:"form-control long",attrs:{"min-height":"200","id":"message-text-mindo-edit","placeholder":"Önerin..."},domProps:{"value":(_vm.suggestionDetail)},on:{"input":function($event){if($event.target.composing)return;_vm.suggestionDetail=$event.target.value}}})]),_c('div',{staticClass:"form-group invalid-feedback",style:([
              _vm.requiredField.includes('suggestionDetail') ? { display: 'block' } : '',
            ]),attrs:{"id":"txtError"}},[_vm._v(" Bu alan boş geçilemez! ")]),_c('label',{staticClass:"col-form-label",attrs:{"for":"drpCustomer"}},[_vm._v("Hangi şirketimiz için öneride bulunuyorsunuz?")]),_c('div',{staticClass:"form-group icon-note icon-down"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCompany),expression:"selectedCompany"}],staticClass:"form-control required",attrs:{"data-val":"true","data-val-required":"The Id field is required.","id":"drpCustomer"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCompany=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.companyList),function(item,key){return _c('option',{key:key,domProps:{"value":item.Id}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),0)]),_c('div',{staticClass:"form-group invalid-feedback",style:([
              _vm.requiredField.includes('selectedCompany') ? { display: 'block' } : '',
            ]),attrs:{"id":"drpError"}},[_vm._v(" Bu alan boş geçilemez! ")]),_c('div',{staticClass:"form-group custom-file icon-folder-alt"},[_c('input',{ref:"fileUploadInput",staticClass:"custom-file-input",attrs:{"type":"file","id":"customFileArge","name":"customFile","accept":_vm.supportedFileType},on:{"change":_vm.fileUploadChange}}),_c('label',{staticClass:"custom-file-label hide-overflow-text selected",attrs:{"for":"customFile","id":"lblDocument"}},[_vm._v(_vm._s(_vm.fileUploadData.length > 0 ? _vm.fileUploadData[0].name : "Doküman Yükle +"))]),_c('small',{staticClass:"Raleway-Medium",style:([_vm.isFileMB ? { color: 'red' } : ''])},[_vm._v("*Maximum 40 MB büyüklüğündeki dosyalar eklenebilir.")]),_c('br'),_c('small',{staticClass:"Raleway-Medium"},[_vm._v("**Birden fazla doküman yüklemek için sıkıştırılmış dosya (.zip vb.) kullanabilirsiniz.")])]),_c('div',{staticClass:"form-group custom-file icon-folder-alt"},[_c('p',{staticClass:"form-control d-flex align-items-center cursor-default"},[_c('span',{staticClass:"flex-shrink-1 mr-1 hide-overflow-text cursor-default"},[_vm._v(_vm._s(_vm.downloadFile.Filename)+"."+_vm._s(_vm.downloadFile.Extension))]),_c('a',{staticClass:"btn btn-light text-blue-1 ml-auto fas fa-file-download",attrs:{"href":'data:' +
                  _vm.downloadFile.fileType +
                  ';base64,' +
                  _vm.downloadFile.DownloadBinary,"download":_vm.downloadFile.Filename + '.' + _vm.downloadFile.Extension,"title":"Dokümanı bilgisayarınıza indirmek için tıklayın.","alt":"Dokümanı bilgisayarınıza indirmek için tıklayın.","target":"_blank"}}),_c('a',{staticClass:"btn btn-light text-danger ml-3 fas fa-trash-alt",attrs:{"href":"javascript:void(0)","id":"document-remove","title":"Dokümanı silmek için tıklayın.","alt":"Dokümanı silmek için tıklayın."},on:{"click":_vm.deleteDocument}})])]),_c('button',{staticClass:"mt-4 btn-innovation-modal bg-orange mx-auto d-block float-sm-right",attrs:{"id":"btnSubmit","disabled":_vm.isBtnDisabled,"type":"button"},on:{"click":_vm.editMindo}},[_vm._v(" Düzenle "),_c('span',{staticClass:"icon-arrow-right pl-2"})]),_c('div',{staticClass:"clearfix"})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }