vue
<template>
  <div
    id="modal-share-arge"
    class="modal fade show custom-modal"
    tabindex="-1"
    role="dialog"
    style="display: block; background-color: rgba(0, 0, 0, 0.5)"
    @click="close"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" ref="modalContent" @click.stop style="height: unset">
        <div class="modal-header bg-orange rounded-0">
          <h5 class="modal-title text-center text-white mx-auto">FİKİR DETAYI</h5>
          <button
            @click="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="container mb-3 mw-100">
          <div class="mindo-form-body" id="mindo-edit-form">
            <input
              name="__RequestVerificationToken"
              type="hidden"
              value="CGe3ma-Lb2clQSSz8EQFAkgkC2QNwTf9AO1Qnc-WFHbhLNbb-TodIFpQepN_rBKsru4IEB-FrVIyqWDZCRRCxazHbmkYoQzVnaDojd2Sn_g0DBWSV6qJ_lJ7B7BtqE9VBVtq6Q5LPp6goU5tQ5BbMg2"
            />
            <div class="form-group">
              <textarea
                class="form-control long"
                min-height="200"
                id="message-text-mindo-edit"
                placeholder="Önerin..."
                v-model="suggestionDetail"
              ></textarea>
            </div>
            <div
              id="txtError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('suggestionDetail') ? { display: 'block' } : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <label class="col-form-label" for="drpCustomer"
              >Hangi şirketimiz için öneride bulunuyorsunuz?</label
            >
            <div class="form-group icon-note icon-down">
              <select
                class="form-control required"
                data-val="true"
                data-val-required="The Id field is required."
                id="drpCustomer"
                v-model="selectedCompany"
              >
                <option v-for="(item, key) in companyList" :key="key" :value="item.Id">
                  {{ item.Name }}
                </option>
              </select>
            </div>
            <div
              id="drpError"
              class="form-group invalid-feedback"
              :style="[
                requiredField.includes('selectedCompany') ? { display: 'block' } : '',
              ]"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group custom-file icon-folder-alt">
              <input
                type="file"
                class="custom-file-input"
                id="customFileArge"
                name="customFile"
                ref="fileUploadInput"
                :accept="supportedFileType"
                @change="fileUploadChange"
              />
              <label
                class="custom-file-label hide-overflow-text selected"
                for="customFile"
                id="lblDocument"
                >{{
                  fileUploadData.length > 0 ? fileUploadData[0].name : "Doküman Yükle +"
                }}</label
              >
              <small class="Raleway-Medium" :style="[isFileMB ? { color: 'red' } : '']"
                >*Maximum 40 MB büyüklüğündeki dosyalar eklenebilir.</small
              >
              <br />
              <small class="Raleway-Medium"
                >**Birden fazla doküman yüklemek için sıkıştırılmış dosya (.zip vb.)
                kullanabilirsiniz.</small
              >
            </div>
            <div class="form-group custom-file icon-folder-alt">
              <p class="form-control d-flex align-items-center cursor-default">
                <span class="flex-shrink-1 mr-1 hide-overflow-text cursor-default"
                  >{{ downloadFile.Filename }}.{{ downloadFile.Extension }}</span
                >
                <a
                  :href="
                    'data:' +
                    downloadFile.fileType +
                    ';base64,' +
                    downloadFile.DownloadBinary
                  "
                  :download="downloadFile.Filename + '.' + downloadFile.Extension"
                  class="btn btn-light text-blue-1 ml-auto fas fa-file-download"
                  title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                  alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                  target="_blank"
                >
                </a>
                <a
                  href="javascript:void(0)"
                  class="btn btn-light text-danger ml-3 fas fa-trash-alt"
                  id="document-remove"
                  title="Dokümanı silmek için tıklayın."
                  alt="Dokümanı silmek için tıklayın."
                  @click="deleteDocument"
                ></a>
              </p>
            </div>

            <button
              id="btnSubmit"
              :disabled="isBtnDisabled"
              @click="editMindo"
              type="button"
              class="mt-4 btn-innovation-modal bg-orange mx-auto d-block float-sm-right"
            >
              Düzenle <span class="icon-arrow-right pl-2"></span>
            </button>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mindoService, upload } from "../../../../services";
import { bytesToMegaBytes, toBase64, fileTypeCheck, isNil } from "../../../../helpers";
export default {
  name: "CardEdit",
  props: ["editCardData", "questionId"],
  data() {
    return {
      selectedCompany: "",
      suggestionDetail: null,
      companyList: [],
      fileId: null,
      isFileMB: false,
      isBtnDisabled: false,
      requiredField: [],
      fileUploadData: [],
      downloadFile: null,
      supportedFileType:
        "image/jpeg,image/jpg,image/png,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-zip-compressed,application/zip,application/pdf",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    getCompanies() {
      return this.$store.getters["suggestion/companiesData"];
    },
  },
  async created() {
    if (!this.getCompanies) await this.$store.dispatch("suggestion/setData");
    this.companyList = [
      { Id: "", Name: "Hangi şirketimiz için öneride bulunuyorsunuz?" },
      ...this.getCompanies,
    ];
    if (this.editCardData) {
      this.fileId = this.editCardData.FileId;
      this.suggestionDetail = this.editCardData.SuggestionDetail;
      this.selectedCompany = this.editCardData.CompanyId;
      this.downloadFile = this.editCardData.DownloadFile;
    }
  },
  methods: {
    close() {
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    async deleteDocument() {
      this.$store.dispatch("loading/setIsLoading", true);
      try {
        await upload.fetchDeleteFile(this.fileId);
        this.fileUploadData = [];
        const uploadFile = this.$refs.fileUploadInput;
        uploadFile.value = null;
        this.fileId = null;
        this.downloadFile = null;
      } catch (error) {
        //console.log("error->", error);
      }
      this.$store.dispatch("loading/setIsLoading", false);
    },
    async fileUploadChange(event) {
      if (Math.round(bytesToMegaBytes(event.srcElement.files[0].size)) <= 40) {
        const Str = event.srcElement.files[0].name.split(".");
        const fileName = Str[0];
        const docExtension = Str[1];
        const base64 = await toBase64(event.srcElement.files[0]);
        const splitBase64 = base64.split(",")[1];
        const body = {
          Filename: fileName,
          Extension: docExtension,
          DownloadBinary: splitBase64,
          ContentType: fileTypeCheck(docExtension),
        };
        try {
          this.$store.dispatch("loading/setIsLoading", true);
          const res = await upload.fetchUpluadFile(body);
          this.fileUploadData = event.srcElement.files;
          this.isFileMB = false;
          this.fileId = res.Data.Id;
          this.$store.dispatch("loading/setIsLoading", false);
          this.$toast.success("Dosya başarıyla yüklendi.", {
            position: "top",
          });
        } catch (error) {
          this.$store.dispatch("loading/setIsLoading", false);
          this.$toast.error("Dosya yüklenirken bir hata oluştu.", {
            position: "top",
          });
        }
        
      } else {
        this.isFileMB = true;
      }
    },
    getSuggestionData() {
      const tmpData = {
        pageIndex: 0,
        pageSize: 5,
        mindoQuestionId: parseInt(this.questionId),
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setSuggestionsData", tmpData);
    },
    getMostLikedData() {
      const tmpData = {
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setMostLikedMindos", tmpData);
    },
    getMostCommentedData() {
      const tmpData = {
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setMostCommentedMindos", tmpData);
    },
    async editMindo() {
      if (!isNil(this.suggestionDetail) && !isNil(this.selectedCompany)) {
        this.isBtnDisabled = true;
        const tmpData = this.editCardData;
        tmpData.CompanyId = this.selectedCompany;
        tmpData.SuggestionDetail = this.suggestionDetail;
        tmpData.CreatedUserId = this.user.Data.UserId;
        tmpData.FileId = this.fileId;
        try {
          await mindoService.fetchUpdateMindo(tmpData);
          this.$toast.success("Mindo başarıyla güncellendi.", {
            position: "top",
          });
          this.$store.dispatch("mindo/deleteSuggestionData");
          this.getSuggestionData();
          this.$store.dispatch("mindo/deleteMostLikedMindos");
          this.getMostLikedData();
          this.$store.dispatch("mindo/deleteMostCommentedMindos");
          this.getMostCommentedData();
          this.close();
        } catch (error) {
          this.$toast.error("Güncellenen zihin başarısız oldu.", {
            position: "top",
          });
        }
        this.isBtnDisabled = false;
      } else {
        if (isNil(this.suggestionDetail)) this.requiredField.push("suggestionDetail");
        if (isNil(this.selectedCompany)) this.requiredField.push("selectedCompany");
      }
    },
  },
};
</script>
