<template>
  <div class="mindo-form">
    <div class="mindo-form-header bg-orange">
      <h5 id="mindoProblem" class="text-white mb-0">{{ questionName }}</h5>
      <div class="mindo-question-mark">
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 17.01 25.51" xml:space="preserve">
          <g>
            <polygon
              points="3.78,9.81 1.89,9.81 1.89,11.77 0,11.77 0,13.74 1.89,13.74 1.89,15.7 3.78,15.7 3.78,13.74 5.67,13.74 5.67,11.77 3.78,11.77 	"
            ></polygon>

            <polygon
              points="12.28,1.96 10.39,1.96 10.39,0 8.5,0 8.5,1.96 6.61,1.96 6.61,3.92 8.5,3.92 8.5,5.89 10.39,5.89 10.39,3.92 12.28,3.92 	"
            ></polygon>

            <polygon
              points="15.12,25.51 15.12,23.55 17.01,23.55 17.01,21.59 15.12,21.59 15.12,19.62 13.23,19.62 13.23,21.59 11.34,21.59 11.34,23.55 13.23,23.55 13.23,25.51 	"
            ></polygon>
          </g>
        </svg>
        <span>?</span>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 17.01 25.51" xml:space="preserve">
          <g>
            <polygon
              points="3.78,9.81 1.89,9.81 1.89,11.77 0,11.77 0,13.74 1.89,13.74 1.89,15.7 3.78,15.7 3.78,13.74 5.67,13.74 5.67,11.77 3.78,11.77 	"
            ></polygon>

            <polygon
              points="12.28,1.96 10.39,1.96 10.39,0 8.5,0 8.5,1.96 6.61,1.96 6.61,3.92 8.5,3.92 8.5,5.89 10.39,5.89 10.39,3.92 12.28,3.92 	"
            ></polygon>

            <polygon
              points="15.12,25.51 15.12,23.55 17.01,23.55 17.01,21.59 15.12,21.59 15.12,19.62 13.23,19.62 13.23,21.59 11.34,21.59 11.34,23.55 13.23,23.55 13.23,25.51 	"
            ></polygon>
          </g>
        </svg>
      </div>
    </div>

    <div class="mindo-form-body">
      <input
        name="__RequestVerificationToken"
        type="hidden"
        value="5euMVwJ_j5_jCExTe1YtK0ffaGbAndh2SEgkPvI4hyNDI1tqpqppfSdp6OlZAX8cLfPaXQ7Gm-B5wPI-YvJtnCX-kJP1Il_Ve7c61YSGZ5I1MFwcBv9dHFgM29P-AkC6BL7swBcsc6ThO_GccZhEjg2"
      />
      <div class="form-group">
        <textarea
          class="form-control long"
          min-height="200"
          id="message-text-sponsor"
          placeholder="Önerin..."
          v-model="suggestionDetail"
        ></textarea>
      </div>
      <div
        id="txtError"
        class="form-group invalid-feedback"
        :style="[requiredField.includes('suggestionDetail') ? { display: 'block' } : '']"
      >
        Bu alan boş geçilemez!
      </div>
      <div class="form-group icon-note icon-down">
        <!-- <b-form-select v-model="selectedCompany" :options="companyList"></b-form-select> -->
        <select
          class="form-control required"
          data-val="true"
          data-val-required="The Id field is required."
          id="drpCustomer"
          v-model="selectedCompany"
        >
          <option v-for="(item, key) in companyList" :key="key" :value="item.Id">
            {{ item.Name }}
          </option>
        </select>
      </div>
      <div
        id="drpError"
        class="form-group invalid-feedback"
        :style="[requiredField.includes('selectedCompany') ? { display: 'block' } : '']"
      >
        Bu alan boş geçilemez!
      </div>
      <div class="form-group custom-file icon-folder-alt">
        <input
          type="file"
          class="custom-file-input"
          id="customFileArge"
          name="customFile"
          ref="fileUploadInput"
          :accept="supportedFileType"
          @change="fileUploadChange"
        />
        <label
          class="custom-file-label hide-overflow-text selected"
          for="customFile"
          id="lblDocument"
          >{{
            fileUploadData.length > 0 ? fileUploadData[0].name : "Doküman Yükle +"
          }}</label
        >
        <small class="Raleway-Medium" :style="[isFileMB ? { color: 'red' } : '']"
          >*Maximum 40 MB büyüklüğündeki dosyalar eklenebilir.</small
        >
        <br />
        <small class="Raleway-Medium"
          >**Birden fazla doküman yüklemek için sıkıştırılmış dosya (.zip vb.)
          kullanabilirsiniz.</small
        >
      </div>

      <button
        id="btnSubmit"
        :disabled="isBtnDisabled"
        @click="createdMindo"
        type="button"
        class="btn-innovation-modal bg-orange mt-3 mt-sm-0 mx-auto d-block float-sm-right"
      >
        ÖNER <span class="icon-arrow-right pl-2"></span>
      </button>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mindoService, upload } from "../../../services";
import { bytesToMegaBytes, toBase64, fileTypeCheck, isNil } from "../../../helpers";
export default {
  name: "CreateMindo",
  data() {
    return {
      selectedCompany: "",
      suggestionDetail: null,
      companyList: [],
      fileId: null,
      fileUploadData: [],
      isFileMB: false,
      isBtnDisabled: false,
      requiredField: [],
      supportedFileType:
        "image/jpeg,image/jpg,image/png,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-zip-compressed,application/zip,application/pdf",
    };
  },
  props: ["questionId", "questionName"],
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    getCompanies() {
      return this.$store.getters["suggestion/companiesData"];
    },
  },
  methods: {
    async deleteDocument() {
      this.fileUploadData = [];
      const uploadFile = this.$refs.fileUploadInput;
      uploadFile.value = null;
      this.fileId = null;
      this.suggestionDetail = null;
      this.selectedCompany = "";
    },
    getSuggestionData() {
      const tmpData = {
        pageIndex: 0,
        pageSize: 5,
        mindoQuestionId: parseInt(this.questionId),
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setSuggestionsData", tmpData);
    },
    async fileUploadChange(event) {
      if (Math.round(bytesToMegaBytes(event.srcElement.files[0].size)) <= 40) {
        const Str = event.srcElement.files[0].name.split(".");
        const fileName = Str[0];
        const docExtension = Str[1];
        const base64 = await toBase64(event.srcElement.files[0]);
        const splitBase64 = base64.split(",")[1];
        const body = {
          Filename: fileName,
          Extension: docExtension,
          DownloadBinary: splitBase64,
          ContentType: fileTypeCheck(docExtension),
        };
        try {
          this.$store.dispatch("loading/setIsLoading", true);
          const res = await upload.fetchUpluadFile(body);
          this.fileUploadData = event.srcElement.files;
          this.isFileMB = false;
          this.fileId = res.Data.Id;
          this.$store.dispatch("loading/setIsLoading", false);
          this.$toast.success("Dosya başarıyla yüklendi.", {
            position: "top",
          });
        } catch (error) {
          this.$store.dispatch("loading/setIsLoading", false);
          this.$toast.error("Dosya yüklenirken bir hata oluştu.", {
            position: "top",
          });
        }
        
      } else {
        this.isFileMB = true;
      }
    },
    async createdMindo() {
      if (!isNil(this.suggestionDetail) && !isNil(this.selectedCompany)) {
        this.isBtnDisabled = true;
        const body = {
          MindoQuestionId: parseInt(this.questionId),
          CompanyId: this.selectedCompany,
          SuggestionDetail: this.suggestionDetail,
          CreatedUserId: this.user.Data.UserId,
          FileId: this.fileId,
        };
        try {
          await mindoService.fetchCreateMindo(body);
          this.$toast.success("Mindo başarıyla oluşturuldu.", {
            position: "top",
          });
          this.$store.dispatch("mindo/deleteSuggestionData");
          this.getSuggestionData();
          this.deleteDocument();
        } catch (error) {
          this.$toast.error("Mindo oluşturulamadı.", {
            position: "top",
          });
        }
        this.isBtnDisabled = false;
      } else {
        if (isNil(this.suggestionDetail)) this.requiredField.push("suggestionDetail");
        if (isNil(this.selectedCompany)) this.requiredField.push("selectedCompany");
      }
    },
  },
  async created() {
    if (!this.getCompanies) await this.$store.dispatch("suggestion/setData");
    this.companyList = [
      { Id: "", Name: "Hangi şirketimiz için öneride bulunuyorsunuz?" },
      ...this.getCompanies,
    ];
  },
};
</script>

<style></style>
