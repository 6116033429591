<template>
  <div class="mindo-tabs-container">
    <b-tabs
      content-class="mt-3"
      :active-nav-item-class="activeTab"
      v-model="selectActiveTab"
    >
      <b-tab title="En Yeniler" active>
        <MindoNewests :questionId="questionId" />
      </b-tab>
      <b-tab title="Beğenilenler">
        <MindoLiked :getMostLiked="getMostLiked" :questionId="questionId" />
      </b-tab>
      <b-tab title="Çok Yorumlananlar">
        <MindoComment :getMostCommented="getMostCommented" :questionId="questionId" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MindoNewests from "./Cards/MindoNewests.vue";
import MindoLiked from "./Cards/MindoLiked.vue";
import MindoComment from "./Cards/MindoComment.vue";
export default {
  name: "MindoTabs",
  data() {
    return {
      activeTab: "",
      selectActiveTab: 0,
      isShareIdea: false,
    };
  },
  props: ["questionId"],
  components: {
    MindoNewests,
    MindoLiked,
    MindoComment,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    getMostLiked() {
      return this.$store.getters["mindo/getMostLiked"];
    },
    getMostCommented() {
      return this.$store.getters["mindo/getMostCommented"];
    },
  },
  watch: {
    selectActiveTab() {
      if (this.selectActiveTab == 0) {
        this.activeTab = "active-newests";
      } else if (this.selectActiveTab == 1) {
        this.activeTab = "active-liked";
        if (this.getMostLiked.length == 0) {
          this.getMostLikedData();
        }
      } else {
        this.activeTab = "active-comment";
        if (this.getMostCommented.length == 0) {
          this.getMostCommentedData();
        }
      }
    },
  },
  methods: {
    getMostLikedData() {
      const tmpData = {
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setMostLikedMindos", tmpData);
    },
    getMostCommentedData() {
      const tmpData = {
        companyId: this.user.Data.CompanyId,
      };
      this.$store.dispatch("mindo/setMostCommentedMindos", tmpData);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/scss/mindo-tabs.scss";
</style>
